import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import moment from "moment";
import { MicrophoneIcon, MicrophoneMuteIcon } from "../Icons";

import { get_deployment, get_host, useAPI, useAPIPost, useAPIUpload, usePost } from "../API";
import FormControl from "react-bootstrap/FormControl";
import { useLocalizedStrings, useLocalTimeZoneId, useProgramTimeZoneId } from "../Localization";
import { blockify, classNames, formatTime, getLocalTimezoneSuffix, MiniAidKitLogo, prettyPhone, renderLinks, safeParse, SpacedSpinner, useInterval, wrapWithBdi } from "../Util";
import { Button, Card, Checkbox, Modal, RadioGroup, useToast } from "@aidkitorg/component-library";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Menu } from '@headlessui/react'
import InterfaceContext, { ConfigurationContext, PublicConfigurationContext, LoggedInConfigurationContext, SupportedLanguage, TwilioDeviceContext, UserInfoContext } from "../Context";
import TranslateWrapper from "../Translator";
import { ClickableButton } from '../Components/Button';
import { ChatBubbleLeftRightIcon, DocumentTextIcon, EllipsisVerticalIcon, EnvelopeIcon, PhoneIcon, PhoneArrowDownLeftIcon, PhoneArrowUpRightIcon, ExclamationTriangleIcon, ChevronDownIcon, PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Badge as TailwindBadge } from "../Components/Badge";
import { createImageWindow } from "../Questions/Attachment";
import { TurnableImage } from "../Components/TurnableImage";
import { Dropdown as TailwindDropdown } from "../Components/Dropdown";
import { CalendarIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { getURLsFromCSV } from '@aidkitorg/roboscreener/lib/util/urls_from_csv'
import * as Sentry from "@sentry/react";
import { timezones } from '@aidkitorg/types/lib/timezones';
import PresetDateTimePicker, { CustomDateTimePicker } from "../Components/DateTimePicker";
import { DateOption, interfaceNumber } from "@aidkitorg/types/lib/survey";
import FlyoutMenu from "../Components/FlyoutMenu";
import type { Call, Device } from "@twilio/voice-sdk";
import type { HideMessageReason } from "aidkit/lib/messaging/manage";
import { getTimeZoneData, TimeZoneId } from "../utils/time";

type CommsProps = {
  uid: string;
  canSendComms: boolean,
  info: Record<string, string>,
  channel: "default" | "ia" | string,
  setUnhandledCount: (count: number) => void
};

type PresetDateOpts = Parameters<typeof PresetDateTimePicker>[0]['options'];
type TimezoneOffset = (typeof timezones)[number];

const twilioSendAtBounds = {
  before: (() => {
    const dt = new Date();
    // 35 days is the max for twillio message scheduling
    // see SendAt in https://www.twilio.com/docs/messaging/features/message-scheduling#required-parameters
    return dt.setTime(dt.setHours(0, 0, 0, 0) + (35 * 24 * 60 * 60 * 1000))
    ;
  })(),
  after: (() => {
    const dt = new Date();
    // 15 minutes later is the min for twillio message scheduling
    return dt.setTime(dt.setSeconds(0) + (15 * 60 * 1000));
  })()
};

function useFirstSignedURL(props: { attachments?: string }) {
  const [signedURL, setSignedURL] = useState("");
  const getSignedURL = usePost('/document/view');
  useEffect(() => {
    if (!props.attachments) return;
    const urls = getURLsFromCSV(props.attachments);
    (async () => {
      if (props.attachments && props.attachments.length) {
        const paths = await getSignedURL({
          paths: urls
        });
        setSignedURL(paths.paths[urls[0]]);
      }
    })();
  }, [props.attachments]);

  return signedURL;
}

export function InlineAttachments(props: { attachments: string, attachmentTypes: string, Viewer: string, autoPlay?: boolean }) {
  const [signedURLs, setSignedURLs] = useState<Record<string, string> | undefined>(undefined);
  const [urlTypes, setUrlTypes] = useState<Record<string, string> | undefined>(undefined);
  const getSignedURL = usePost('/document/view');
  useEffect(() => {
    // urlTypes[i] is the type of urls[i]
    const urls = getURLsFromCSV(props.attachments);
    const urlTypes = props.attachmentTypes.split(',');

    (async () => {
      if (props.attachments && props.attachments.length) {
        const paths = await getSignedURL({
          paths: urls
        });
        setSignedURLs(paths.paths);

        let contentTypes: Record<string, string> = {};
        urls.forEach((url, i) => {
          contentTypes[url] = urlTypes[i] || urlTypes[0];
        })
        setUrlTypes(contentTypes);
      }
    })();
  }, [props.attachments]);

  return <>
    {signedURLs && Object.keys(signedURLs).map((key) => {
      const url = signedURLs[key];
      const urllower = url.toLowerCase();
      // This supports playing a voice message
      if (urlTypes?.[key]?.includes('audio')) {
        return <div key={key}><audio controls src={url} /></div>
      }
      else if (urlTypes?.[key]?.includes('video')) {
        return <div key={key}>
          <video controls autoPlay={props.autoPlay} muted={props.autoPlay} width="250" style={{ maxHeight: '400px' }}>
            <source src={url} type={urlTypes[key]} />
          </video>
        </div>

        // Pretty much everything else we treat like a turnable 
        // image right now
      } else if (
        urllower.endsWith("png") ||
        urllower.endsWith("jpeg") ||
        urllower.includes(".jpg") ||
        url.split("/").slice(-1)[0].indexOf(".") === -1
      ) {
        const createFullScreenURL = () => {
          const params = new URLSearchParams({
            src: signedURLs[key],
            Viewer: props.Viewer || 'applicant'
          });
          return `/fsti?${params.toString()}`;
        };
        return <li key={url}>
          <a href={createFullScreenURL()}
            target="_blank" rel="noopener noreferrer">
            {signedURLs[key] &&
              <TurnableImage
                Viewer={props.Viewer}
                alt="document"
                src={url}
              /> || key.split('/').pop()}
          </a>
        </li>
      }
      return <li>
        <a href={url}
          onClick={async (e) => {
            e.preventDefault();
            const paths = await getSignedURL({
              paths: [key],
            });
            window.open(paths.paths[key], "_blank");
          }}
          rel="noopener noreferrer" target="_blank">
          {key.split("/").slice(-1)[0]}
        </a>{" "}
      </li>
    })}
  </>
}

function ApplicantComms(props: CommsProps) {
  const L = useLocalizedStrings();
  const config = useContext(ConfigurationContext);

  const [commsDisabled, setCommsDisabled] = useState(false);

  // get messages from state
  const [contactNameKey, setContactNameKey] = useState("legal_name");

  const getMessages = usePost("/v2/applicant/get_messages");
  const [messages, setMessages] = useState({ data: [], unhandled_count: 0 } as Awaited<ReturnType<typeof getMessages>>);
  const [fetchingInitialMessages, setFetchingInitialMessages] = useState(false);
  const refreshMessages = useCallback(async () => {
    if (!props.uid) return;
    let messageData = await getMessages({
      channel: props.channel,
      applicant: props.uid,
      contactNameKey
    });
    setFetchingInitialMessages(false);
    if (messageData) {
      setMessages(messageData);
    }
  }, [props.uid, props.channel, contactNameKey]);

  useEffect(() => {
    if (!props.uid) return;
    setFetchingInitialMessages(true);
    (async () => {
      await refreshMessages();
    })();
  }, [props.uid]);

  useEffect(() => {
    props.setUnhandledCount(messages?.unhandled_count || 0);
  }, [messages]);

  // Don't call if document hidden
  const cb = useCallback(() => {
    if (document.hidden) return;
    if (window.document.hidden) return;
    refreshMessages();
  }, [document.hidden, refreshMessages]);

  useInterval(cb, 5000);

  const resend_message = useAPIPost("/message/.../resend_message");
  async function retrySendMessage(message_id: string) {
    await resend_message({}, "/message/" + message_id + "/resend_message");
    await refreshMessages();
  }

  const delete_message = useAPIPost("/message/.../delete_message");
  async function confirmDeleteMessage(message_id: string) {
    const yn = await window.confirm(L.applicant.comms.are_you_sure);
    if (yn) {
      if (delete_message) await delete_message({}, "/message/" + message_id + "/delete_message");
      if (refreshMessages) await refreshMessages();
    }
  }

  return <CommsWindow info={props.info}
    contact={{ applicant: props.uid }}
    fetchingInitialMessages={fetchingInitialMessages}
    messages={messages}
    disabled={commsDisabled}
    channel={props.channel}
    contactNameKey={contactNameKey}
    setContactNameKey={setContactNameKey}
    retrySendMessage={retrySendMessage}
    confirmDeleteMessage={confirmDeleteMessage}
    refreshMessages={refreshMessages}
  />
}

type AidKitMessage = {
  id: string | number,
  platform_message_id: string,
  source: string,
  destination: string,
  message: string,
  kind: 'sms' | 'email' | 'mms' | 'call' | 'whatsapp',
  needs_attention?: boolean | null,
  created_at: string,
  metadata: string,
  status?: string
  author_name?: string
}


function makeDateOption(params: DateOption, offsetInHours?: number): PresetDateOpts[number] {
  const secondsInMillis = 1000;
  const minutesInMillis = secondsInMillis * 60;
  const hourInMillis = 60 * minutesInMillis;

  const date = new Date();
  // getTimezoneOffset is in MINUTES.
  // Hence dividing by 60 and doing fractional rounding (so half hour increments are preserved)
  const actualOffset = Math.fround(date.getTimezoneOffset()) / 60;
  const desiredOffset = Math.abs(offsetInHours ?? actualOffset);

  const minutes = ((params.minutes ?? 0) + (params.relative ? date.getUTCMinutes() : 0));
  const hours = ((params.hours ?? 0) + (params.relative ? date.getUTCHours() : 0));
  const days = 24 * (params.days ?? 0);

  date.setUTCHours(days + hours, minutes, 0, 0);

  return {
    date: date.getTime() + (params.relative ? 0 : desiredOffset * hourInMillis),
    alias: params.name
  };
}

function generateHiddenMessage (m: any, lang: SupportedLanguage, L: ReturnType<typeof useLocalizedStrings>) {
  const metadata = safeParse(m.metadata || '{}');
  if (!metadata.hidden) return '';
  
  const {
    on,
    reason,
    details,
    attachmentOnly,
  }: {
    on: string;
    reason: HideMessageReason;
    details?: string;
    attachmentOnly?: boolean;
  } = metadata.hidden;

  const descriptions = {
    applicant_request: attachmentOnly
      ? L.applicant.comms.attachments_hidden_applicant_request
      : L.applicant.comms.hidden_applicant_request,
    sensitive_information: attachmentOnly
      ? L.applicant.comms.attachments_hidden_sensitive_info
      : L.applicant.comms.hidden_sensitive_info,
    inappropriate_content: attachmentOnly
      ? L.applicant.comms.attachments_flagged_inappropriate
      : L.applicant.comms.flagged_inappropriate,
    default: attachmentOnly
      ? L.applicant.comms.attachments_hidden
      : L.applicant.comms.message_hidden
  };

  const onDate = on ? ` ${new Date(on).toLocaleString(lang)} ${getLocalTimezoneSuffix()}` : '';
  
  const optionalDetails = details ? `: "${details}"` : ''; // not translated because it's a user input
  
  // Example message format: [Hidden at the applicant's request 1/1/2021 12:00:00 PM: "Applicant requested we delete their data"]
  return `[${descriptions[reason || 'default']}${onDate}${optionalDetails}]`;
};

// Strictly calls AidKitTS and is UID agnostic
export function CommsWindow(props: {
  channel: "default" | "ia" | string,
  info: Record<string, string | undefined>,
  fetchingInitialMessages: boolean,
  contact: { applicant: string, withNameKey?: string } | { supportCase: string, mainContact: string, applicant?: string },
  messages?: {
    data: AidKitMessage[]
  },
  disabled?: boolean,
  // this configures which contact messages to grab from the API
  contactNameKey: string,
  setContactNameKey: React.Dispatch<React.SetStateAction<string>>,
  refreshMessages?: () => Promise<void>,
  retrySendMessage?: (params: any) => Promise<any>,
  confirmDeleteMessage?: (params: any) => Promise<any>
}) {

  // Boiler plate
  const { messages, fetchingInitialMessages, refreshMessages, retrySendMessage, confirmDeleteMessage, contactNameKey, setContactNameKey } = props;
  const L = useLocalizedStrings();
  const localTimeZoneId = useLocalTimeZoneId();
  const programTimeZoneId = useProgramTimeZoneId();

  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const loggedInConfig = useContext(LoggedInConfigurationContext);
  const userContext = useContext(UserInfoContext);
  const { twilioDevice, twilioCall, twilioCaller, setTwilioCall,
    twilioVoiceChannel, resetTwilioVoiceChannel } = useContext(TwilioDeviceContext);
  
  const publicConfig = useContext(PublicConfigurationContext);
  const translationService = publicConfig?.interface?.translationService;
  const conferenceNumbers = publicConfig?.interface?.conferenceNumbers;
  const [sending, setSending] = useState(false);

  const mainContact = (props.contact as any).mainContact as string | undefined;
  const applicant = (props.contact as any).applicant as string | undefined;
  const aOrApplicant = interfaceNumber(publicConfig.interface?.version) > 0 ? 'a' : 'applicant';

  const [message, setMessage] = useState("");
  const [contactPhoneKey, setContactPhoneKey] = useState("phone_number");
  const [contactEmailKey, setContactEmailKey] = useState("email");
  const [whatsAppKey, setWhatsAppKey] = useState("whatsapp");
  const [contactLabel, setContactLabel] = useState("Applicant");
  const [whatsAppWindowClosed, setWhatsAppWindowClosed] = useState(false);
  const [canSendWhatsApp, setCanSendWhatsApp] = useState(false);

  // limit amount of messages to show
  const [limit, setLimit] = useState(20);

  const sendMessage = usePost("/applicant/send_message");

  // For showing a spinner when resolving unhandled messages
  const [resolving, setResolving] = useState(false);
  const resolveRequests = usePost("/messages/resolve_all");

  const [cbTimeout, setCbTimeout] = useState(null as null | ReturnType<typeof setTimeout>);
  const [fetchVMs, setFetchVMs] = useState(0);

  const uploadAttachment = useAPIUpload("/upload");
  const getUploadURL = usePost("/document/upload_url");
  const [recordedAudioURL, setRecordedAudioURL] = useState<string>();
  const [recordedAudio, setRecordedAudio] = useState<Blob>();

  const Selfie = useFirstSignedURL({ attachments: props.info['selfie'] });

  const addToConference = usePost('/voice/add_to_conference');
  const removeFromConference = usePost('/voice/remove_from_conference');
  const startRecording = usePost('/voice/start_recording');
  const saveUnrecordedOutboundCall = usePost('/voice/save_unrecorded_call');
  const callStatus = usePost('/voice/call_status');

  const [isMuted, setMuted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showingConsentBox, setShowingConsentBox] = useState<'notice' | 'script' | null>(null);
  const [callAnswered, setCallAnswered] = useState(false);
  const [consentGranted, setConsentGranted] = useState<boolean | null>(null);
  // Need refs to keep current values to use inside startCall function
  const callAnsweredRef = useRef(callAnswered);
  const consentGrantedRef = useRef(consentGranted);

  const requireConsentToRecordCalls = loggedInConfig?.comms?.requireConsentToRecordCalls;
  const customConsentScript = requireConsentToRecordCalls?.customConsentScript?.[context.lang] || requireConsentToRecordCalls?.customConsentScript?.['en']

  useEffect(() => {
    callAnsweredRef.current = callAnswered;
    consentGrantedRef.current = consentGranted;
  }, [callAnswered, consentGranted]);
  
  const [applicantCallSid, setApplicantCallSid] = useState('');
  const [translatorCallSid, setTranslatorCallSid] = useState('');
  const [conferenceNumbersSids, setConferenceNumbersSids] = useState<Record<string, string>>({});
  const [conferenceNumberStatuses, setConferenceNumberStatuses] = useState<Record<string, string>>({});
  const [conferenceNumbersLoading, setConferenceNumbersLoading] = useState<Record<string, boolean>>({});
  const [conferenceCall, setConferenceCall] = useState(false);
  const [applicantConfStatus, setApplicantConfStatus] = useState('');
  const [translatorConfStatus, setTranslatorConfStatus] = useState('');
  const [applicantLoading, setApplicantLoading] = useState(false);
  const [translatorLoading, setTranslatorLoading] = useState(false);
  const [To, setTo] = useState('');
  const [dateOpts, setDateOpts] = useState<PresetDateOpts>([]);
  const [localTimeZoneOffset, setLocalTimeZoneOffset] = useState<TimezoneOffset>();
  const [refTimeZoneOffset, setRefTimeZoneOffset] = useState<TimezoneOffset>();
  const { toast } = useToast();

  const endConfOnRemoveLastParticipant = (sidToRemove: string) => {
    if ([applicantCallSid, translatorCallSid, ...Object.values(conferenceNumbersSids)].filter(sid => !!sid && sid !== sidToRemove).length === 0) {
      if (twilioCall) {
        twilioCall.disconnect();
        setTwilioCall(null);
        setConferenceCall(false);
      }
    }
  };

  useEffect(() => {
    setLocalTimeZoneOffset(getTimeZoneData(localTimeZoneId));
    setRefTimeZoneOffset(getTimeZoneData(programTimeZoneId || localTimeZoneId));
  }, [publicConfig]);

  useEffect(() => {
    const configured = (publicConfig.comms?.replyScheduling?.options ?? [
      {
        days: 1,
        hours: 9,
        minutes: 0,
        name: 'Tomorrow @ 9am'
      },
      {
        days: 1,
        hours: 14,
        minutes: 0,
        name: 'Tomorrow @ 2pm'
      }
    ])
      .map(t => makeDateOption(t, refTimeZoneOffset?.offset))
      .filter(t => t.date && t.date > Date.now());
    setDateOpts(configured);
  }, [refTimeZoneOffset]);

  useEffect(() => {
    if (refreshMessages) refreshMessages();
  }, [contactNameKey]);

  // Check if the WhatsApp 24 hour window is closed. 
  // If the last inbound message from an applicant was more than 24 hours ago, the window is closed.
  useEffect(() => {
    if (!canSendWhatsApp) {
      setWhatsAppWindowClosed(false);
      return;
    }

    let latestInboundWhatsAppMessage = messages?.data?.filter(msg => {
      return msg.kind === 'whatsapp' && (msg.needs_attention || msg.status);
    })?.sort((msg1, msg2) => {
      return (new Date(msg2.created_at)).getTime() - (new Date(msg1.created_at)).getTime()
    })?.[0];

    if (latestInboundWhatsAppMessage) {
      const latestDate = (new Date(latestInboundWhatsAppMessage.created_at)).getTime();
      const timeDifference = (new Date()).getTime() - latestDate;
      const twentyFourHours = 24 * 60 * 60 * 1000;
      if (timeDifference < twentyFourHours) {
        setWhatsAppWindowClosed(false);
      } else {
        setWhatsAppWindowClosed(true);
      }
    } else {
      setWhatsAppWindowClosed(false);
    }
  }, [messages, canSendWhatsApp]);

  useEffect(() => {
    if (whatsAppKey && props.info[whatsAppKey] === 'yes') {
      setCanSendWhatsApp(true);
      return;
    }

    const applicantSentWhatsApp = messages?.data?.find(m => {
      return m.kind === 'whatsapp'
    });

    setCanSendWhatsApp(!!applicantSentWhatsApp);
  }, [messages, whatsAppKey])

  async function resolveAllRequests(message_ids: number[]) {
    setResolving(true);
    await resolveRequests({
      applicant: (props.contact as any).applicant || 'unknown',
      message_ids,
      supportCase: (props.contact as any).supportCase
    });
    setResolving(false);
    if (refreshMessages) {
      await refreshMessages();
    }
  }

  const doSend = useCallback(async (method: 'email' | 'sms' | 'whatsapp', sendAt?: Date) => {
    let sendingVoiceMemo = false;
    let savedPath;
    if ((method !== 'email') && recordedAudio && recordedAudioURL) {
      const parts = recordedAudioURL.split('/');
      sendingVoiceMemo = true;

      const url = await getUploadURL({
        path: `twilio/voiceMemo${parts[parts.length - 1]}`,
        length: recordedAudio.size,
      });

      try {
        await uploadAttachment([recordedAudio], {}, url.uploadURL, "PUT");
        savedPath = url.savedPath;
      } catch (e) {
        toast({
          description: "Error uploading voice message",
          variant: 'error'
        })
        console.error('Error uploading voice message: ', e);
        return;
      }
    }

    if (message.length === 0 && !sendingVoiceMemo) {
      alert(L.applicant.notes.please_enter_a_message);
      return;
    }

    setSending(true);
    try {
      const response = await sendMessage({
        channel: props.channel,
        method,
        contact: {
          ...props.contact,
          withNameKey: contactNameKey
        },
        message: message,
        ...(savedPath &&
          { attachment: {
            path: savedPath,
            kind: sendingVoiceMemo ? 'audio' : 'image'
          }}),
        sendAt
      });
    } catch (e) {
      console.warn("Error sending message: ", e);
      toast({
        description: "Error sending message, please try again",
        variant: 'error'
      });
    } finally {
      setSending(false);
    }

    setMessage("");
    setRecordedAudio(undefined);
    setRecordedAudioURL(undefined);
    if (refreshMessages) await refreshMessages();
  }, [recordedAudio, recordedAudioURL, message]);

  async function doCall (To: string, twilioDevice: Device, twilioCaller: string | null) {
    console.log("Calling: " + To);
    const call = await twilioDevice.connect({
      params: {
        To,
        ...(twilioCaller && { agent: twilioCaller })
      }
    });

    call.on('accept', (call) => {
      console.log("accept", call);
      if (requireConsentToRecordCalls) {
        setCallAnswered(true);
      }
    })
    call.on('disconnect', (call) => {
      console.log("disconnect", call);
      if (requireConsentToRecordCalls) {
        // Save unrecorded call info if call was answered but consent wasn't indicated
        if (callAnsweredRef.current && consentGrantedRef.current === null) {
          saveUnrecordedOutboundCall({
            callAgent: call.customParameters.get('agent'),
            callSid: call.parameters.CallSid,
            to: To,
            channel: twilioVoiceChannel,
            status: 'consent_unknown',
          });
        }
        resetConsentState();
      }
    })
    call.on('cancel', (call) => {
      console.log("cancel", call);
      if (requireConsentToRecordCalls) {
        resetConsentState();
      }
    })

    setTwilioCall(call);
  }

  function resetConsentState () {
    setCallAnswered(false);
    setConsentGranted(null);
    setIsRecording(false);
    setShowingConsentBox(null);
    setTwilioCall(null);
  };

  function muteCall (twilioCall: Call) {
    twilioCall.mute(true);
    setMuted(true);
  }

  function unmuteCall (twilioCall: Call) {
    twilioCall.mute(false);
    setMuted(false);
  }

  async function recordCall(twilioCall: Call) {
    setConsentGranted(true);
    try {
      const res = await startRecording({
        callAgent: twilioCall.customParameters.get('agent'),
        callSid: twilioCall.parameters.CallSid,
        to: To,
        channel: twilioVoiceChannel,
      });

      if(!('error' in res)) {
        setIsRecording(true);
      }
    } catch (e) {
      console.error('Error recording call:', e);
      toast({
        description: L.applicant.comms.consent.recording_error,
        variant: 'error'
      });
      // Try to save unrecorded call info if recording fails
      await saveUnrecordedOutboundCall({
        callAgent: twilioCall.customParameters.get('agent'),
        callSid: twilioCall.parameters.CallSid,
        to: To,
        channel: twilioVoiceChannel,
        status: 'recording_error',
      });
    }
  }

  useEffect(() => {
    if (!twilioCall) {
      setMuted(false);
      return;
    }

    twilioCall.on('disconnect', () => {
      setCbTimeout(setTimeout(() => {
        setFetchVMs(prevState => prevState + 1);
      }, 3000));
    })

    return () => {
      if (cbTimeout) clearTimeout(cbTimeout);
    }
  }, [twilioCall]);

  useEffect(() => {
    const to = (contactPhoneKey === 'phone_number'
      ? (((mainContact || '').indexOf('@') === -1 ? mainContact : '') || props.info.phone_number || props.info.phone)
      : props.info[contactPhoneKey] || '');
    // if country code is included in phone number, do not try to replace it with +1.
    setTo(to ? to.startsWith('+') ? to : '+1' + to : '')
  }, [contactPhoneKey, mainContact, props.info.phone_number, props.info.phone]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (applicantCallSid) {
      interval = setInterval(async () => {
        const response = await callStatus({ callSid: applicantCallSid });
        setApplicantConfStatus(response);
        if (['failed', 'completed', 'busy', 'no-answer', 'canceled'].includes(response)) {
          setApplicantCallSid('');
        }
      }, 1000)
    } else {
      setApplicantConfStatus('');
    }
    return () => clearInterval(interval);
  }, [applicantCallSid]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (translatorCallSid) {
      interval = setInterval(async () => {
        const response = await callStatus({ callSid: translatorCallSid });
        setTranslatorConfStatus(response);
        if (['failed', 'completed', 'busy', 'no-answer', 'canceled'].includes(response)) {
          setTranslatorCallSid('');
        }
      }, 1000)
    } else {
      setTranslatorConfStatus('');
    }
    return () => clearInterval(interval);
  }, [translatorCallSid]);

  useEffect(() => {
    const intervals: Record<string, ReturnType<typeof setInterval>> = {};

    Object.keys(conferenceNumbersSids).forEach(key => {
      const callSid = conferenceNumbersSids[key];
      if (!callSid) return;
      intervals[key] = setInterval(async () => {
        const response = await callStatus({ callSid });
        setConferenceNumberStatuses(prevState => ({
          ...prevState,
          [key]: response
        }));
        if (['failed', 'completed', 'busy', 'no-answer', 'canceled'].includes(response)) {
          setConferenceNumbersSids(prevState => {
            const { [key]: _, ...rest } = prevState;
            return rest;
          });
        }
      }, 1000);
    });

    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, [conferenceNumbersSids]);

  const getMessageDebugInfo = usePost("/admin/message_details");
  type MessageDetail = Awaited<ReturnType<typeof getMessageDebugInfo>>;
  const [showDebugMessage, setShowDebugMessage] = useState<MessageDetail | null>(null);

  const [showHideMessage, setShowHideMessage] = useState<MessageDetail | null>(null);
  const [hideMessageReason, setHideMessageReason] = useState<HideMessageReason | ''>('');
  const [hideMessageDetails, setHideMessageDetails] = useState<string>('');
  const [shouldHideAttachmentOnly, setShouldHideAttachmentOnly] = useState(false);

  const hideMessage = usePost('/messages/update_visibility');
  
  const handleMessageOptionsClick = async (msg: AidKitMessage, option: 'debug' | 'hide') => {
    console.log(msg);

    // Query the shortlink information
    const response = await getMessageDebugInfo({
      id: parseInt(msg.id + '')
    });

    if (option === 'debug') {
      setShowDebugMessage(response);
    } else if (option === 'hide') {
      setShowHideMessage(response);
    }
  }

  const hideMessageReasonOptions = [
    { value: 'applicant_request', label: L.applicant.comms.applicant_request },
    { value: 'sensitive_information', label: L.applicant.comms.sensitive_information },
    { value: 'inappropriate_content', label: L.applicant.comms.inappropriate_content },
  ];

  const hiddenMessagesMap = useMemo(() => 
    messages?.data?.reduce<Record<string | number, string>>((acc, m) => {
      // for all messages with 'hidden' metadata, add the message to display to the map
      if (safeParse(m.metadata || '{}').hidden) {
        acc[m.id] = generateHiddenMessage(m, context.lang, L);
      }
      return acc;
    }, {}), [messages, context.lang]
  );

  const clearHideMessageModal = () => {
    setShowHideMessage(null);
    setHideMessageReason('');
    setHideMessageDetails('');
    setShouldHideAttachmentOnly(false);
  }

  return (
    <div>
      {showDebugMessage ? 
        <Modal size="fullscreen" title={"Debug Comms"} open={!!showDebugMessage} onClose={() => {
          setShowDebugMessage(null);
        }}>
          <pre>          
            <h2>{L.applicant.comms.debug_message}</h2>
            <pre>
              <dl>
                {Object.keys(showDebugMessage['message']).map(key => {
                  return <dd key={key}>{key}: {showDebugMessage['message'][key as keyof typeof showDebugMessage['message']]}</dd>
                })}
              </dl>
            </pre>

            {showDebugMessage.shortlinks ?
              <>
                <h2>Shortlinks</h2>
                <div>
                  {showDebugMessage.shortlinks.map((link, i) => {
                    return <div key={link.slug}>
                      <h3>{link.slug}</h3>
                      <dl>
                        <dt>Path</dt>
                        <dd>{link.path}</dd>

                        <dt>Clicks</dt>
                        {link.firstClickedTime 
                          ? <dd>First Clicked Time: <span title={new Date(link.firstClickedTime).toLocaleString()}>
                            {new Date(link.firstClickedTime).toISOString()} (UTC)
                          </span></dd>
                          : <dd>Has not yet been clicked!</dd>}
                        {link.tokenProps && <>
                          <dt>Token</dt>
                          <dd><pre>{JSON.stringify(link.tokenProps)}</pre></dd>
                        </>}
                        <div className="max-w-md">
                          <h3>Path Info ({link.path})</h3>
                          {link.subsurvey ? <dl>
                            <dt>Subsurvey Status (Viewable/Not Viewable)</dt>
                            <dd>
                              {link.subsurvey.currentlyAvailable ? "Currently Viewable" : "Expired / Not Available" }
                            </dd>
                            <dt className="">Fields: (First 3 and Last 3)</dt>
                            <dl>
                              {link.subsurvey.fields.slice(0, 3).concat(link.subsurvey.fields.slice(-3)).map((f, i) => (
                                <>
                                  <dt>{f}</dt>
                                  <dd>{props.info[f] ?? <><em>(empty)</em></>}</dd>
                            
                                  {i === 2 && (<div className="flex-1">...</div>)}
                                </>
                              ))}
                            </dl>
                          </dl> : <dd>No Subsurvey Associated with this shortlink</dd>}
                        </div>
                      </dl>
                    </div>
                  })}
                </div>
            
              </>
              : null
            }
          </pre>
        </Modal>
        : null}  

      {showHideMessage ? 
        <Modal title={L.applicant.comms.hide_message} open={!!showHideMessage} onClose={clearHideMessageModal}>
          <p>
            <RadioGroup
              label={L.applicant.comms.hide_message_reason}
              options={hideMessageReasonOptions}
              value={hideMessageReason}
              onChange={(value) => setHideMessageReason(value as HideMessageReason)}
            />
          </p>

          <p>
            <FormControl
              as="textarea"
              placeholder={L.applicant.comms.hide_message_details}
              value={hideMessageDetails}
              onChange={(e) => setHideMessageDetails(e.target.value)}
            />
          </p>

          {safeParse(showHideMessage.message.metadata || '{}').attachments?.length > 0 && (
            <p>
              <Checkbox
                label={L.applicant.comms.hide_attachment_only}
                checked={shouldHideAttachmentOnly}
                description={shouldHideAttachmentOnly && L.applicant.comms.hide_attachment_only_description}
                onChange={() => setShouldHideAttachmentOnly(!shouldHideAttachmentOnly)}
              />
            </p>
          )}

          <div className="flex justify-end gap-x-3">
            <Button size="md" variant="secondary" onClick={clearHideMessageModal}>{L.apply.cancel}</Button>
            <Button size="md" variant="primary" isDisabled={!hideMessageReason} onClick={() => {
              if (showHideMessage.message.id && hideMessageReason) {
                hideMessage({
                  messageIds: [showHideMessage.message.id],
                  action: 'hide',
                  metadata: {
                    reason: hideMessageReason,
                    ...hideMessageDetails && { details: hideMessageDetails },
                    ...shouldHideAttachmentOnly && { attachmentOnly: shouldHideAttachmentOnly },
                  }
                })
              }
              clearHideMessageModal();
              toast({ description: L.applicant.comms.message_hidden, variant: 'success' });
            }}
            >{L.applicant.comms.hide_message}</Button>
          </div>
        </Modal>
        : null}
      {twilioVoiceChannel !== props.channel && <><span className="-mt-2 mr-2 font-sm"><em>
        This channel is currently not voice enabled because you are assigned to another comms channel.
        To enable voice calling on this channel&nbsp;
        <a className={`-mt-2 mr-2`} style={{ cursor: 'pointer' }} onClick={async () => await resetTwilioVoiceChannel(props.channel)}>
          click here
        </a></em></span><br /><br /></>}

      <div className="flex flex-col gap-2 mb-2"> 
        {/* CALL IN PROGRESS INDICATORS */}
        {/* For now, only show if consent is required */}
        {twilioCall && requireConsentToRecordCalls && (
          consentGranted && isRecording
            ? (
              // RECORDING INDICATOR
              <div className="flex items-center gap-2 text-sm font-medium text-red-600">
                <div className="w-2.5 h-2.5 bg-red-600 rounded-full blink"></div>
                <div>{L.applicant.comms.consent.recording_call}</div>
              </div>
            ) : (
              // CALL IN PROGRESS INDICATOR
              <div className="flex items-center gap-2 text-sm font-medium text-gray-500">
                <PhoneIcon className="w-5 h-5 blink" />
                <div>{L.applicant.comms.consent.call_in_progress_not_recording}</div>
              </div>
            )
        )}

        <div className="flex justify-content-between items-center">
          {/* CALL BUTTON */}
          {((contactPhoneKey && props.info[contactPhoneKey])) ?
            (!twilioCall && twilioDevice &&
            <div className="inline">
              <button
                className={classNames(`inline-flex items-center mr-2 px-4 py-2 border border-transparent text-sm
              font-medium rounded-md shadow-sm text-white`,
                (props.disabled || twilioVoiceChannel !== props.channel || showingConsentBox === 'notice') ? 'bg-gray-300 pointer-events-none' : 'bg-indigo-600 hover:bg-indigo-700',
                `focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`)}
                onClick={async () => {
                  // If consent is required, don't start the call right away
                  // Instead, show the consent notice
                  if (requireConsentToRecordCalls) {
                    setShowingConsentBox('notice');
                    return;
                  }

                  await doCall(To, twilioDevice, twilioCaller);
                }}
                disabled={props.disabled || twilioVoiceChannel !== props.channel || showingConsentBox === 'notice'}
              >
                {L.applicant.comms.call}
              </button>
            </div>)
            : <div></div>}

          {/* IN-CALL BUTTONS */}
          {twilioCall &&
            <div className="flex items-center gap-1.5">
              {/* HANG UP BUTTON */}
              <button
                className="inline-flex items-center flex-auto w-1/2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={async () => {
                  if (applicantCallSid) {
                    await removeFromConference({
                      toRemove: applicantCallSid,
                    })
                    setApplicantCallSid('');
                  }
                  if (translatorCallSid) {
                    await removeFromConference({
                      toRemove: translatorCallSid,
                    })
                    setTranslatorCallSid('');
                  }
                  Object.values(conferenceNumbersSids).forEach(async sid => {
                    if (!sid) return;
                    await removeFromConference({
                      toRemove: sid,
                    })        
                  });
                  setConferenceNumbersSids({});
                  twilioCall.disconnect();
                  setTwilioCall(null);
                }}>{L.applicant.comms.hangup}
              </button>
              {/* MUTE / UNMUTE BUTTONS */}
              {isMuted ? (
                <button
                  id="button-unmute"
                  title={L.applicant.comms.unmute}
                  className={`inline-flex items-center mr-2 p-2 border border-transparent text-sm 
                      font-medium rounded-full text-white bg-red-600 hover:bg-red-700
                      focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  onClick={() => unmuteCall(twilioCall)}>
                  <MicrophoneMuteIcon className="w-5 h-5" />
                </button>
              ) : (
                <button 
                  id="button-mute"
                  title={L.applicant.comms.mute}
                  className={`inline-flex items-center mr-2 p-2 border border-transparent text-sm 
                    font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  onClick={() => muteCall(twilioCall)}>
                  <MicrophoneIcon className="w-5 h-5" />
                </button>
              )}
            </div>
          }

          {/* CONTACT SELECTOR */}
          {config.alternate_contact && <TailwindDropdown
            className="text-white inline-flex items-center mr-2 px-2 py-2"
            label={"Contact: " + (contactPhoneKey === 'phone_number' ?
              (props.info['legal_name'] || 'Applicant') :
              (props.info[contactNameKey] || contactLabel))}
            color="indigo" colorIntensity={600}
            options={[
              {
                label: props.info['legal_name'] || 'Applicant',
                callback: () => {
                  setContactPhoneKey("phone_number");
                  setContactEmailKey("email");
                  setContactNameKey("legal_name");
                  setContactLabel('Applicant');
                }
              },
              ...(safeParse(config.alternate_contact || '[]', []).filter((ac: any) => props.info[ac.phone_key] || props.info[ac.email_key]).map((ac: {
                label: Record<SupportedLanguage, string | undefined>,
                name_key: string,
                phone_key: string,
                email_key: string
              }) => ({
                label: (ac.label[context.lang] || ac.label['en']) + ": " + (props.info[ac.name_key] || 'Unknown'),
                callback: () => {
                  setContactPhoneKey(ac.phone_key);
                  setContactEmailKey(ac.email_key);
                  setContactNameKey(ac.name_key);
                  setContactLabel(ac.label[context.lang] || ac.label['en'] || "Unknown");
                }
              })))
            ]} />}
        </div>

        {/* TRANSLATION AND SUPPORT SERVICES */}
        {(!requireConsentToRecordCalls || (requireConsentToRecordCalls && !showingConsentBox)) && <div className="flex flex-col gap-2 mb-2">
          {(translationService || conferenceNumbers?.length) && twilioDevice && <>
            <div className="grid grid-cols-2 gap-2">
              {/* START CONFERENCE CALL BUTTON */}
              {!conferenceCall && <button
                className={`w-full inline-flex items-center mr-2 px-4 py-2 border border-transparent text-sm
            font-medium rounded-md shadow-sm text-white
            ${props.disabled ? 'bg-gray-300' : 'bg-indigo-600 hover:bg-indigo-700'}
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                onClick={async () => {
                  const to = (contactPhoneKey === 'phone_number'
                    ? (((mainContact || '').indexOf('@') === -1 ? mainContact : '') || props.info.phone_number || props.info.phone)
                    : props.info[contactPhoneKey] || '');
                  if (!to) return;
                  // if country code is included in phone number, do not try to replace it with +1.
                  console.log("Calling: " + To);
                  console.log('twilioCaller', twilioCaller)
                  const call = await twilioDevice.connect({
                    params: {
                      To,
                      callType: 'start_conference',
                      conferenceId: To.slice(1,), // use applicant phone number without + as conference id
                      ...(twilioCaller && { agent: twilioCaller })
                    }
                  });

                  console.log("call", call)

                  call.on('accept', (call) => {
                    console.log("accept", call);
                    setConferenceCall(true);
                  })
                  call.on('disconnect', (call) => {
                    console.log("disconnect", call);
                    setTwilioCall(null);
                    setConferenceCall(false);
                  })
                  call.on('cancel', (call) => {
                    console.log("cancel", call);
                    setTwilioCall(null);
                    setConferenceCall(false);
                  })
                  setTwilioCall(call);
                }} disabled={props.disabled || twilioVoiceChannel !== props.channel}>{L.applicant.comms.start_conference}</button>}

              {/* IN-CONFERENCE CALL BUTTONS */}
              {twilioDevice && conferenceCall && <>
                {!applicantCallSid && <button
                  className={`w-full inline-flex justify-center items-center flex-auto w-1/2 py-2 border border-transparent text-sm
                    font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  onClick={
                    // add participant to the conference
                    async () => {
                      setApplicantLoading(true);
                      const response = await addToConference({
                        to: props.info[contactPhoneKey] || '',
                        voiceChannel: twilioVoiceChannel || '',
                        conferenceName: To.slice(1,)
                      })
                      if (typeof response === 'string') {
                        setApplicantCallSid(response)
                      }
                      setApplicantLoading(false);
                    }}
                  disabled={applicantLoading}>{L.applicant.comms.add_applicant}</button>}
                {applicantCallSid && <button
                  className={`w-full inline-flex justify-center items-center flex-auto w-1/2 p-2 border border-transparent text-sm
                    font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  onClick={
                    // remove applicant from the conference
                    async () => {
                      setApplicantLoading(true);
                      endConfOnRemoveLastParticipant(applicantCallSid);
                      await removeFromConference({
                        toRemove: applicantCallSid,
                      })
                      setApplicantCallSid('');
                      setApplicantLoading(false);
                    }}
                  disabled={applicantLoading}>
                  <div className="flex justify-center items-center gap-x-2">
                    <div className={['', 'queued', 'ringing'].includes(applicantConfStatus) ? 'animate-pulse text-blue-200' : ''}>
                      <PhoneArrowUpRightIcon className="h-5 w-5" />
                    </div>{L.applicant.comms.remove_applicant}
                  </div></button>}
                {translationService && !translatorCallSid && <button
                  className={`w-full inline-flex justify-center items-center flex-auto w-1/2 py-2 border border-transparent text-sm
                    font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  onClick={
                    // add translator to the conference
                    async () => {
                      setTranslatorLoading(true);
                      const response = await addToConference({
                        to: 'translationService',
                        voiceChannel: twilioVoiceChannel || '',
                        conferenceName: To.slice(1,)
                      })
                      if (typeof response === 'string') {
                        setTranslatorCallSid(response)
                      }
                      setTranslatorLoading(false);
                    }}
                  disabled={translatorLoading}>{L.applicant.comms.add_interpreter}</button>}
                {translationService && translatorCallSid && <button
                  className={`w-full inline-flex justify-center items-center flex-auto w-1/2 p-2 border border-transparent text-sm
                    font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  onClick={
                    // remove translator from the conference
                    async () => {
                      setTranslatorLoading(true);
                      endConfOnRemoveLastParticipant(translatorCallSid);
                      await removeFromConference({
                        toRemove: translatorCallSid,
                      })
                      setTranslatorCallSid('');
                      setTranslatorLoading(false);
                    }}
                  disabled={translatorLoading}>
                  <div className="flex justify-center items-center gap-x-2">
                    <div className={['', 'queued', 'ringing'].includes(translatorConfStatus) ? 'animate-pulse text-blue-200' : ''}>
                      <PhoneArrowUpRightIcon className="h-5 w-5" />
                    </div>
                    <div>{L.applicant.comms.remove_interpreter}</div>
                  </div>
                </button>
                }
                {conferenceNumbers?.map((number, index) => {
                  return (
                    !conferenceNumbersSids[number.phoneNumber] ? <button
                      key={index}
                      className={`w-full inline-flex justify-center items-center flex-auto w-1/2 py-2 border border-transparent text-sm
                        font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700
                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={
                        // add support number to the conference
                        async () => {
                          setConferenceNumbersLoading(prevState => {
                            return { ...prevState, [number.phoneNumber]: true }
                          });
                          const response = await addToConference({
                            to: number.phoneNumber,
                            voiceChannel: twilioVoiceChannel || '',
                            conferenceName: To.slice(1,),
                            dialCode: number.dialCode || ''
                          })
                          if (typeof response === 'string') {
                            setConferenceNumbersSids(prevState => {
                              return { ...prevState, [number.phoneNumber]: response }
                            })
                          }
                          setConferenceNumbersLoading(prevState => {
                            return { ...prevState, [number.phoneNumber]: false }
                          });
                        }}
                      disabled={conferenceNumbersLoading[number.phoneNumber]}>Add {number.label[context.lang]}</button>
                      : <button
                        className={`w-full inline-flex justify-center items-center flex-auto w-1/2 p-2 border border-transparent text-sm
                          font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700
                          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        onClick={
                          // remove support number from the conference
                          async () => {
                            setConferenceNumbersLoading(prevState => {
                              return { ...prevState, [number.phoneNumber]: true }
                            });
                            endConfOnRemoveLastParticipant(conferenceNumbersSids[number.phoneNumber]);
                            await removeFromConference({
                              toRemove: conferenceNumbersSids[number.phoneNumber],
                            })
                            setConferenceNumbersSids(prevState => {
                              return { ...prevState, [number.phoneNumber]: '' }
                            });
                            setConferenceNumbersLoading(prevState => {
                              return { ...prevState, [number.phoneNumber]: false }
                            });
                          }}
                        disabled={conferenceNumbersLoading[number.phoneNumber]}>
                        <div className="flex justify-center items-center gap-x-2">
                          <div className={['', 'queued', 'ringing'].includes(conferenceNumberStatuses[number.phoneNumber]) ? 'animate-pulse text-blue-200' : ''}>
                            <PhoneArrowUpRightIcon className="h-5 w-5" />
                          </div>
                          <div>Remove {number.label[context.lang]}</div>
                        </div>
                      </button>
                  )
                })}
              </>}
            </div>
          </>}
        </div>}
      </div>

      {!showingConsentBox ? (
        <div>
          <FormControl as="textarea"
            style={{ minHeight: "40px" }}
            disabled={props.disabled || sending}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          {recordedAudioURL && <div className="flex justify-end mt-2">
            <div className='flex-column'>
              <div className='inline-flex items-center'>
                <audio className='h-10' controls src={recordedAudioURL} />
                <XMarkIcon className="h-7 w-7 hover:cursor-pointer" 
                  aria-hidden="true" onClick={() => {
                    setRecordedAudioURL(undefined);
                    setRecordedAudio(undefined);
                  }} />
              </div>
            </div>
          </div>}
          <div className="flex justify-end mt-2 border-none">
            {sending && <SpacedSpinner />}
            <SendMessageButton
              messageTypes={[
                ...(contactEmailKey && props.info[contactEmailKey] ? ['email'] : []),
                ...(contactPhoneKey && props.info[contactPhoneKey] ? ['sms'] : []),
                ...(contactPhoneKey && props.info[contactPhoneKey] && canSendWhatsApp ? ['whatsapp'] : []),
              ] as ('sms' | 'whatsapp' | 'email')[]}
              disabled={props.disabled || sending}
              refTimeZoneOffset={refTimeZoneOffset}
              localTimeZoneOffset={localTimeZoneOffset}
              dateOpts={dateOpts}
              usesTwilioSMS={!publicConfig?.comms?.gatewayConfig}
              onClick={(messageType: 'sms' | 'whatsapp' | 'email', date) => doSend(messageType, date ? new Date(date) : undefined)}
              saveRecordingCallback={(url, blob) => {
                setRecordedAudio(blob);
                setRecordedAudioURL(url);
              }}
            />
          </div>
        </div>
      ) : (
        <Card className={classNames('p-3 transition duration-700', showingConsentBox === 'notice' ? ' bg-yellow-100' : '', showingConsentBox === 'script' ? ' bg-green-100' : '')}>
          {showingConsentBox === 'notice' && (
            <div className="text-sm">
              <h5>{L.applicant.comms.consent.important_notice}</h5>
              <p>{L.applicant.comms.consent.please_read_script}</p>
              <p>{L.applicant.comms.consent.you_may_proceed}</p>
              <div className="flex justify-end gap-3">
                <Button onClick={async () => {
                  if (!twilioDevice) return;
                  setShowingConsentBox('script');
                  await doCall(To, twilioDevice, twilioCaller);
                }}>{L.applicant.comms.consent.start_call}</Button>
                <Button variant="secondary" onClick={() => setShowingConsentBox(null)}>{L.applicant.comms.consent.cancel}</Button>
              </div>
            </div>
          )}
          {showingConsentBox === 'script' && (
            <>
              <h5 className="mb-3">{L.applicant.comms.consent.read_this_script}</h5>
              <p className="italic">{customConsentScript || L.applicant.comms.consent.default_script}</p>
              <h6 className="my-3">{L.applicant.comms.consent.did_user_consent}</h6>
              <div className="flex justify-end gap-4">
                <Button isDisabled={!callAnswered} onClick={() => {
                  setConsentGranted(true);
                  setShowingConsentBox(null);
                  if (twilioCall) recordCall(twilioCall);
                }}>{L.applicant.comms.consent.yes_start_recording}</Button>
                <Button variant={callAnswered ? 'danger' : undefined} isDisabled={!callAnswered} onClick={async () => {
                  if (twilioCall) {
                    await saveUnrecordedOutboundCall({
                      callAgent: twilioCall.customParameters.get('agent'),
                      callSid: twilioCall.parameters.CallSid,
                      to: To,
                      channel: twilioVoiceChannel,
                      status: 'consent_denied'
                    });
                    setConsentGranted(false);
                    setShowingConsentBox(null);
                  }
                }}>{L.applicant.comms.consent.no_do_not_record}</Button>
              </div>
            </>)}
        </Card>
      )}
      
      <br />
      <div className="d-flex justify-content-end">
        <small><em>{L.applicant.comms.messages_will_automatically_refresh}</em></small>
      </div>
      <div className="flex justify-end my-2">
        {(messages?.data || []).some((m: any) => m.needs_attention) ?
          <ClickableButton disabled={resolving}
            color="green" extraClasses="text-white bg-green-600 hover:bg-green-700"
            onClick={() => resolveAllRequests(messages!.data.filter((m: any) => m.needs_attention).map((m: any) => m.id))}>
            {resolving && <SpacedSpinner />}{L.applicant.comms.mark_all_unhandled_handled}
          </ClickableButton> : <></>}
      </div>
      {whatsAppWindowClosed && <div className="mt-4">
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0 mt-3">
              <ExclamationTriangleIcon className="h-7 w-7 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <div className="text-sm text-yellow-700 -mb-4">
                <p>{L.applicant.comms.outside_whatsapp_window}</p>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {/* If requiring consent, we don't need to show this because a similar message is displayed in the show consent box */}
      {!requireConsentToRecordCalls && <div className="flow-root mt-5 mb-20">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <div className="flex justify-between">
            <div>
              <p className="text-sm text-yellow-700 font-semibold">{L.applicant.comms.reminder_staff}</p>
              <p className="text-sm text-yellow-700">{L.applicant.comms.important_obtain_explicit_consent}</p>
              <p className="text-sm text-yellow-700 font-semibold">{L.applicant.comms.example_consent}</p>
              <p className="text-sm text-yellow-700 italic">{L.applicant.comms.before_we_begin}</p>
            </div>
          </div>
        </div>
      </div>}
      <div className="flow-root mt-5 mb-20">
        {fetchingInitialMessages ? <><SpacedSpinner />{L.applicant.loading}</> : 
          (!messages?.data || messages.data.length === 0) ? (
            <div className="flex" key={"no_messages"}>
              {L.applicant.comms.no_messages_have_been_sent_or_received}
            </div>
          ) : null}
        <ul className="-mb-8">
          {(messages?.data || []).map((m: AidKitMessage, mIndex: number) => {
            const metadata = safeParse(m.metadata || '{}');
            // Don't show if incognito
            if ((metadata && metadata['incognito']) || mIndex > limit) {
              return <></>;
            }

            if (mIndex === limit) {
              return <li key={`message-${m.id}-more`}>
                <ClickableButton color="gray" extraClasses="text-sm" onClick={() => setLimit(limit + 20)}>
                  {L.applicant.comms.show_more}
                </ClickableButton>
              </li>
            }

            const statusColorMap = {
              'accepted': 'gray',
              'queued': 'gray',
              'sending': 'yellow',
              'sent': 'blue',
              'delivered': 'green',
              'failed': 'red',
              'undelivered': 'red',
              'scheduled': 'yellow',
              'received': 'indigo'
            } as const;

            const statusTranslations = {
              'accepted': L.applicant.comms.accepted,
              'queued': L.applicant.comms.queued,
              'sending': L.applicant.comms.sending,
              'sent': L.applicant.comms.sent,
              'delivered': L.applicant.comms.delivered,
              'failed': L.applicant.comms.failed,
              'undelivered': L.applicant.comms.undelivered,
              'received': L.applicant.comms.received,
              'scheduled': L.applicant.comms.scheduled
            } as const;

            const alternateContacts = safeParse(config.alternate_contact || '[]', []);

            const isContactMatch = (contact: string, checkAgainst?: string) => (
              checkAgainst && (contact === checkAgainst || contact === '+1' + checkAgainst)
            );

            // Easily determine direction based on Twilio or AWS Metadata
            let direction = (m.needs_attention || metadata.handled_by || metadata.ToCountry || metadata.eventSource === "aws:ses" || metadata.isInboundVoiceRecordingDisabled)
              ? 'inbound'
              : 'outbound';

            let applicantName;

            // Look for specific contact matches and assign the applicant name
            let applicantLabel = 'Current Phone';
            let applicantSource = '' as '' | 'current' | 'alternate' | 'old';
            for (let contact of ['phone_number', 'email']) {
              if (isContactMatch(direction === 'inbound' ? m.source : m.destination, props.info[contact])) {
                applicantName = props.info['legal_name'];
                if (contact === 'email') applicantLabel = 'Current Email';
                applicantSource = 'current';
              }
            }

            for (const ac of alternateContacts) {
              for (let key of ['phone_key', 'email_key']) {
                if (isContactMatch(direction === 'inbound' ? m.source : m.destination, props.info[ac[key]])) {
                  applicantName = props.info[ac.name_key] || L.applicant.comms.alternate_contact
                  applicantLabel = ac.label[context.lang];
                  applicantSource = 'alternate';
                }
              }
            }

            if (!applicantName) {
              applicantName = props.info['legal_name'] || m.source;
            }

            let author = direction === 'inbound' ? applicantName : (m.author_name || 'System');

            // assume status is received if source is applicant.
            if (direction === 'inbound' && !m.status && !m.needs_attention) {
              m.status = 'received';
            }

            const messageDisplayType = m.kind === 'whatsapp' ? ' WhatsApp' : m.kind === 'sms' ? ' SMS' : '';

            const statusColor = statusColorMap[m.status as keyof typeof statusColorMap] || 'gray';

            const canDebugMessages = (userContext?.tags ?? []).some((tag) => ['admin'].includes(tag));
            const canManageMessages = (userContext?.tags ?? []).some((tag) => ['admin', 'comms admin'].includes(tag));
            
            const hiddenMessage = hiddenMessagesMap?.[m.id];

            const createdAt = typeof m.created_at === 'number' ?
              moment.unix(m.created_at).calendar() : new Date(m.created_at).toLocaleString(context.lang) + " " + getLocalTimezoneSuffix();
            const createdAtDate = typeof m.created_at === 'number' ?
              moment.unix(m.created_at) : new Date(m.created_at);
            const sendAt = metadata.sendAt ? moment(metadata.sendAt) : undefined;

            const blankWhatsAppMsg = m.kind === 'whatsapp' &&
              m.message.trim().length === 0 &&
              !(metadata['attachments']?.length);
            return (
              <li key={m.id}>
                <div className="relative pb-10">
                  {mIndex !== (limit > (messages?.data || []).length ? (messages?.data || []).length : limit) - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3 px-1">
                    <>
                      <div className="relative">
                        <div className={`h-10 w-10 rounded-full ${direction === 'inbound' ? 'bg-gray-300' : 'bg-blue-800 p-2'} flex items-center justify-center ring-8 ring-white z-0`}>
                          {direction === 'outbound' && <MiniAidKitLogo fill="white" width={50} height={50} /> }
                          {direction === 'inbound' && Selfie && <img className="w-10 h-10 rounded-full" src={Selfie} />}
                        </div>
                        <span className="absolute -bottom-0.5 -right-1 bg-transparent rounded-tl px-0.5 py-px">
                          {m.kind === 'sms' ?
                            direction === 'inbound'
                              ? <ChatBubbleLeftRightIcon className="h-5 w-5 text-yellow-50" aria-hidden="true" />
                              : <ChatBubbleLeftRightIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
                            :
                            m.kind === 'mms' ?
                              <DocumentTextIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> :
                              m.kind === 'email' ?
                                <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> :
                                m.kind === 'call' ?
                                  (direction === 'inbound' ?
                                    <PhoneArrowDownLeftIcon className="h-5 w-5 text-gray-400 bg-transparent" aria-hidden="true" /> :
                                    <PhoneArrowUpRightIcon className="h-5 w-5 text-gray-400 bg-transparent" aria-hidden="true" />) :
                                  <></>}
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 whitespace-pre-wrap break-words">
                        <div>
                          <div className="text-sm font-light text-gray-900 flex flex-column">
                            <div className="flex justify-between font-medium text-lg">
                              <div className="flex items-center gap-x-2 gap-y-1 flex-wrap">
                                <span>{author}</span>
                                <span>{(m.status || m.needs_attention) && (
                                  <div className="flex items-center">
                                    {direction === 'inbound' && m.needs_attention
                                      ? <TailwindBadge color="yellow">
                                        {L.applicant.comms.new_unhandled}
                                      </TailwindBadge>
                                      : <TailwindBadge color={statusColor}>
                                        {statusTranslations[m.status as keyof typeof statusTranslations] || m.status}
                                      </TailwindBadge>}
                                  </div>
                                )}</span>
                              </div>
                              {(canDebugMessages || canManageMessages) && (
                                <Menu as="div" className="relative inline-block text-left mt-0.5">
                                  <div>
                                    <Menu.Button className="flex rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                      <span className="sr-only">{L.applicant.comms.open_options}</span>
                                      <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6" />
                                    </Menu.Button>
                                  </div>

                                  <Menu.Items
                                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                  >
                                    <div className="py-1">
                                      {canDebugMessages && <Menu.Item>
                                        {({active }) => (
                                          <button
                                            onClick={() => handleMessageOptionsClick(m, 'debug')}
                                            className={`block px-4 py-2 w-full text-left text-sm text-gray-700 focus:bg-gray-100 focus:text-gray-900 focus:outline-none ${active ? 'bg-gray-100 text-gray-900 outline-none' : ''}`}
                                          >
                                            {L.applicant.comms.debug_message}
                                          </button>
                                        )}
                                      </Menu.Item>}
                                      {/* Don't show Hide Message dropdown option if the message has already been hidden */}
                                      {canManageMessages && <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() => handleMessageOptionsClick(m, 'hide')}
                                            className={`block px-4 py-2 w-full text-left text-sm text-gray-700 focus:bg-gray-100 focus:text-gray-900 focus:outline-none ${active ? 'bg-gray-100 text-gray-900 outline-none' : ''}`}
                                          >
                                            {L.applicant.comms.hide_message}
                                          </button>
                                        )}
                                      </Menu.Item>}
                                    </div>
                                  </Menu.Items>
                                </Menu>
                              )}
                            </div>

                            <span hidden={!sendAt} className="text-xs" title={sendAt?.toDate().toLocaleString(context.lang)}>
                              <span className="font-semibold">{L.support.scheduled_for}:</span>&nbsp;
                              {sendAt?.toDate().toLocaleString(context.lang)}&nbsp;{getLocalTimezoneSuffix()}
                            </span>
                            <span hidden={!!sendAt} className="text-xs" title={createdAtDate.toLocaleString(context.lang)}>{createdAt}</span>
                            {m.kind === 'call'
                              ? <div>{direction === 'inbound' ? L.applicant.comms.inbound_call : L.applicant.comms.outbound_call}</div>
                              : <div>{(direction === 'inbound' ? L.applicant.comms.inbound_message : L.applicant.comms.outbound_message).replace("$type", messageDisplayType)}</div>}
                            <div>{L.applicant.comms.from.replace('$from', m.source === '+1' ? L.applicant.comms.messaging_service : prettyPhone(m.source))}</div>
                            <div>{L.applicant.comms.to.replace('$to', prettyPhone(m.destination))}</div>
                          </div>
                        </div>
                        {props.info && (applicantSource !== 'current'
                          || (metadata.onBehalfOfApplicant && metadata.onBehalfOfApplicant !== applicant)) && 
                          <p className="mt-0.5 text-sm text-gray-500 flex items-center">
                            <InformationCircleIcon className="h-4 w-4" />&nbsp;
                            {metadata.onBehalfOfApplicant
                              ? <span>This message was sent {metadata.onBehalfOfApplicant === applicant
                                ? "to " + (props.info.legal_name || props.info.uid || 'the applicant') + "'s " + applicantLabel
                                : <a target="_blank" 
                                  href={"/" + aOrApplicant + "/" + metadata.onBehalfOfApplicant}>on behalf of Another Applicant</a>}</span>
                              : <span>{applicantSource}</span>}
                          </p>}
                        <div className="mt-2 text-sm text-gray-700">
                          <div>
                            {/* THE MESSAGE ITSELF */}
                            {hiddenMessage && !metadata.hidden?.attachmentOnly ? (
                              <div className="italic">{hiddenMessage}</div>
                            ) : (
                              m.kind === 'call'
                                ? metadata.RecordingUrl ? <audio controls src={metadata.RecordingUrl + '.mp3?cb=' + (new Date(createdAtDate.toString()).getTime() < (new Date().getTime() - 10000) ? 'constant' : fetchVMs)} />
                                  : metadata.isInboundVoiceRecordingDisabled
                                    ? <div>{L.applicant.comms.inbound_voice_recordings_disabled}</div>
                                    : <div>{L.applicant.comms.consent.call_not_recorded}</div>
                                : <TranslateWrapper
                                  id={`message-${m.id}`}
                                  applicant_lang={props.info.language || 'en'}
                                  desired_lang={context.lang}
                                  translateText={m.message}
                                  body={blockify(m.message || '', (str) => prettyTextMessage(L, str, blankWhatsAppMsg))}
                                />
                            )}
                            {/* MESSAGE ATTACHMENTS */}
                            {hiddenMessage && metadata.hidden?.attachmentOnly
                              ? <div className="italic">{hiddenMessage}</div>
                              : !hiddenMessage && metadata.attachments?.length > 0 && (
                                <InlineAttachments
                                  attachments={metadata['attachments'].join(',')}
                                  attachmentTypes={metadata['attachmentTypes']?.length > 0 ? metadata['attachmentTypes'].join(',') : ''}
                                  Viewer='screener'
                                />
                              )
                            }
                          </div>
                        </div>
                        {!m.needs_attention && metadata && metadata['handled_by'] ?
                          <div className="mt-2 text-xs text-gray-500">
                            <div className="flex flex-row justify-start">
                              <em>
                                {L.applicant.comms.handled_by} {metadata['handled_by']} {(metadata['handled_date'] || '').toString().indexOf('Z') !== -1 ? moment(metadata['handled_date']).calendar() : moment.unix(metadata['handled_date']).calendar()}
                              </em>
                            </div>
                          </div>
                          : <></>}
                        <div className="mt-2 text-sm text-gray-700">
                          {m.needs_attention &&
                            <></>}
                          {!m.platform_message_id && (
                            <div className="w-100" style={{ float: "right" }}>
                              <Dropdown>
                                <Dropdown.Toggle variant="link"
                                  size="sm" style={{ float: "right", color: "#bb0000" }}>
                                  <em>{L.applicant.comms.message_failed}</em>
                                </Dropdown.Toggle>

                                {retrySendMessage && confirmDeleteMessage && <Dropdown.Menu>
                                  {/* commenting this out for nonLegacy comms as this is a legacy endpoint to retry */}
                                  {/* <Dropdown.Item key={`retry-${m.id}`} onClick={() => retrySendMessage(m.id)}>
                                    {L.applicant.comms.try_again}
                                  </Dropdown.Item> */}
                                  <Dropdown.Item key={`delete-${m.id}`} onClick={() => confirmDeleteMessage(m.id)}>
                                    {L.applicant.comms.delete_message}
                                  </Dropdown.Item>
                                </Dropdown.Menu>}
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </li>)
          }
          )}
        </ul>
      </div>
    </div>
  );
}

/**
 * Helper function to create button links out of urls in texts.
 * If @param blankWhatsAppMsg is supplied, a default message like "Unable to display WhatsApp message"
 * will be returned instead of whatever was inside @param text
 */
function prettyTextMessage(L: ReturnType<typeof useLocalizedStrings>, text: string, blankWhatsAppMsg?: boolean) {
  if (blankWhatsAppMsg) {
    return <em>{L.applicant.comms.blank_whatsapp_message}</em>;
  }

  if (!text) return <></>;

  if (text && text === '--') {
    return <small><em>{L.applicant.comms.media_message}</em></small>;
  }

  /* Split apart the text around phone numbers, and then wrap the phone numbers with the <bdi> tag
  * to ensure that they are displayed LTR even in RTL contexts. Check all other parts of the text for
  * links and convert any found to <a> elements.
  *
  * Regex explanation:
  * Capturing group, surrounding...
  * /(
  *   Optional, non-capturing group of optional + followed by 1-3 digits (country code),
  *   optionally followed by whitespace, ., or -
  *   (?:\+?\d{1,3}[\s.-]?)?
  *   Non-capturing group containing either 3 digits in parentheses or not (area code),
  *   optionally followed by whitespace, ., or -
  *   (?:(?:\(\d{3}\))|\d{3})[\s.-]?
  *   3 digits optionally followed by whitespace, ., or - followed by 4 digits (7 digit phone number)
  *   \d{3}[\s.-]?\d{4}
  * )/g
  */
  const phoneNumberRegex = new RegExp(/((?:\+?\d{1,3}[\s.-]?)?(?:(?:\(\d{3}\))|\d{3})[\s.-]?\d{3}[\s.-]?\d{4})/g);
  const wrappedParts = text.split(phoneNumberRegex)
    .filter(x => !!x)
    .map((part) => phoneNumberRegex.test(part) ? wrapWithBdi(part) : renderLinks(part));
  return <>{wrappedParts}</>;
}

type LegacyCommsProps = {
  uid: string;
  canSendComms: boolean,
  info: Record<string, string>,
  internalAudit?: boolean,
  setUnhandledCount: (count: number) => void
};

export function LegacyComms(props: LegacyCommsProps) {
  const L = useLocalizedStrings();
  const config = useContext(ConfigurationContext);

  const [commsDisabled, setCommsDisabled] = useState(false);
  useEffect(() => {
    if (!config || Object.keys(config).length === 0) return;
    if ((config.roles || '').indexOf('internal audit') !== -1 && !props.internalAudit) {
      setCommsDisabled(true);
    }
  }, [config]);

  // get messages from state
  const [messages, refreshMessages] = useAPI("/applicant/" + props.uid + "/get_messages?mode=" + (props.internalAudit ? 'internal_audit' : 'default'), true);

  useEffect(() => {
    if (!messages || !('unhandled' in messages)) return;
    props.setUnhandledCount(messages.unhandled);
  }, [messages]);

  // Don't call if document hidden
  const cb = useCallback(() => {
    if (document.hidden) return;
    refreshMessages();
  }, [refreshMessages]);
  useInterval(cb, 5000);

  // Legacy API Posts
  const send_message = useAPIPost("/applicant/" + props.uid + "/send_message");

  const resend_message = useAPIPost("/message/.../resend_message");
  async function retrySendMessage(message_id: string) {
    await resend_message({}, "/message/" + message_id + "/resend_message");
    await refreshMessages();
  }

  const delete_message = useAPIPost("/message/.../delete_message");
  async function confirmDeleteMessage(message_id: string) {
    const yn = await window.confirm(L.applicant.comms.are_you_sure);
    if (yn) {
      if (delete_message) await delete_message({}, "/message/" + message_id + "/delete_message");
      if (refreshMessages) await refreshMessages();
    }
  }

  return <LegacyCommsWindow info={props.info}
    uid={props.uid} messages={messages} disabled={commsDisabled}
    internalAudit={props.internalAudit}
    send_message={send_message}
    retrySendMessage={retrySendMessage}
    confirmDeleteMessage={confirmDeleteMessage}
  />
}

export function LegacyCommsWindow(props: {
  uid?: string,
  contact?: string,
  info: Record<string, string | undefined>,
  messages?: {
    data: AidKitMessage[]
  },
  disabled?: boolean,
  refreshMessages?: () => Promise<void>,
  internalAudit?: boolean,
  send_message: (params: {
    uid?: string,
    phone?: string,
    message: string,
    has_link?: boolean,
    internal_audit?: boolean,
    contact_phone_key?: string,
    send_at?: Date
  }) => Promise<{
    message?: string,
    error?: string
  }>,
  retrySendMessage?: (params: any) => Promise<any>,
  confirmDeleteMessage?: (params: any) => Promise<any>
}) {

  // Boiler plate
  const { messages, refreshMessages, send_message, retrySendMessage, confirmDeleteMessage } = props;
  const L = useLocalizedStrings();
  const localTimeZoneId = useLocalTimeZoneId();
  const programTimeZoneId = useProgramTimeZoneId();
  
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const { toast } = useToast();

  const { twilioDevice, twilioCall, twilioCaller, setTwilioCall } = useContext(TwilioDeviceContext);
  // API Routes to call
  const sendEmail = usePost('/applicant/send_email');
  const [message, setMessage] = useState("");
  const [contactPhoneKey, setContactPhoneKey] = useState("phone_number");
  const [contactEmailKey, setContactEmailKey] = useState("email");
  const [contactNameKey, setContactNameKey] = useState("legal_name");
  const [contactLabel, setContactLabel] = useState("Applicant");
  const [isMuted, setMuted] = useState(false);

  // limit amount of messages to show
  const [limit, setLimit] = useState(20);

  // For showing a spinner when resolving unhandled messages
  const [resolving, setResolving] = useState(false);
  const resolveRequests = usePost("/messages/resolve_all");

  const [cbTimeout, setCbTimeout] = useState(null as null | ReturnType<typeof setTimeout>);
  const [fetchVMs, setFetchVMs] = useState(0);

  // reply scheduling
  const [dateOpts, setDateOpts] = useState<PresetDateOpts>([]);
  const [localTimeZoneOffset, setLocalTimeZoneOffset] = useState<TimezoneOffset>();
  const [refTimeZoneOffset, setRefTimeZoneOffset] = useState<TimezoneOffset>();

  const getSourceInfo = useCallback((m: any) => {
    if (m.source === props.contact || m.source === (`+1${props.info['phone_number']}` || m.source === props.info['email'])) {
      return {
        source: 'applicant',
        sourceType: 'inbound',
        sourceName: props.info['legal_name'] || 'Applicant',
      }
    }

    const alternateContacts = safeParse(config.alternate_contact || '[]', []);
    for (const ac of alternateContacts) {
      if (m.source === props.info[ac.phone_key] || m.source === '+1' + props.info[ac.phone_key] || m.source === props.info[ac.email_key]) {
        return {
          source: 'alternate_contact',
          sourceType: 'inbound',
          sourceName: props.info[ac.name_key] || 'Alternate Contact'
        }
      }
    }

    return {
      source: 'screener',
      sourceType: 'outbound',
      sourceName: m.author || 'System'
    }
  }, [props.info, config]);

  async function resolveAllRequests(message_ids: number[]) {
    setResolving(true);
    await resolveRequests({
      applicant: props.uid || 'unknown',
      message_ids
    });
    setResolving(false);
    if (refreshMessages) {
      await refreshMessages();
    }
  }

  async function doSendEmail() {
    if (message.length === 0) {
      alert(L.applicant.notes.please_enter_a_message);
      return;
    }

    const response = await sendEmail({
      applicant: props.uid,
      email: ((props.contact || '').indexOf('@') !== -1 ? props.contact : '') || props.info[contactEmailKey],
      message: message,
      from: config.comms_from_email,
      ...(props.internalAudit && { internalAudit: true })
    });

    if (response.status) toast({ description: response.status });

    setMessage("");
    if (refreshMessages) await refreshMessages();
  }

  async function sendMessage(sendAt?: Date) {
    if (message.length === 0) {
      alert(L.applicant.notes.please_enter_a_message);
      return;
    }

    const has_link = message.includes('https:') ? true : false;
    try {
      const response = await send_message({
        uid: props.uid,
        phone: ((props.contact || '').indexOf('@') === -1 ? props.contact : '') || props.info.phone_number || props.info.phone,
        message,
        has_link,
        send_at: sendAt,
        ...(props.internalAudit && { internal_audit: true }),
        ...(contactPhoneKey && { contact_phone_key: contactPhoneKey })
      });

      if (response && response.message) toast({ description: response.message });

      setMessage("");
      if (refreshMessages) await refreshMessages();
    } catch (e) {
      console.warn("Error sending message: ", e);
      toast({
        description: "Error sending message, please try again",
        variant: 'error'
      });
    }
  }

  function muteCall (twilioCall: Call) {
    twilioCall.mute(true);
    setMuted(true);
  }

  function unmuteCall (twilioCall: Call) {
    twilioCall.mute(false);
    setMuted(false);
  }

  useEffect(() => {
    if (!twilioCall) {
      setMuted(false);
      return;
    }

    twilioCall.on('disconnect', () => {
      setCbTimeout(setTimeout(() => {
        setFetchVMs(prevState => prevState + 1);
      }, 3000));
    })

    return () => {
      if (cbTimeout) clearTimeout(cbTimeout);
    }
  }, [twilioCall]);

  useEffect(() => {
    setLocalTimeZoneOffset(getTimeZoneData(localTimeZoneId));
    setRefTimeZoneOffset(getTimeZoneData(programTimeZoneId || localTimeZoneId));
  }, [publicConfig]);

  useEffect(() => {
    const core: typeof dateOpts = [];
    const configured = (publicConfig.comms?.replyScheduling?.options ?? [
      {
        days: 1,
        hours: 9,
        minutes: 0,
        name: 'Tomorrow @ 9am'
      },
      {
        days: 1,
        hours: 14,
        minutes: 0,
        name: 'Tomorrow @ 2pm'
      }
    ])
      .map(t => makeDateOption(t, refTimeZoneOffset?.offset))
      .filter(t => t.date && t.date > Date.now());

    setDateOpts(core.concat(configured));
  }, [refTimeZoneOffset]);


  return (
    <div>
      <div className="flex items-center">
        {((contactPhoneKey && props.info[contactPhoneKey])) ?
          (!twilioCall && twilioDevice &&
            <button
              className={`inline-flex items-center -mt-2 mr-2 mb-2 px-4 py-2 border border-transparent text-sm 
            font-medium rounded-md shadow-sm text-white 
            ${props.disabled ? 'bg-gray-300' : 'bg-indigo-600 hover:bg-indigo-700'}
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              onClick={async () => {
                const to = (contactPhoneKey === 'phone_number'
                  ? (((props.contact || '').indexOf('@') === -1 ? props.contact : '') || props.info.phone_number || props.info.phone)
                  : props.info[contactPhoneKey] || '');
                if (!to) return;
                const To = '+1' + to.replace(/^\+1/, '');
                console.log("Calling: " + To);
                const call = await twilioDevice.connect({
                  params: {
                    To,
                    ...(twilioCaller && { agent: twilioCaller })
                  }
                });

                call.on('accept', (call) => {
                  console.log("accept", call);
                })
                call.on('disconnect', (call) => {
                  console.log("disconnect", call);
                  setTwilioCall(null);
                })
                call.on('cancel', (call) => {
                  console.log("cancel", call);
                  setTwilioCall(null);
                })

                setTwilioCall(call);
              }} disabled={props.disabled}>{L.applicant.comms.call}</button>) : <div></div>}
        {twilioCall &&
          <>
            <button
              className="inline-flex items-center -mt-2 mr-2 mb-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={async () => {
                twilioCall.disconnect();
                setTwilioCall(null);
              }}>{L.applicant.comms.hangup}
            </button>
            {isMuted ? (
              <button
                id="button-unmute"
                title={L.applicant.comms.mute}
                className={`inline-flex items-center -mt-2 mb-2 mr-2 p-2 border border-transparent text-sm 
                  font-medium rounded-full text-white bg-red-600 hover:bg-red-700
                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                onClick={() => unmuteCall(twilioCall)}>
                <MicrophoneMuteIcon className="w-5 h-5" />
              </button>
            ) : (
              <button 
                id="button-mute"
                title={L.applicant.comms.mute}
                className={`inline-flex items-center -mt-2 mb-2 mr-2 p-2 border border-transparent text-sm 
                  font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700
                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                onClick={() => muteCall(twilioCall)}>
                <MicrophoneIcon className="w-5 h-5" />
              </button>
            )}
          </>}
        {config.alternate_contact && <TailwindDropdown
          className="text-white inline-flex items-center -mt-2 mr-2 mb-2 px-2 py-2"
          label={"Contact: " + (contactPhoneKey === 'phone_number' ?
            (props.info['legal_name'] || 'Applicant') :
            (props.info[contactNameKey] || contactLabel))}
          color="indigo" colorIntensity={600}
          options={[
            {
              label: props.info['legal_name'] || 'Applicant',
              callback: () => {
                setContactPhoneKey("phone_number");
                setContactEmailKey("email");
                setContactNameKey("legal_name");
                setContactLabel('Applicant');
              }
            },
            ...(safeParse(config.alternate_contact || '[]', []).map((ac: {
              label: Record<SupportedLanguage, string | undefined>,
              name_key: string,
              phone_key: string,
              email_key: string
            }) => ({
              label: (ac.label[context.lang] || ac.label['en']) + ": " + (props.info[ac.name_key] || 'Unknown'),
              callback: () => {
                setContactPhoneKey(ac.phone_key);
                setContactEmailKey(ac.email_key);
                setContactNameKey(ac.name_key);
                setContactLabel(ac.label[context.lang] || ac.label['en'] || "Unknown");
              }
            })))
          ]} />
        }
      </div>
      <div>
        <FormControl as="textarea"
          style={{ minHeight: "40px" }}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
        <div className="flex justify-end mt-2">
          <SendMessageButton
            messageTypes={[
              ...(contactEmailKey && props.info[contactEmailKey] ? ['email'] : []),
              ...(contactPhoneKey && props.info[contactPhoneKey] ? ['sms'] : [])] as ('sms' | 'whatsapp' | 'email')[]
            }
            disabled={!!props.disabled}
            refTimeZoneOffset={refTimeZoneOffset}
            localTimeZoneOffset={localTimeZoneOffset}
            dateOpts={dateOpts}
            onClick={(messageType, date) => {
              if (messageType === 'email') {
                doSendEmail()
              } else {
                sendMessage(date ? new Date(date) : undefined)
              }
            }}
            usesTwilioSMS={!publicConfig.comms?.gatewayConfig}
            saveRecordingCallback={(url, blob) => {}}
          />
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-end">
        <small><em>{L.applicant.comms.messages_will_automatically_refresh}</em></small>
      </div>
      <div className="flex justify-end my-2">
        {(messages?.data || []).some((m: any) => m.needs_attention) ?
          <ClickableButton disabled={resolving}
            color="green" extraClasses="text-white bg-green-600 hover:bg-green-700"
            onClick={() => resolveAllRequests(messages!.data.filter((m: any) => m.needs_attention).map((m: any) => m.id))}>
            {resolving && <SpacedSpinner />}{L.applicant.comms.mark_all_unhandled_handled}
          </ClickableButton> : <></>}
      </div>
      <div className="flow-root mt-5 mb-20">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <div className="flex justify-between">
            <div>
              <p className="text-sm text-yellow-700 font-semibold">{L.applicant.comms.reminder_staff}</p>
              <p className="text-sm text-yellow-700">{L.applicant.comms.important_obtain_explicit_consent}</p>
              <p className="text-sm text-yellow-700 font-semibold">{L.applicant.comms.example_consent}</p>
              <p className="text-sm text-yellow-700 italic">{L.applicant.comms.before_we_begin}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flow-root mt-5 mb-20">
        {(!messages?.data || messages.data.length === 0) && (
          <div className="flex" key={"no_messages"}>
            {L.applicant.comms.no_messages_have_been_sent_or_received}
          </div>
        )}
        <ul className="-mb-8">
          {(messages?.data || []).map((m: any, mIndex: number) => {
            const metadata = safeParse(m.metadata || '{}');
            // Don't show if incognito
            if ((metadata && metadata['incognito']) || mIndex > limit) {
              return <></>;
            }

            if (mIndex === limit) {
              return <li key={`message-${m.id}-more`}>
                <ClickableButton color="gray" extraClasses="text-sm" onClick={() => setLimit(limit + 20)}>
                  {L.applicant.comms.show_more}
                </ClickableButton>
              </li>
            }

            const statusColorMap = {
              'accepted': 'gray',
              'queued': 'gray',
              'sending': 'yellow',
              'sent': 'blue',
              'delivered': 'green',
              'failed': 'red',
              'undelivered': 'red',
              'received': 'indigo'
            } as const;

            const statusTranslations = {
              'accepted': L.applicant.comms.accepted,
              'queued': L.applicant.comms.queued,
              'sending': L.applicant.comms.sending,
              'sent': L.applicant.comms.sent,
              'delivered': L.applicant.comms.delivered,
              'failed': L.applicant.comms.failed,
              'undelivered': L.applicant.comms.undelivered,
              'received': L.applicant.comms.received
            } as const;

            const { sourceType, sourceName } = getSourceInfo(m);

            // assume status is received if source is applicant.
            if (sourceType === 'inbound' && !m.status && !m.needs_attention) {
              m.status = 'received';
            }

            const statusColor = statusColorMap[m.status as keyof typeof statusColorMap] || 'gray';

            const createdAt = typeof m.created_at === 'number' ?
              moment.unix(m.created_at).calendar() : new Date(m.created_at).toLocaleString(context.lang);
            const createdAtDate = typeof m.created_at === 'number' ?
              moment.unix(m.created_at) : new Date(m.created_at);

            return (
              <li key={m.id}>
                <div className="relative pb-8">
                  {mIndex !== (limit > (messages?.data || []).length ? (messages?.data || []).length : limit) - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <>
                      <div className="relative">
                        <div className={`h-10 w-10 rounded-full ${sourceType === 'inbound' ? 'bg-gray-400' : 'bg-blue-800'} flex items-center justify-center ring-8 ring-white`}></div>
                        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                          {m.kind === 'sms' ?
                            <ChatBubbleLeftRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> :
                            m.kind === 'mms' ?
                              <DocumentTextIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> :
                              m.kind === 'email' ?
                                <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> :
                                m.kind === 'call' ?
                                  (sourceType === 'inbound' ?
                                    <PhoneArrowDownLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> :
                                    <PhoneArrowUpRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />) :
                                  <></>}
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div>
                          <div className="text-sm font-medium text-gray-900 flex flex-row justify-between">
                            {sourceName}
                            <div>{L.applicant.comms.from.replace('$from', m.source === '+1' ? L.applicant.comms.messaging_service : prettyPhone(m.source))}</div>
                          </div>
                          <div className="mt-0.5 text-sm text-gray-500">
                            <div className="flex flex-row justify-between">
                              <div className="space-x-2">
                                {(m.status || m.needs_attention) ?
                                  sourceType === 'inbound' && m.needs_attention ?
                                    <TailwindBadge color="yellow">
                                      {L.applicant.comms.new_unhandled}
                                    </TailwindBadge> :
                                    <TailwindBadge color={statusColor}>
                                      {statusTranslations[m.status as keyof typeof statusTranslations] || m.status}
                                    </TailwindBadge> : <></>}
                                <span title={createdAtDate.toString()}>{createdAt}</span>
                              </div>
                              <div>{L.applicant.comms.to.replace('$to', prettyPhone(m.destination))}</div>{}
                            </div>
                          </div>
                          <p className="mt-0 text-sm text-gray-500"></p>
                        </div>
                        <div className="mt-2 text-sm text-gray-700">
                          <div>{m.kind === 'call' ?
                            <><audio controls src={metadata.RecordingUrl + '.mp3?cb=' + (new Date(createdAtDate.toString()).getTime() < (new Date().getTime() - 10000) ? 'constant' : fetchVMs)} /></>
                            :
                            React.createElement(TranslateWrapper, {
                              id: `message-${m.id}`,
                              applicant_lang: props.info.language || 'en',
                              desired_lang: context.lang,
                              translateText: m.message,
                              body: blockify(m.message || '', (str) => prettyTextMessage(L, str))
                            })}
                          {metadata && metadata['attachments'] && metadata['attachments'].length > 0 && (
                            <InlineAttachments
                              attachments={metadata['attachments'].join(',')}
                              attachmentTypes={metadata['attachmentTypes']?.length > 0 ? metadata['attachmentTypes'].join(',') : ''}
                              Viewer='screener' />)}</div>
                        </div>
                        {!m.needs_attention && metadata && metadata['handled_by'] ?
                          <div className="mt-2 text-xs text-gray-500">
                            <div className="flex flex-row justify-start">
                              <em>
                                {L.applicant.comms.handled_by} {metadata['handled_by']} {(metadata['handled_date'] || '').toString().indexOf('Z') !== -1 ? moment(metadata['handled_date']).calendar() : moment.unix(metadata['handled_date']).calendar()}
                              </em>
                            </div>
                          </div>
                          : <></>}
                        <div className="mt-2 text-sm text-gray-700">
                          {m.needs_attention &&
                            <></>}
                          {!m.platform_message_id && (
                            <div className="w-100" style={{ float: "right" }}>
                              <Dropdown>
                                <Dropdown.Toggle variant="link"
                                  size="sm" style={{ float: "right", color: "#bb0000" }}>
                                  <em>{L.applicant.comms.message_failed}</em>
                                </Dropdown.Toggle>

                                {retrySendMessage && confirmDeleteMessage && <Dropdown.Menu>
                                  <Dropdown.Item key={`retry-${m.id}`} onClick={() => retrySendMessage(m.id)}>
                                    {L.applicant.comms.try_again}
                                  </Dropdown.Item>
                                  <Dropdown.Item key={`delete-${m.id}`} onClick={() => confirmDeleteMessage(m.id)}>
                                    {L.applicant.comms.delete_message}
                                  </Dropdown.Item>
                                </Dropdown.Menu>}
                              </Dropdown>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </li>)
          }
          )}
        </ul>
      </div>
    </div>
  );
}

function RecordAudioButton(props: {
  saveRecordingCallback: (url: string, audioBlob: Blob) => void,
  disabled: boolean,
  isRecordingCallback?: (b: boolean) => void
}) {
  const AUDIO_LIMIT = 60; // seconds

  const L = useLocalizedStrings();

  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder>();
  const [recording, setRecording] = useState(false);
  const [chosenCodec, setChosenCodec] = useState<string>();

  // counts how long our recording is. Especially useful if we have a limit.
  const [counter, setCounter] = React.useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (recording) {
      if (AUDIO_LIMIT - counter <= 0) {
        endRecording();
        setCounter(0);
        return;
      }
      timeoutRef.current = setTimeout(() => setCounter(counter + 1), 1000);
    } else {
      setCounter(0);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }

    return () => { if (timeoutRef.current) clearTimeout(timeoutRef.current) };
  }, [counter, recording]);

  // begin recording audio for a voice message
  const beginRecording = useCallback(async () => {
    if (props.isRecordingCallback) props.isRecordingCallback(true);
    const audioCodecs = [
      'audio/wav',
      'audio/wave',
      'audio/webm',
      'audio/ogg',
      'audio/mp4',
      'audio/aac', // AAC audio codec
      'audio/mpeg' // MP3 audio codec
    ];

    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    let newCodec = chosenCodec;
    let mediaRecorder = chosenCodec ? 
      new MediaRecorder(mediaStream, {
        mimeType: chosenCodec
      }) :
      (() => {
        let recorder: MediaRecorder | null;
        for (let codec of audioCodecs) {
          try {
            recorder = new MediaRecorder(mediaStream, {
              mimeType: codec
            });
            setChosenCodec(codec);
            newCodec = codec;
            break;
          } catch (e) {
          // pass; we'll try the next one.
          }
        }
        if (!recorder!) throw new Error("Device not supported");
        return recorder;
      })();

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        const audioBlob = new Blob([event.data], { type: newCodec });
        props.saveRecordingCallback(URL.createObjectURL(audioBlob), audioBlob);
      }
    };

    mediaRecorder.start();
    setAudioRecorder(mediaRecorder);
    setRecording(true);
  }, [chosenCodec]);

  // end the recorded voice message and save the recording
  const endRecording = useCallback(() => {
    // this triggers the ondataavailable event to save the rest of the 
    // audio, from when the recorder started recording.
    audioRecorder?.stop();
    setRecording(false);

    audioRecorder?.stream.getTracks().forEach(track => track.stop());

    if (props.isRecordingCallback) props.isRecordingCallback(false);
  }, [audioRecorder]);

  return (<div className='flex position-relative'>
    <button 
      onClick={recording ? endRecording : beginRecording} type="button"
      className={`inline-flex items-center px-2 py-2 border
            text-sm font-medium focus:ring-2 ${props.disabled ? 'bg-gray-300' : (recording ? 'bg-red-600 hover:bg-red-700' : 'bg-indigo-600 hover:bg-indigo-70 ')}`}>
      <MicrophoneIcon className={`h-5 w-5 text-white ${recording ? 'animate-pulse' : ''}`} />
    </button>
    {recording && <div className="position-absolute z-10 border rounded bg-white p-2 top-full mt-1 right-0 w-max flex flex-col items-center shadow-md">
      <div className="text-red-600 font-bold">{L.applicant.comms.recording}</div>
      <div className="text-sm">{L.applicant.comms.time_remaining + ' ' + formatTime(AUDIO_LIMIT - counter)} </div>
    </div>}
  </div>);
}

function SendMessageButton({
  messageTypes,
  disabled,
  localTimeZoneOffset,
  refTimeZoneOffset,
  dateOpts,
  usesTwilioSMS,
  onClick,
  saveRecordingCallback
}: {
  messageTypes: ('email' | 'sms' | 'whatsapp')[],
  disabled: boolean,
  localTimeZoneOffset?: TimezoneOffset,
  refTimeZoneOffset?: TimezoneOffset,
  dateOpts: PresetDateOpts,
  usesTwilioSMS: boolean,
  onClick: (messageType: 'email' | 'sms' | 'whatsapp', sendAt?: number) => Promise<void> | void,
  saveRecordingCallback: (url: string | undefined, audioBlob: Blob | undefined) => void,
  requireConsentToRecordCalls?: { kind: string, customConsentScript?: Text },
  recordingConsent?: boolean | null
}) {
  if (messageTypes.length === 0) return <></>;

  const L = useLocalizedStrings();
  const timeZoneId = useProgramTimeZoneId() || useLocalTimeZoneId();
  const [sendAt, setSendAt] = useState<number | undefined>();
  const [messageType, setMessageType] = useState<'email' | 'sms' | 'whatsapp'>(messageTypes.length ? messageTypes[0] : 'sms');
  const labelOptions = {
    'email': L.applicant.comms.send_email,
    'sms': L.applicant.comms.send_text,
    'whatsapp': L.applicant.comms.send_whatsapp
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!messageTypes.includes(messageType)) setMessageType(messageTypes.length ? messageTypes[0] : 'sms');
  }, [messageType, messageTypes]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  const [recording, setRecording] = useState(false);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    if (messageType === 'email' || (messageType === 'sms' && !usesTwilioSMS)) {
      saveRecordingCallback(undefined, undefined);
    }
  }, [messageType]);

  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    timeZone: refTimeZoneOffset?.utc[0],
    timeStyle: 'medium',
    dateStyle: 'medium'
  });
  const localDateFormatter = new Intl.DateTimeFormat(undefined, {
    timeZone: localTimeZoneOffset?.utc[0],
    timeStyle: 'medium',
    dateStyle: 'medium'
  });

  return <div className="ml-auto">
    <ul className="flex justify-end mb-0">
      <li className="position-relative grow">
        <ClickableButton
          disabled={disabled}
          extraClasses={classNames(
            sendAt ? 'rounded-b-none' : '',
            'rounded-r-none border-none text-white w-full',
            (messageTypes.length < 2) ? 'pointer-events-none' : ''
          )}
          onClick={() => {setDropdownOpen(!dropdownOpen)}}
          color="indigo" colorIntensity={600}>
          <>{labelOptions[messageType]}{messageTypes.length > 1 && <ChevronDownIcon className='ml-2 -mr-4 text-white h-5'/>}</>
        </ClickableButton>
        {messageTypes.length > 1 && dropdownOpen && <div id="dropdown" ref={dropdownRef} className="z-10 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 position-absolute">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            {messageTypes.map((s: 'sms' | 'email' | 'whatsapp') => {
              return <li key={s}>
                <button onClick={() => {
                  setMessageType(s);
                  setDropdownOpen(false);
                }} className="block px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white w-full">{labelOptions[s]}</button>
              </li>
            })
            }
          </ul>
        </div>}
      </li>
      {messageType !== 'email' && <><li>
        <div hidden={!dateOpts || dateOpts.length === 0} className='h-full'>
          <PresetDateTimePicker
            extraClasses={classNames(disabled ? '' : 'hover:bg-blue-400 hover:text-gray-500')}
            disabled={disabled}
            onSelected={setSendAt}
            timezones={{
              local: localTimeZoneOffset ?? timezones[0],
              reference: refTimeZoneOffset
            }}
            options={dateOpts}
          />
        </div>
      </li>
      <li>
        <FlyoutMenu
          popperProps={{ disabled }}
          className={classNames(
            sendAt ? 'rounded-b-none' : '',
            disabled ? 'bg-gray-300' : 'bg-indigo-600 hover:bg-blue-400 hover:text-gray-500',
            'inline-flex items-center border border-transparent p-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-white h-full'
          )}
          label={<CalendarIcon className="h-5 w-5" />}
          items={
            <div className="p-0 h-full">
              <CustomDateTimePicker
                initialValue={sendAt}
                after={twilioSendAtBounds.after}
                before={twilioSendAtBounds.before}
                onChange={setSendAt}
                timeZoneId={timeZoneId as TimeZoneId}
                referenceTimeZoneId={useLocalTimeZoneId() as TimeZoneId}
              />
            </div>
          }
        />
      </li></>}
      {(messageType === 'whatsapp' || (messageType === 'sms' && usesTwilioSMS)) && 
        <RecordAudioButton disabled={disabled} saveRecordingCallback={saveRecordingCallback} isRecordingCallback={(bool: boolean) => setRecording(bool) } />
      }
      <li>
        <button disabled={disabled || recording} onClick={onClick.bind(null, messageType, sendAt)} type="button"
          className={classNames('inline-flex items-center px-2 py-2 border h-full', (disabled || recording) ? 'bg-gray-300 hover:bg-gray-300' : 'bg-indigo-600 hover:bg-indigo-800',
            'text-sm font-medium rounded-md rounded-l-none text-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500')}>
          <PaperAirplaneIcon className="text-white h-5"/>
        </button>
      </li>
    </ul>
    <div className="text-center rounded-b-md bg-indigo-100 p-2 w-auto text-black" hidden={!sendAt}>
      <XMarkIcon className='h-5 w-5 hover:cursor-pointer float-right' onClick={() => setSendAt(undefined)} />
      <span hidden={!sendAt} className="flex justify-center">{L.support.scheduled_reply_for}</span>
      <div hidden={!sendAt}>
        <time className="font-semibold">{dateFormatter.format(sendAt)}</time>
        <span hidden={!refTimeZoneOffset}>&nbsp;({!refTimeZoneOffset ? '' : refTimeZoneOffset.abbr})</span>
      </div>
      <div hidden={!sendAt}>
        <time className="font-semibold">{localDateFormatter.format(sendAt)}</time>
        <span hidden={!localTimeZoneOffset}>&nbsp;({!localTimeZoneOffset ? '' : localTimeZoneOffset.abbr})</span>
      </div>
    </div>
  </div>
}

export function useAuthorizedCommsChannels(props: {
  config: Record<string, string | undefined>
}) {
  const getCommsChannels = usePost('/messaging/channels');

  const [commsChannels, setCommsChannels] = useState([] as string[]);

  useEffect(() => {
    getCommsChannels({}).then((channels) => {
      setCommsChannels(channels);
    }).catch((e) => {
      console.error("Error fetching comms channels: ", e);
      Sentry.captureException(e);
    });
  }, []);

  return commsChannels;
}

export { ApplicantComms }