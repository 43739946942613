import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";
import { useParams } from "react-router-dom";
import { apiPath, get_deployment, get_rs_host, useAPI, useAPIPost, usePost, useToken } from "./API";
import { useInterval, useMarkdown, languageContent, safeParse, safeParseUrl, useAsyncInterval } from "./Util";
import { useToast } from "@aidkitorg/component-library";
import moment from "moment";
import InterfaceContext, { ConfigurationContext, PublicConfigurationContext, SupportedLanguage, SurveyContext } from "./Context";
import { decompressSync, strFromU8 } from "fflate";

import Notes from "./Applicant/Notes";
import History from "./Applicant/History";
import { useLocalizedStrings } from './Localization';
import { XMarkIcon } from "@heroicons/react/24/outline";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { QuestionProps, Result, ValidatorDict } from "./Questions/Props";

import { CorrectionRequest, CorrectionResolver } from "./Questions/Correction";
import { AttachmentQuestion, createImageWindow } from "./Questions/Attachment";
import { ContractQuestion, ContractSignerQuestion } from "./Questions/Contract";
import { Flag, FlagReview } from "./Questions/Flags";
// import IncomeCalculation from "./Questions/Income";
import {
  SingleLineTextEntryQuestion,
  NumberQuestion,
  PhoneNumberQuestion,
  EmailQuestion,
  EncryptedValueVerification,
  MultilineQuestion,
  BankRoutingNumberQuestion,
} from "./Questions/TextEntry";
import { DateQuestion } from "./Questions/Date";
import {
  SingleSelectQuestion,
  MultipleSelectQuestion,
  ConfirmQuestion,
  SearchSelectQuestion,
  SearchSelectLargeDictionaryQuestion,
} from "./Questions/Choice";
import { NumberWithUnitQuestion } from "./Questions/NumberWithUnit";
import { LikertQuestion } from "./Questions/Likert";
import {
  RankingQuestion,
} from "./Questions/Ranking";
import {
  PropertyLookup,
} from "./Questions/PropertyLookup";
import { ApplicantComms, LegacyComms, useAuthorizedCommsChannels } from "./Applicant/Comms";
import ReassignDropdown from "./Applicant/Reassign";

import { IdentityDocument, IdentityFlagReview } from "./Questions/IdentityVerificationPersona";

import { DeviceSetup } from "./Questions/DeviceSetup";
import { CardManagement } from "./Questions/CardManagement";
import { Explanation } from "./Questions/Explanation";
import { SubsurveyQuestion } from "./Questions/Subsurvey";
import { AddressQuestion } from "./Questions/Address";
import { questionIsComplete } from "./QuestionUtil";
import RCPayments from "./RCPayments";
import { Card } from "react-bootstrap";
import { PauseIcon, PlayIcon, CheckCircleIcon, CurrencyDollarIcon, InformationCircleIcon, QuestionMarkCircleIcon, SpeakerWaveIcon } from "@heroicons/react/24/solid";
import { DataRequestBox } from "./Applicant/DataRequest";
import { Help } from "./Applicant/Help";
import { InlineSignature } from "./Questions/InlineSignature";
import { useSurveyDescription, useRoboScreener } from "./Components/Roboscreener";
import IncomeCalculation from "./Questions/Income";
import HouseholdCalculation from "./Questions/Household";
import { AdminTab } from "./Applicant/AdminTab";
import { useEffectDebugger } from "./Debug";
import { computeKeys } from "@aidkitorg/roboscreener/lib/compute/core";
import { evalConditional } from "@aidkitorg/roboscreener/lib/compute/util";
import { questionConditionalMet } from "@aidkitorg/roboscreener/lib/compute/stage";

import { QuotaCheck } from "./Components/QuotaCheck";
import { LoginWidget } from "./Questions/LoginWidget";
import { ResumeWidget } from "./Questions/ResumeWidget";
import { Assignee, CompleteAssignment } from "./Questions/Assignee";
import { ValidatorMessage } from "./Components/ValidatorMessage";
import { Badge as TailwindBadge } from "./Components/Badge";
import * as Sentry from "@sentry/react";
import { ActionButton } from "./Questions/Action";
import { ShowDate, ShowField } from "./Questions/ShowField";
import { TurnableImage } from "./Components/TurnableImage";
import { BenefitsCalculator } from "./Questions/BenefitsCalculator";
import { PlaidBankAccount, PlaidIncome } from "./Questions/Plaid";
import { DuplicateReview } from "./Components/DuplicateReview";
import { InlineNotification } from "./Questions/InlineNotification";
import { CheckQuestionComplete } from "./Apply";
import { FraudFlags } from "./Applicant/FraudFlags";
import { ApplicantCreator } from "./Questions/ApplicantCreator";
import { Validated } from "./Questions/Validated";
import { ContactConfirmation } from "./Questions/ContactConfirmation";
import { Consent } from "./Components/Consent";
import { LivenessDetectionQuestion } from "./Questions/LivenessDetection";
import { SimilarDocumentReview } from "./Components/SimilarDocumentReview";
import { LANG_MAP } from "@aidkitorg/i18n/lib";
import { SupportButton } from "./Components/SupportButton";
import RelatedApplicantsQuestion from "./Questions/RelatedApplicants";
import { InlineLanguageSelector } from "./Questions/InlineLanguageSelector";
import { FillableFormQuestion } from "./Questions/FillableForm";
import { SimilarDocumentCheck } from "./Components/SimilarDocumentCheck";
import { ArcGISAttachmentViewer } from "./Components/ArcGISAttachmentViewer";
import { ImagePreview } from "@aidkitorg/types/lib/survey";
import { useAsyncEffect } from "./Hooks/AsyncEffect";

const s3Languages = ['vi', 'uk', 'so', 'am', 'om', 'zh_CN', 'ps_AF', 'fa_AF'];

export function URLViewer(props: { urls: string[], signedURLs: Record<string, string>, Viewer: string }) {
  const getSignedURL = usePost('/document/view');
  return <ul>
    {props.urls.map((url: string) => {
      const lower = url.toLowerCase();
      if (
        lower.endsWith("png") ||
        lower.endsWith("jpeg") ||
        lower.endsWith("jpg") ||
        lower.split("/").slice(-1)[0].indexOf(".") === -1
      ) {
        return (
          <li key={url}>
            <a href={url}
              onClick={async (e) => {
                e.preventDefault();
                const newWindow = createImageWindow(e)
                const paths = await getSignedURL({
                  paths: [url],
                });
                newWindow.callback(paths.paths[url]);
              }}
              target="_blank" rel="noopener noreferrer">
              {props.signedURLs[url] &&
                <TurnableImage
                  Viewer={props.Viewer}
                  alt="document"
                  src={props.signedURLs[url]}
                />}
            </a>
          </li>
        );
      }
      return (
        <li key={url}>
          <a href={url}
            onClick={async (e) => {
              e.preventDefault();
              const paths = await getSignedURL({
                paths: [url],
              });
              window.open(paths.paths[url], "_blank");
            }}
            rel="noopener noreferrer" target="_blank">
            {url.split("/").slice(-1)[0]}
          </a>
        </li>
      );
    })}
  </ul>;
}

function Computed(props: QuestionProps) {
  const metadata = safeParse(props["Metadata"]!);
  const cardVariant = metadata.card_variant; // { "card_variant": { "any": "light", "true": "success", "false": "danger" }}
  const formula = metadata.formula;
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);

  const alwaysStyle = config['always_style_computations'];
  const content = useMarkdown(props[languageContent(context.lang)]);

  const [result, setResult] = useState(props.info[props["Target Field"]!]);

  useEffect(() => {
    if (Object.keys(props.info).length === 0) return;
    if (Object.keys(config).length === 0) return;

    if (props.info[props["Target Field"]!] !== result) {
      setResult(props.info[props["Target Field"]!]);
    }
  }, [result, props.info, props, config]);

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <></>
  }

  if (cardVariant || (alwaysStyle && alwaysStyle === 'true')) {
    const bg = cardVariant ? (cardVariant[result] ?? (cardVariant['any'] ?? 'light')) : 'light';
    return (
      <Card>
        <Card.Header as="h6">
          {props[languageContent(context.lang)]}
        </Card.Header>
        <Card.Body as="div" className={`alert alert-${bg} mb-0`}>
          <Card.Text>
            <span>{(metadata.format && metadata.format === 'currency' && (
              <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <span>{parseFloat(result).toFixed(2)}</span>
              </p>
            )) || result}</span>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div>
      {content}
      {result}
    </div>
  );
}

function Lookup(props: QuestionProps) {
  const metadata = safeParse(props["Metadata"] || '{}');
  const cardVariant = metadata.card_variant; // { "card_variant": { "any": "light", "true": "success", "false": "danger" }}
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);

  const lookupHashId = usePost('/confirm/lookup');
  const fetching = useRef(false);
  const [searched, setSearched] = useState({} as Record<string, string>);

  useEffect(() => {
    if (fetching.current) return;

    if (metadata?.kind === 'dynamo_hash' && props.Viewer === 'applicant') {
      const infoKeys = (metadata.query as string).split(',').map((s) => {
        return s.trim().split(':')[1];
      });

      let doLookup = false;
      for (const key of infoKeys) {
        if (!props.info[key]) return;
        if (props.info[key] !== searched[key]) {
          doLookup = true;
        }
      }

      if (!doLookup) return;

      // perform the lookup 
      (async () => {
        if (fetching.current) return;
        fetching.current = true;
        const response = await lookupHashId({
          targetField: props["Target Field"]!,
          infoKeys: props.info,
          contactToken: (props.info[metadata.contact_confirmation || ''] || '').split(':')[1]
        });

        if (response && response.result !== undefined) {
          props.setInfoKey(props["Target Field"]!, response.result, true, false);
        }
        setSearched(infoKeys.reduce((nextState, key) => {
          nextState[key] = props.info[key];
          return nextState;
        }, {} as Record<string, string>));

        fetching.current = false;
      })();
    }
  }, [props.info]);

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <span></span>
  }

  const bg = cardVariant ? (cardVariant[props.info[props["Target Field"]!]] ?? (cardVariant['any'] ?? 'light')) : 'light';
  return (
    <Card>
      <Card.Header as="h6">{props[languageContent(context.lang)]}</Card.Header>
      <Card.Body as="div" className={`alert alert-${bg} mb-0`}>
        <Card.Text>
          {props.info[props["Target Field"]!]}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

function SubmitButton(props: QuestionProps) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const submitted = useRef(false);
  const [submitting, setSubmitting] = useState(false);
  const sendMessage = useAPIPost("/send_message");
  const hideRequestChangesButton = safeParse(props["Metadata"] || '{}').hide_request_changes_button;

  if (props.Viewer === 'screener') {
    if (props.info[props["Target Field"]!]) {
      return <>{L.submission.submitted_at} {props.info[props['Target Field']!]}
        {!hideRequestChangesButton && (
          <button
            onClick={(e) => {
              const message = prompt('Enter a message to send to the recipient');
              if (message) {
                props.setInfoKey(props["Target Field"]!, '', true, false);
                sendMessage({
                  phone: props.info.phone_number,
                  message
                })
              }
            }}
            className="ml-2 inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {L.submission.request_changes}
          </button>
        )}
      </>
    }

    return <button
      type="button"
      onClick={(e) => {
        props.setInfoKey(props["Target Field"]!, (new Date()).toISOString(), true, false);
      }}
      className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {safeParse(props["Metadata"] || '{}').custom_text?.[context.lang] || L.submission.mark_submitted}
    </button>
  }

  const submitText = safeParse(props["Metadata"] || '{}').custom_text?.[context.lang] || L.submission.submit;

  return <button
    type="button"
    onClick={async (e) => {
      // If we have a props.Submit that function will set this info.
      // If we try to set it here we run into the previous_info_does_not_match error.
      if (props.Submit && window.location.pathname.includes('/p/')) {
        // Don't set the info, saveInfoRS will do that
      } else {
        props.setInfoKey(props["Target Field"]!, (new Date()).toISOString(), true, false);
      }

      if (!submitted.current) {
        submitted.current = true;
        setSubmitting(true);
        if (props.Submit) {
          try {
            const resp = (await props.Submit(props["Target Field"]!)) as any;
            if (resp && (resp as any).error) {
              setSubmitting(false)
              submitted.current = false;
            }
          } catch (e) {
            setSubmitting(false);
            submitted.current = false;
          }
        }
        setSubmitting(false);
      }
    }}
    className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  >
    {submitting ? L.submission.submitting : submitText}
  </button>
}

function Invisible(props: QuestionProps) {
  return <></>;
}

function ResourcePageSender(props: QuestionProps) {
  const L = useLocalizedStrings();
  const { toast } = useToast();
  const sendResourcePage = useAPIPost("/applicant/" + props.uid + "/send_resource_page");
  async function resend_resource_page() {
    const result = await sendResourcePage({});
    if (result.error) {
      alert(result.message);
    } else {
      toast({
        description: "Message sent!",
        variant: 'success'
      })
    }
  }
  return <Button onClick={resend_resource_page}>{L.device.send_resource_page}</Button>;
}

function RobocontrollerIssuedPayment(props: QuestionProps) {
  const L = useLocalizedStrings();

  let status = useAPI("/check_usio/" + props.info[props['Target Field']!]);
  if (status.value && status.value.load_confirmation) {
    return <div>{L.questions.payment.payment_issued} (${(status.value.amount / 100).toFixed(2)})</div>
  }
  if (status.value && status.value.load_confirmation) {
    return <div>{L.questions.payment.payment_pending} (${(status.value.amount / 100).toFixed(2)})</div>
  }
  return <div></div>;
}

function RobocontrollerPayment(props: QuestionProps) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);

  const payment = safeParse(props['Metadata']!);

  let status = <></>;
  if (!props.info[props['Target Field']!]) {
    if (!props.stages) {
      return <></>;
    }
    if (props.stages!.indexOf(props.currentStage!) < props.stages!.indexOf(payment.stage)) {
      return <></>;
    }

    if (props.info[payment.depends_on]) {
      if (payment.depends_on_value) {
        if (props.info[payment.depends_on] === payment.depends_on_value) {
          status = <div>Payment Queued: {props[languageContent(context.lang)]?.trim()}</div>;
        } else {
          return <></>;
        }
      } else {
        status = <div>Payment Queued: {props[languageContent(context.lang)]?.trim()}</div>;
      }
    } else {
      return <></>;
    }
  } else {
    status = <div>Payment Issued: {props[languageContent(context.lang)]?.trim()}</div>
  }

  return <div className={"survey-question relative pb-8 text-clip"}
    data-name={props['Question']}>
    {props.lastQuestion !== true && <span className="absolute top-10 left-8 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />}
    <div className="relative flex space-x-4">
      <div className={'pt-1.5'}>
        <span className={'h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-white bg-white '}>
          <CheckCircleIcon className={"h-7 w-7 text-green-200"} />
        </span>
      </div>
      <div className={'min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'}>
        <div>
          {status}
        </div>
      </div>
    </div>
  </div>
}

function Notification(props: QuestionProps) {
  const metadata = safeParse(props['Metadata'] || '{}', {});
  if (metadata.inline) {
    return InlineNotification(props);
  }
  return <></>;
  function replacer(match: string) {
    const variable = match.slice(1).replace(/\\_/g, '_');
    if (variable === "uid") return props.uid;
    if (props.info[variable] !== undefined && props.info[variable] !== null) return props.info[variable];
    return match;
  }


  function clear() {
    props.setInfoKey(props['Target Field']!, '', true, false);
  }


}

function DistroComponentAdapter<T>(c: (props: {
  component: T,
  setInfo: (key: string, value: string) => void,
  info: Record<string, string | undefined>,
  uid: string,
  refreshInfo?: () => Promise<void>,
  questionProps: QuestionProps
}) => JSX.Element) {
  return (props: QuestionProps) => {
    const component = useMemo(() => {
      return safeParse(props.Metadata || '{}', {})
    }, [props.Metadata]);

    return React.createElement(c, {
      component,
      setInfo: (key: string, value: string) => props.setInfoKey(key, value, true, false),
      refreshInfo: props.refreshInfo,
      info: props.info || {},
      uid: props.uid || '',
      questionProps: props,
    });
  }
}

export const QuestionTypes: any = {
  "Action": ActionButton,
  ApplicantCreator: ApplicantCreator,
  "ArcGIS Attachment Viewer": DistroComponentAdapter(ArcGISAttachmentViewer),
  "Single Select": SingleSelectQuestion,
  "Single Line Text Entry": SingleLineTextEntryQuestion,
  Address: AddressQuestion,
  Number: NumberQuestion,
  "Bank Routing Number": BankRoutingNumberQuestion,
  "Phone Number": PhoneNumberQuestion,
  "Email": EmailQuestion,
  "Multiple Select": MultipleSelectQuestion,
  "Search Select": SearchSelectQuestion,
  "Search Select Large Dictionary": SearchSelectLargeDictionaryQuestion,
  Attachment: AttachmentQuestion,
  Contract: ContractQuestion,
  Likert: LikertQuestion,
  "Number With Unit": NumberWithUnitQuestion,
  "Contract Signer": ContractSignerQuestion,
  "Contract Stale": Invisible,
  "Encrypted Value Verification": EncryptedValueVerification,
  Explanation: Explanation,
  Subsurvey: SubsurveyQuestion,
  "Correction Resolver": CorrectionResolver,
  "Correction Request": CorrectionRequest,
  "Plaid Bank Account": PlaidBankAccount,
  "Plaid Income": PlaidIncome,
  Date: DateQuestion,
  "Duplicate Review": DistroComponentAdapter(DuplicateReview),
  "Similar Document Check": DistroComponentAdapter(SimilarDocumentCheck),
  "Similar Document Review": DistroComponentAdapter(SimilarDocumentReview),
  "Consent": DistroComponentAdapter(Consent),
  "Show Field": ShowField,
  "Show Date": ShowDate,
  "Persona Document": IdentityDocument,
  "Multiline Text Entry": MultilineQuestion,
  Computed: Computed,
  Lookup: Lookup,
  Confirmation: ConfirmQuestion,
  Validated: Validated,
  Flag: Flag,
  "Flag Review": FlagReview,
  "Ranking": RankingQuestion,
  "Property Lookup": PropertyLookup,
  "Inline Signature": InlineSignature,
  "Identity Flag Review": IdentityFlagReview,
  "Device Setup": DeviceSetup,
  "Card Management": CardManagement,
  "Resource Page Sender": ResourcePageSender,
  "Payment": RobocontrollerPayment,
  "Notification": Notification,
  "Liveness Detection": DistroComponentAdapter(LivenessDetectionQuestion),
  "Submit Button": SubmitButton,
  "Contact Confirmation": ContactConfirmation,
  "Income Calculation": IncomeCalculation,
  "Household Calculation": HouseholdCalculation,
  "Quota Check": QuotaCheck,
  "Resume Widget": ResumeWidget,
  "Login Widget": LoginWidget,
  Assignee: Assignee,
  "Complete Assignment": CompleteAssignment,
  'Benefits Calculator': BenefitsCalculator,
  'Support Button': SupportButton,
  'Related Applicants': RelatedApplicantsQuestion,
  'Inline Language Selector': InlineLanguageSelector,
  'Fillable Form': DistroComponentAdapter(FillableFormQuestion),
  'Third Party Check': function() { return null; },
  'Image Preview': ImagePreviewQuestion
};

function ImagePreviewQuestion(props: QuestionProps) {
  const { component } = safeParse(props.Metadata!) as { component: ImagePreview };
  const getSignedURL = usePost('/document/view');
  const [src, setSrc] = useState<string>();

  const syncImage = useCallback(async () => {
    let url = safeParseUrl(component.imageSrc);
    if (url?.protocol === 's3:') {
      let urlStr = url.toString();
      const { paths } = await getSignedURL({ paths: [urlStr] });
      setSrc(paths[urlStr]);
    } else {
      setSrc(component.imageSrc);
    }
  }, [component]);

  // initial load
  useAsyncEffect(syncImage, []);

  // subsequent loads
  useAsyncInterval(syncImage, 60_000);

  return <img src={src} className="aspect-auto" />
}

export function TerminalQuestionNode(props: { icon?: any, children?: React.ReactNode }) {
  return <div className={"survey-question relative pb-8 text-clip mb-40"}>
    <div className="relative flex space-x-4">
      <div className={'pt-1.5'}>
        {props.icon ?
          <span className={'h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-white bg-white '}>
            {React.createElement(props.icon, { className: 'h-7 w-7 text-gray-200' })}
          </span>
          : <span className={'h-6 w-6 text-white bg-gray-200 flex rounded-full items-center justify-center ring-4 ring-white'}>
            &#9679;
          </span>
        }
      </div>
      <div className={'min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'}>{props.children}</div>
    </div>
  </div>;
}

export function QuestionNode(props: { icon?: any, children?: React.ReactNode }) {
  return <div className={"survey-question relative pb-8 text-clip"}>
    <span className="absolute top-10 left-8 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
    <div className="relative flex space-x-4">
      <div className={'pt-1.5'}>
        <span className={'h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-white bg-white '}>
          {props.icon && React.createElement(props.icon, { className: 'h-7 w-7 text-gray-200' })}
        </span>
      </div>
      <div className={'min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'}>
        <div>
          {props.children}
        </div>
      </div>
    </div>
  </div>
}

export function AudioStatusIcon(props: { questionId: string, state: 'info' | 'optional' | 'complete' | 'required' }) {
  const [playing, setPlaying] = useState(false);
  const config = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);
  const speak = usePost('/voice/speak', { asBlob: true });
  const player = useRef<HTMLAudioElement>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [currentLang, setCurrentLang] = useState(context.lang);
  const [initialLoad, setInitialLoad] = useState(true);

  const fetchAudioUrl = async () => {
    const response = await speak({ questionId: props.questionId, lang: context.lang }) as any;

    if (response) {
      try {
        const data = JSON.parse(await response.text());
        if (data?.url && data.url.trim() !== "") {
          let url = data.url;
          setAudioUrl(url);
          return url;
        }
      } catch (error) {
        let url = URL.createObjectURL(response);
        setAudioUrl(url);
        return url;
      }
    }
  }

  const isValidUrl = (url: string | null | undefined): boolean => {
    if (url === null || url === undefined) return false;
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  const initializeAudioUrl = async () => {
    setAudioUrl(null);
    await fetchAudioUrl();
  };

  const click = async () => {
    // locally, we either display toast notifications
    // of text fed to Amazon Polly, or use custom audio
    // files for languages that are not covered

    if (initialLoad || currentLang !== context.lang) {
      initializeAudioUrl();
      setCurrentLang(context.lang);
    }

    if (initialLoad) {
      setInitialLoad(false);
    }

    let url = audioUrl;

    if (!url) {
      let getUrl = await fetchAudioUrl();

      if (isValidUrl(getUrl)) {
        url = getUrl!;
      }
    }

    if (url && player.current) {
      if (playing) {
        player.current.pause();
        setPlaying(false);
      } else {
        if (context.audioPlayer) {
          context.audioPlayer.pause();
          // Ensures all other audio players on the page are paused
          window.dispatchEvent(new CustomEvent('audioPlayerPaused'));
        }
        context.setAudioPlayer(player.current);
        player.current.src = url;
        player.current.play();
        setPlaying(true);
      }
      return;
    }
  };

  // When audio language context changes, stop currently playing audio and change audio icon to "Play"
  useEffect(() => {
    if (player.current) {
      player.current.pause();
    }
    setPlaying(false);
    setAudioUrl(null);
  }, [context.lang]);

  // Listen for other players being paused
  useEffect(() => {
    const handleAudioPaused = () => {
      setPlaying(false);
    };
    window.addEventListener('audioPlayerPaused', handleAudioPaused);
    return () => window.removeEventListener('audioPlayerPaused', handleAudioPaused);
  }, []);

  const shouldDisplayAudioButton = () => {
    return audioUrl || config.voiceover_enabled &&
      (LANG_MAP[context.lang as keyof typeof LANG_MAP]?.voice);
  }

  if (!shouldDisplayAudioButton) {
    switch (props.state) {
      case 'info':
        return <InformationCircleIcon className={"h-7 w-7 text-gray-500"} />;
      case 'complete':
        return <CheckCircleIcon className={"h-7 w-7 text-green-500"} />;
      case 'required':
        return <QuestionMarkCircleIcon className={"h-7 w-7 text-red-500"} />;
      case 'optional':
        return <QuestionMarkCircleIcon className={"h-7 w-7 text-gray-500"} />;
    }
  }

  let color;
  let hoverColor;
  let bgHoverColor;
  let bgColor;

  switch (props.state) {
    case 'optional':
    case 'info': {
      color = 'text-gray-500';
      bgColor = 'bg-gray-500';
      hoverColor = 'hover:text-gray-700';
      bgHoverColor = 'hover:bg-gray-700';
      break;
    }
    case 'complete': {
      color = 'text-green-500';
      bgColor = 'bg-green-500';
      hoverColor = 'hover:text-green-700';
      bgHoverColor = 'hover:bg-green-700';
      break;
    }
    case 'required': {
      color = 'text-red-500';
      bgColor = 'bg-red-500';
      hoverColor = 'hover:text-red-700';
      bgHoverColor = 'hover:bg-red-700';
      break;
    }
  }

  if (context.lang === 'om') {
    return <></>;
  }

  return <>
    <audio preload="none" ref={player} onEnded={() => setPlaying(false)} />
    {playing ? (
      <PauseIcon className={`cursor-pointer h-16 w-16 ${color} ${hoverColor}`} onClick={click} />
    ) : (
      <div onClick={click} className={`cursor-pointer p-1.5 rounded-full flex justify-center items-center ${bgColor} ${bgHoverColor}`}>
        <SpeakerWaveIcon className={`h-5 w-5 text-white`} />
      </div>
    )}
  </>
}

export function SurveyQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const config = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);

  const [questionComplete, setQuestionComplete] = useState<boolean>();
  useEffect(() => {
    const hasSubQuestions = props["Field Type"] === "Likert" || props["Field Type"] === "Number With Unit";
    (async () => {
      const complete = await questionIsComplete(props,
        props.info,
        props.infoValid || {},
        { treatOptionalAsComplete: false, hasSubQuestions },
        props.screenerMetadata || {},
        props.orgMetadata || {});

      setQuestionComplete(!!complete);
    })();
  }, [props]);

  const block = props.blockQuestionEdits
    ? <fieldset disabled={props.blockQuestionEdits}>{React.createElement(QuestionTypes[props["Field Type"]], props)}</fieldset>
    : React.createElement(QuestionTypes[props["Field Type"]], props);

  const stickyElementRef = useRef<HTMLDivElement | null>(null);
  const stickySensorRef = useRef<HTMLDivElement | null>(null);

  const { keepVisibleForReview } = safeParse(props.Metadata || '{}');
  const [isActivelySticking, setIsActivelySticking] = useState(false);
  const [wasUnstuckByUser, setWasUnstuckByUser] = useState(false);
  const shouldObserveSticky = useMemo(() => keepVisibleForReview && !wasUnstuckByUser, [keepVisibleForReview, wasUnstuckByUser]);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (!shouldObserveSticky) return;

    // Detect when the element is actively sticking
    let observer: IntersectionObserver | null = null;
    const currentElement = stickySensorRef.current;
    if (currentElement) {
      observer = new IntersectionObserver(
        ([entry]) => {
          setIsActivelySticking(entry.intersectionRatio < 1);
        },
        // trigger the actively sticking state when the ref object is less than fully visible
        { threshold: [1], rootMargin: `${headerHeight}px 0px 0px 0px` }
      );
      observer.observe(currentElement!);
    }

    // Calculate the header height if in the screener view
    const getHeaderHeight = () => {
      const headerElement = document.getElementById('screener-view-header');
      if (headerElement) {
        setHeaderHeight(headerElement.offsetHeight);
      }
    };
    getHeaderHeight();
    window.addEventListener('resize', getHeaderHeight);

    return () => {
      if (observer && currentElement) {
        observer.unobserve(currentElement!);
      }

      window.removeEventListener('resize', getHeaderHeight);
    };
  }, [shouldObserveSticky]);

  const handleUnstick = () => {
    // Stop observing the sticky element and prevent it from sticking again
    setWasUnstuckByUser(true);
    setIsActivelySticking(false);
    setHeaderHeight(0);
    // Reset the height in case the element was resized
    if (stickyElementRef.current) {
      stickyElementRef.current.style.height = 'auto';
    }
    // Clear sticky formatting in the parent if in the applicant view
    if (props.clearApplicantSticky) {
      props.clearApplicantSticky(props.id);
    }
  };

  const questionClass = useMemo(() => {
    let className = 'survey-question pb-8';
    if (shouldObserveSticky) {
      className += ` sticky bg-white z-[9] border border-gray-200 pl-4 shadow-sm max-h-[75vh] resize-y`;
      className += isActivelySticking ? ' overflow-auto' : ' overflow-hidden';
    } else {
      className += ' relative';
    }
    return className;
  }, [shouldObserveSticky, isActivelySticking]);

  // If it's custom, there's no default rendering of anything
  if (!QuestionTypes[props["Field Type"]])
    return (
      <div>
        <b>{L.applicant.unknown_question_type} {props["Field Type"]}</b>
      </div>
    );

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <>
      {block}
    </>
  }

  const optional = (props["Additional Options"] || []).indexOf("Optional") !== -1;
  const COMPUTED = ['Flag', 'Identity Flag Review', 'Computed', 'Validated',
    'Contract Stale', 'Persona Document', 'Assignee'];
  const INFORMATIONAL = ['Flag', 'Assignee', 'Flag Review', 'Show Field', 'Show Date'];

  // Compute the block now to determine if we need to format it

  if (['Payment', 'Flag', 'Validated'].indexOf(props['Field Type']) !== -1) {
    return <>
      {block}
    </>
  }

  const voiceover_enabled = config.voiceover_enabled;
  const hasSubQuestions = props["Field Type"] === "Likert" || props["Field Type"] === "Number With Unit";

  if (config.enable_question_highlight === 'true' || true) {
    const complete = questionIsComplete(props,
      props.info,
      props.infoValid || {},
      { treatOptionalAsComplete: false, hasSubQuestions },
      props.screenerMetadata || {},
      props.orgMetadata || {});
    return (
      <>
        {shouldObserveSticky && <div ref={stickySensorRef} className="sticky-sensor-element relative" style={{ height: '1px', top: `-${headerHeight + 1}px` }}></div>}
        <div
          ref={stickyElementRef}
          className={questionClass}
          style={shouldObserveSticky ? { top: `${headerHeight}px` } : {}}
          data-name={props['Question']}
        >
          {/* Hide the gray line on the left for sticky elements */}
          {props.lastQuestion !== true && !shouldObserveSticky && <span className={`absolute top-10 ${context.textAlign}-8 -ml-px h-full w-0.5 bg-gray-200`} aria-hidden="true" />}
          <div className="relative flex space-x-4">
            <div className={voiceover_enabled ? '-pt-1' : 'pt-1.5'}>
              <span className={(voiceover_enabled ? '-ml-2 h-10 w-10 mt-3 ' : 'h-6 w-6 ') + 'rounded-full flex items-center justify-center ring-4 ring-white bg-white '}>
                {(props["Target Field"] && !INFORMATIONAL.includes(props['Field Type'])) ?
                  (questionComplete ?
                    <AudioStatusIcon questionId={props.id} state='complete' />
                    : (optional ?
                      <AudioStatusIcon questionId={props.id} state='optional' />
                      :
                      <AudioStatusIcon questionId={props.id} state='required' />)
                  )
                  : <>
                    <AudioStatusIcon questionId={props.id} state='info' />
                  </>}
              </span>
            </div>
            <div className={'min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'}>
              <div className="w-full">
                {props["Target Field"] && props["Metadata"] && props["Metadata"].indexOf("quota_check") !== -1 ?
                  React.createElement(QuotaCheck, { ...props, block }) :
                  block}
              </div>
              {isActivelySticking && <button className="btn absolute -top-2 -right-3 pl-0" onClick={handleUnstick}><XMarkIcon className="w-6 h-6 text-gray-400 hover:text-gray-500" /></button>}
            </div>
          </div>
        </div>
      </>
    )
  };

}

type ReactSectionProps = {
  section: QuestionProps;
  Questions?: QuestionProps[];
  setInfoKey: (
    key: string,
    value: any,
    valid: boolean,
    disqualifies: boolean
  ) => Promise<void>;
  complete: boolean;
  expireDate?: string;
  uid: string;
  info: any;
  infoValid: ValidatorDict;
  orgMetadata: any;
  screenerMetadata?: any;
  stages: string[];
  currentStage: string;
};

type APISectionProps = QuestionProps & { Questions?: QuestionProps[] };

function SurveySection(props: ReactSectionProps) {
  // Deprecated, props.section['Field Type'] will let us know if this is a subsurvey.
  // const { subsurvey } = useParams(); 

  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();

  // Extract these to static variables so they can be passed to the useEffect
  const targetField = props.section['Target Field'];
  const complete = props['complete'];

  const setInfoKey = useCallback(props.setInfoKey, [targetField, complete]);

  const content = useMarkdown(props.section[languageContent(context.lang)]);
  const metadata = safeParse(props.section['Metadata'] || '{}');

  useEffect(() => {
    if (!targetField) return;

    setInfoKey(targetField, complete ? 'complete' : '', true, false);
  }, [setInfoKey, targetField, complete]);

  /** 
   * State management for whether a question should be shown based on any conditional
  */
  const [questionActive, setQuestionActive] = useState({} as Record<string, boolean>);
  useEffect(() => {
    (async () => {
      const metadata = safeParse(props.section['Metadata'] || '{}');
      let filteredQuestions = [] as QuestionProps[];
      const indexMap: Record<number, number> = {};
      let filteredIndex = 0;
      let questionsIndex = 0;
      if (metadata.screenerMode === 'Progressive') {
        for (let q of props.section.Questions) {
          if ((await questionConditionalMet(q, props.info, {}, {}))) {
            CheckQuestionComplete(q as any, props.info, true);
            if (filteredQuestions.length === 0 || filteredQuestions[filteredQuestions.length - 1].complete) {
              indexMap[filteredIndex] = questionsIndex;
              filteredQuestions.push(q);
              filteredIndex++;
            } else {
              break;
            }
          }
          questionsIndex++;
        }
      } else {
        filteredQuestions = props.section.Questions;
      }

      let index = 0;
      const questionActive: Record<string, boolean> = {};
      for (const q of filteredQuestions) {
        const questionIndex = indexMap[index] || index;
        const key = q["Question"] + "-" + questionIndex.toString();
        index++;

        if (q["Conditional On"] !== undefined) {
          if (q["Conditional On Value"]) {
            if (
              q["Conditional On Value"].indexOf(
                props.info[q["Conditional On"]]
              ) === -1
            ) {
              if (q["Conditional On Value"].indexOf("!") !== -1) {

              } else {
                continue;
              }
            }
          } else {
            if (!props.info[q["Conditional On"]]) {
              continue;
            }
          }
        }
        // check if metadata exists and is an object (can be string in legacy Validated)
        if (q['Metadata'] && safeParse(q['Metadata'], 'safeReturn') !== 'safeReturn') {
          const metadata = safeParse(q['Metadata'] || '{}');
          if (metadata?.conditional) {
            const shouldShow = await evalConditional(
              metadata.conditional,
              props.info,
              props.orgMetadata,
              props.screenerMetadata);
            if (!shouldShow) {
              questionActive[key] = false;
              continue;
            }
          }
        }
        questionActive[key] = true;
      }

      setQuestionActive(questionActive);
    })();
  }, [props]);

  return (
    <div>
      {props['section']['Field Type'] === 'Subsurvey' && content}
      {props['expireDate'] && (
        <Alert variant="info" className="w-full sticky top-0">
          {(L.subsurvey.this_survey_will_expire).replace('%d', moment(props['expireDate']).add(moment(props['expireDate']).utcOffset(), "minutes").calendar())}
        </Alert>
      )}
      <>
        {props.section.Questions.map((q: QuestionProps, index: number) => {
          const key = q["Question"] + "-" + index.toString();

          if (!questionActive[key]) {
            return <span key={key}></span>
          }

          return <>{React.createElement(SurveyQuestion, {
            ...q,
            Viewer: 'screener',
            setInfoKey: props.setInfoKey,
            info: props.info,
            infoValid: props.infoValid,
            orgMetadata: props.orgMetadata,
            screenerMetadata: props.screenerMetadata,
            uid: props.uid,
            key: key,
            currentStage: props.currentStage,
            stages: props.stages,
          })}</>;
        })}
      </>
    </div>
  );
}

type SurveyProps = { uid: string; subsurvey?: boolean };

function Payment(props: any) {
  const L = useLocalizedStrings();
  const configuration = useContext(ConfigurationContext);
  const notify = useAPIPost("/paymenttransaction/" + props.payment.id + "/notify_payment");

  if (props.payment.status === 'cancelled') {
    return <></>;
  }

  async function doNotify() {
    await notify({});
    alert(L.applicant.notified)
  }

  return (
    <p>
      <b>
        {L.applicant.payment_of_usd}{-props.payment.amount} {L.applicant.via}{" "}
        {
          ({
            usio: 'Prepaid Debit Card',
            check: "check",
            wu_batch: 'Western Union',
            ach_mercury: 'ACH',
            ach_dwolla_float: "ACH",
            ach_dwolla: "ACH",
            ach_legacy: "ACH",
            manual: "manual processing"
          } as any)[
            props.payment.kind
          ]
        }{" "}
        {L.applicant.on} {moment.unix(props.payment.created_at).calendar()}
      </b>
      <br />
      <b>{L.applicant.status}</b>&nbsp;{props.payment.status}&nbsp;
      {props.payment.kind === 'wu_batch' &&
        <>
          {(props.payment.mtcn ?
            (props.payment.reversed ? '' : props.payment.mtcn) :
            "MTCN Not Yet Issued")}&nbsp;
          {(props.payment.wu_status &&
            (L.banking.wu_status as any)[props.payment.wu_status as any])}
        </>}
      &nbsp;
      {props.payment.cleared && <Badge variant="success">{L.applicant.cleared}</Badge>}
      {props.payment.reversed && <Badge variant="danger">{L.applicant.failed}</Badge>}
      &nbsp;
      {props.payment.kind === 'usio' &&
        <>
          <Button variant="light" size="sm" onClick={doNotify}>{L.applicant.resend_usio}</Button>
          <p>
            {L.applicant.usio_instructions.replace("NUMBER", (configuration.twilio_mms_number || '').replace("+1", ""))}
            <br /><br />
            <img src="/usioflow.png" alt="USIO text flow" style={{ 'maxWidth': '400px' }} />
          </p>
        </>}
    </p>
  );
}

function SurveyPage(props: SurveyProps) {

  // Determines if the state is actually valid (this is used to determine what section to show)
  const initialValidState: { [key: string]: boolean } = {};
  const [applicantInfoValid, setApplicantInfoValid] = useState(
    initialValidState
  );
  const [sectionComplete, setSectionComplete] = useState([] as boolean[]);
  const [sectionStarted, setSectionStarted] = useState([] as boolean[]);
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const L = useLocalizedStrings();

  // When merging the state, there's a few different scenarios depending
  // on whether there's local or remote changes. Basically, if there's a
  // local change, we pause refreshing until everything has been committed.
  //
  // One trickiness here is that, we don't want to save redundant info, so 
  // we would like to check if what we're saving is different than what was
  // last received from the server

  // Contains the applicant key/value pairs
  const initialState: { [key: string]: any } = {};
  const [applicantInfo, setApplicantInfo] = useState(initialState);
  const [remoteApplicantInfo, setRemoteApplicantInfo] = useState(initialState);
  const [screeningOrgInfo, setScreeningOrgInfo] = useState(initialState);
  const [screenerInfo, setScreenerMetadata] = useState(initialState);

  // Updates applied to applicantInfo that haven't yet been saved
  const [pendingUpdates, setPendingUpdates] = useState(
    {} as { [key: string]: any }
  );

  // Optimistic updates that will trigger optimistic computations.
  const [optimisticUpdates, setOptimisticUpdates] = useState({} as Record<string, string>);

  const refPendingUpdates = useRef(pendingUpdates);

  // Fetch the state and assume that anything saved by the server has been validated
  const [applicant, setApplicant] = useState({} as Awaited<ReturnType<typeof getEverything>>);
  const getEverything = usePost('/applicant/get_everything');
  async function refreshApplicant() {
    setApplicant(await getEverything({ uid: props.uid }))
  }
  useEffect(() => {
    refreshApplicant();
  }, [])

  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (applicant.info) {
      // Don't refresh if pending changes
      if (Object.keys(refPendingUpdates.current).length !== 0) {
        return;
      }

      setRemoteApplicantInfo(applicant.info);
      setScreeningOrgInfo(applicant.screener_org_metadata || {});
      setScreenerMetadata(applicant.screener_metadata || {});
      setApplicantInfo(applicant.info);

      const valid: { [key: string]: boolean } = {};
      for (const i in applicant.info) {
        if (applicant.info[i]?.length) {
          valid[i] = true;
        }
      }
      setApplicantInfoValid(valid);
      setInfoLoaded(true);
    }
  }, [applicant]);

  // Load the actual survey itself
  const data = useContext(SurveyContext);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [sectionMetadata, setSectionMetadata] = useState({} as any);

  // a list sv where sv[i] = true iff data.sections[i] is a visible (active) section
  // created in preparation for sectionActive to go async on us
  const [sectionVisibility, setSectionVisibility] = useState([] as boolean[]);
  useEffect(() => {
    (async () => {
      if (data?.sections) {
        const activeInfo = new Array(data.sections.length).fill(false);
        await Promise.all(data.sections.map(async (s: any, i: number) => {
          const active = await sectionActive(s);
          activeInfo[i] = active;
        }));
        setSectionVisibility(activeInfo);
      }
    })();
  }, [data.sections]);

  useEffect(() => {
    if (data.sections && !surveyLoaded) {
      setSurveyLoaded(true);
      for (let i = 0; i < data.sections.length; i++) {
        if (
          encodeURIComponent(data.sections[i]["Question"]) ===
          window.location.hash.slice(1)
        ) {
          setActiveSection(i);
          setSectionMetadata(safeParse(data.sections[i]['Metadata'] || '{}'));
        }
      }
    }
  }, [data, surveyLoaded]);

  // Keep track of any disqualifying answers so we can show UI to bail out
  const initialDisqualifierState: { [key: string]: boolean } = {};
  const [applicantDisqualifiers, setApplicantDisqualifiers] = useState(
    initialDisqualifierState
  );

  const [disqualified, setDisqualified] = useState(false);

  // Shows which page we're currently on
  const [activeSection, setActiveSection] = useState(0);
  useEffect(() => {
    if (data.sections) {
      // This is a clever way to set the hash without triggering a long page reload
      // TODO: do something more react-standard in the future
      window.history.pushState(
        null,
        "",
        "#" + encodeURIComponent(data.sections[activeSection]["Question"])
      );

      window.scrollTo({ 'top': 0 });
    }
  }, [activeSection, data.sections]);

  // The API call to save a key/value pair
  const saveInfoRS = useAPIPost(get_rs_host() + "/compute_and_save", { includeTokenInData: true });
  const claimApplicant = useAPIPost("/applicant/" + props.uid + "/claim_applicant");

  const savingRef = useRef(false);
  const [commsUnhandledCount, setCommsUnhandledCount] = useState(0);
  const [iaUnhandledCount, setIAUnhandledCount] = useState(0);

  const [unhandledCountByChannel, setUnhandledCountByChannel] = useState({} as Record<string, number>);
  const commsChannels = useAuthorizedCommsChannels({ config });

  const getProgramConfig = usePost('/program/configuration');
  const [programConfig, setProgramConfig] = useState<any>({});
  const [fraudTabEnabled, setFraudTabEnabled] = useState<boolean>(false);
  const [fraudFlagsLength, setFraudFlagsLength] = useState<number | null>(null);

  const [canSetInfo, setCanSetInfo] = useState(true);

  useEffect(() => {
    getProgramConfig({}).then(pc => pc || ({})).then(programConfig => {
      setProgramConfig(programConfig);
      if (programConfig['fraud']) {
        let fraudConfig = JSON.parse(programConfig['fraud']);
        if (fraudConfig && 'enableTab' in fraudConfig) {
          setFraudTabEnabled(fraudConfig['enableTab']);
        }
      }
    });
  }, [])

  // Do some setup for RS
  const infoDefs = useSurveyDescription();
  const deployment = get_deployment();

  useEffect(() => {
    refPendingUpdates.current = pendingUpdates;
  });

  const cb = useCallback(() => {
    if (document.hidden) return;
    refreshApplicant();
  }, [refreshApplicant]);
  useInterval(cb, 5000);

  // Everytime the survey validity changes, recompute available sections
  useEffect(() => {
    (async () => {
      if (data.sections) {
        const sectionsComplete = [] as boolean[];
        const sectionsStarted = [] as boolean[];
        for (let section of data.sections) {
          let sectionComplete = true;
          let sectionStarted = false;
          for (let q of section.Questions || []) {
            // question is complete
            const questionComplete = await questionIsComplete(q,
              applicantInfo,
              applicantInfoValid,
              {
                treatOptionalAsComplete: true,
                hasSubQuestions: q['Field Type'] === 'Likert' || q['Field Type'] === 'Number With Unit'
              },
              screenerInfo,
              screeningOrgInfo
            );
            // if any question is not complete, mark section as incomplete
            const isDone = questionComplete !== undefined && questionComplete !== null && questionComplete !== false;
            if (!isDone) {
              sectionComplete = false;
            }
            // question is started
            const questionStarted = await questionIsComplete(q,
              applicantInfo,
              applicantInfoValid,
              { treatOptionalAsComplete: false, onlyIfHumanFillable: true },
              screenerInfo,
              screeningOrgInfo
            );

            // if any question is started, mark section as started
            const isStarted = questionStarted !== undefined && questionStarted !== null && questionStarted !== false;
            if (isStarted) {
              sectionStarted = true;
            }
          }

          sectionsComplete.push(sectionComplete);
          sectionsStarted.push(sectionStarted);
        };

        setSectionComplete(sectionsComplete);
        setSectionStarted(sectionsStarted);
      }
    })();
  }, [data, applicantInfoValid, applicantInfo]);

  // Everytime disqualification changes, check if we should terminate the applicant
  useEffect(() => {
    setDisqualified(
      Object.keys(applicantDisqualifiers)
        .map((k) => applicantDisqualifiers[k])
        .some((e) => e)
    );
  }, [applicantDisqualifiers]);

  useEffect(() => {
    if (Object.keys(optimisticUpdates).length === 0) return;
    if (Object.keys(config).length === 0) return; // Wait til we are sure of our configuration.

    const optimisticTimer = (setTimeout(async () => {
      if (Object.keys(optimisticUpdates).length === 0) return;
      console.log("Optimistically Computing RS Locally");
      console.time("Optimistic Computations");
      try {
        const updates = await computeKeys({
          infoKeys: { ...applicantInfo, ...optimisticUpdates },
          infoDefs,
          computeOptions: {
            debugKeys: {}
          }
        });

        if (updates.isErr()) {
          console.warn("Error computing: ", updates.error);
          return;
        }

        console.log("Optimistic updates: ", updates.value);

        setApplicantInfo((prevState: Record<string, string>) => {
          const nextState: any = Object.assign({}, prevState);
          for (const key in updates.value) {
            // Cannot compute stage here because there are no stage defs provided by survey API
            if (key === 'stage') continue;
            if (optimisticUpdates[key]) continue;

            nextState[key] = updates.value[key];
          }
          return nextState;
        });

      } catch (e) {
        console.log("Error computing: ", e);
      }
      setOptimisticUpdates((prevState: any) => {
        const nextState: any = Object.assign({}, prevState);
        for (const key in optimisticUpdates) {
          if (prevState[key] === optimisticUpdates[key]) {
            delete nextState[key];
          }
        }
        return nextState;
      });

      console.timeEnd("Optimistic Computations");
    }, 25));
    return () => clearTimeout(optimisticTimer);
  }, [optimisticUpdates, applicantInfo, infoDefs])

  useEffect(() => {
    if (Object.keys(pendingUpdates).length === 0) return;
    if (Object.keys(config).length === 0) return; // Wait til we are sure of our configuration.

    const nextTimer = (setTimeout(async () => {
      //const pendingUpdates = refPendingUpdates.current;
      console.log("Sending updates", pendingUpdates, Date.now());
      const rsParams: Record<string, any> = {
        deploymentKey: deployment,
        uid: props.uid
      };
      // I do this because sometimes it seems to post to RS twice - 
      // Open to suggestions as to how to fix this by tearing stuff down rather 
      // than adding this ref. But this seems to work!
      if (!savingRef.current) {
        savingRef.current = true;
        console.debug(new Date().toISOString() + " Computing with RS externally for DB", rsParams);
        console.time("Actual Computations");
        const results: Result<Record<string, string>, string> = await saveInfoRS({
          ...rsParams,
          changedKeys: pendingUpdates
        });
        savingRef.current = false;
        console.log("RS Results: ", results);
        if (results?.value) {
          // Don't set if there are pendingUpdates?
          setApplicantInfo((prevState: Record<string, string>) => {
            const nextState: any = Object.assign({}, prevState);
            for (const key in results.value) {
              if (!pendingUpdates[key]) {
                console.debug(`Updating key ${key} to value ${results.value[key]}`);
                nextState[key] = results.value[key];
              }
            }
            return nextState;
          });
          setRemoteApplicantInfo((prevState: any) => {
            const nextState: any = Object.assign({}, prevState);
            Object.assign(nextState, pendingUpdates);
            return nextState;
          });
        }
      }
      console.timeEnd("Actual Computations");
      setPendingUpdates((prevState: any) => {
        const nextState: any = Object.assign({}, prevState);
        for (const key in pendingUpdates) {
          if (prevState[key] === pendingUpdates[key]) {
            delete nextState[key];
          }
        }
        return nextState;
      });
    }, 1000) as unknown) as typeof setTimeout;

    return () => { clearTimeout(nextTimer as any); };
  }, [props.uid, deployment, pendingUpdates, saveInfoRS, config, infoDefs, applicantInfo]);

  useEffect(() => {
    if ((config.roles || '').includes('read-only')) setCanSetInfo(false);
  }, [props.uid, config]);

  async function setInfoKey(
    key: string,
    value: any,
    valid: boolean,
    disqualifies: boolean
  ) {
    if (!canSetInfo) return;
    console.log("Setting key", key, value, valid, disqualifies);

    setApplicantInfo((prevState: any) => {
      if (prevState[key] === value) return prevState;

      const nextState: any = Object.assign({}, prevState);
      nextState[key] = value;
      return nextState;
    });
    // Update the validity
    if (valid !== applicantInfoValid[key]) {
      setApplicantInfoValid((prevState: any) => {
        const nextState: any = Object.assign({}, prevState);
        nextState[key] = valid;
        return nextState;
      });
    }
    // Update any disqualifications
    if (disqualifies !== applicantDisqualifiers[key]) {
      setApplicantDisqualifiers((prevState: any) => {
        const nextState: any = Object.assign({}, prevState);
        nextState[key] = disqualifies;
        return nextState;
      });
    }

    // If the value is valid and different, queue an update
    const pendingUpdater = (prevState: any) => {
      const nextState: any = Object.assign({}, prevState);
      nextState[key] = value;
      return nextState;
    }
    if ((valid || value === "") && ((remoteApplicantInfo[key] || "") !== value ||
      (pendingUpdates[key] !== undefined && pendingUpdates[key] !== value))) {
      setPendingUpdates(pendingUpdater);
      setOptimisticUpdates(pendingUpdater);
    }
  }

  // Don't show anything while we're loading the questions or the data
  if (!infoLoaded || !surveyLoaded) {
    return <h2 className="p-5">{L.applicant.loading}</h2>
  }

  async function doClaimApplicant() {
    const result = await claimApplicant({});
    if (result.status === 'ok') {
      refreshApplicant();
    }
  }

  function isAuthorized(s: any): boolean {
    if ((s['Additional Options'] || []).includes('Hidden from Screener') && !applicant.reviewer) {
      return false;
    }

    return true;
  }

  async function sectionActive(s: APISectionProps) {
    if ((s['Additional Options'] || []).includes('Unconditional for Screener')) {
      return true;
    }
    if (s['Conditional On']) {
      if (s['Conditional On Value']) {
        if (s['Conditional On Value'].indexOf(applicantInfo[s['Conditional On']]) === -1) {
          return false;
        }
      } else {
        if (!(applicantInfo[s['Conditional On']])) {
          // console.log(s[languageContent(context.lang)], 'hidden', '1');
          return false;
        }
      }
    }

    if (s['Metadata']) {
      const metadata = safeParse(s['Metadata'] || '{}');
      // console.log("Checking metadata for section: " + s['Question']);
      if (metadata?.conditional) {
        return await evalConditional(metadata.conditional, applicantInfo, screeningOrgInfo, screenerInfo);
      }
    }

    return true;
  }

  return (<>
    {!props.subsurvey && (
      <h2 id="screener-view-header" className="sticky top-0 z-10 name-and-status">
        {applicantInfo["legal_name"]}, {applicantInfo["stage"]}, {L.applicant.screened_by}{" "}
        {publicConfig?.interface?.disableReassignScreener ?
          <>{applicant.screener_name}</> :
          <ReassignDropdown uid={props.uid} applicant={applicant} applicantInfo={applicantInfo} />
        }
        {applicant.screener_name === '(not assigned)' &&
          <><Button onClick={doClaimApplicant}>{L.applicant.claim_applicant}</Button>&nbsp;</>}
        <div style={{ float: "right" }}>
          {((disqualified || applicantInfo["stage"] === 'Ineligible') && (
            <Button variant="danger" href="/">
              {L.applicant.finish_survey_applicant_ineligible}
            </Button>
          )) || (
            <>
              {activeSection > 0 && (
                <Button onClick={() => {
                  let nextSection = activeSection - 1;
                  while ((!sectionVisibility[nextSection] || !isAuthorized(data.sections[nextSection])) && nextSection > 0) {
                    nextSection -= 1;
                  }
                  setActiveSection(nextSection);
                }}>
                  {L.applicant.back}
                </Button>
              )}
                &nbsp;
              {activeSection < data.sections.length - 1 && (
                <Button onClick={() => {
                  let nextSection = activeSection + 1;
                  while ((!sectionVisibility[nextSection] || !isAuthorized(data.sections[nextSection])) &&
                      nextSection < data.sections.length - 1) {
                    nextSection += 1;
                  }
                  setActiveSection(nextSection)
                }}>
                  {L.applicant.next}
                </Button>
              )}
            </>
          )}
        </div>
        <Dropdown style={{ float: "right", marginRight: "20px" }}>
          <Dropdown.Toggle variant="link">
            {data.sections[activeSection][languageContent(context.lang)]}
            &nbsp;
            {sectionComplete[activeSection] && (
              <Badge key={`badge-${activeSection}`} variant="success">{L.applicant.done}</Badge>
            )}
            {!sectionComplete[activeSection] && sectionStarted[activeSection] && (
              <Badge key={`badge-${activeSection}`} variant="info">{L.applicant.in_progress}</Badge>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            {data.sections &&
              data.sections.map((s: any, i: number) => {
                if (!sectionVisibility[i]) return '';
                if (!isAuthorized(s)) return '';

                // If all preceding sections are complete then this is clickable
                return (
                  <Dropdown.Item onClick={() => setActiveSection(i)} key={i}>
                    {s[languageContent(context.lang)]}
                    &nbsp;
                    {sectionComplete[i] && (
                      <Badge key={`badge-${i}`} variant="success">{L.applicant.done}</Badge>
                    )}
                    {!sectionComplete[i] && sectionStarted[i] && (
                      <Badge key={`badge-${i}`} variant="info">{L.applicant.in_progress}</Badge>
                    )}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
      </h2>
    )}
    <div className="p-5">
      <div className="container">
        <div className="row">
          <div className="col">
            {data.sections && data.sections[activeSection] && (
              <SurveySection
                setInfoKey={setInfoKey}
                complete={sectionComplete[activeSection]}
                expireDate={data['expire_date']}
                section={data.sections[activeSection]}
                info={applicantInfo}
                infoValid={applicantInfoValid}
                uid={props.uid}
                orgMetadata={screeningOrgInfo}
                screenerMetadata={screenerInfo}
                stages={data.stages || []}
                currentStage={applicantInfo["stage"]}
              />
            )}

            {props.subsurvey && !data.is_dashboard &&
              sectionComplete[0] &&
              Object.keys(pendingUpdates).length === 0 && (
              <h2><br /><br /><br /><br />{L.applicant.all_done_you_may_now_close_this_window}</h2>
            )}
          </div>
          {!props.subsurvey && (
            <div className="col overflow-x-hidden">
              {config.enable_robocontroller && <RCPayments applicant_uid={props.uid} legacy />}
              {(applicant.payments || []).length > 0 && (
                <>
                  <br />
                  <br />
                  <h3>{L.applicant.payments}</h3>
                  {applicant.payments.map((p: any) => {
                    return <Payment key={p.id} payment={p} />;
                  })}
                  <br />
                  <br />
                </>
              )}
              {(applicant.screener_name !== '(not assigned)' && !!applicantInfo["previous_app"]) &&
                <DataRequestBox uid={props.uid} applicant={applicant} applicantInfo={applicantInfo} refreshApplicant={refreshApplicant} />}
              {!(config.roles || '').includes('no-tabs') && <Tabs defaultActiveKey="notes">
                {(config.roles || '').includes('no-notes') ? null : <Tab eventKey="notes" title={L.applicant.notes.title}>
                  <br />
                  <Notes
                    uid={props.uid}
                    canRequestChanges={applicant.reviewer && !(config.disable_change_requests === 'true')}
                    canDeleteNotes={applicant.reviewer}
                  />
                </Tab>}
                {!(config.roles || '').includes('no-comms') && commsChannels.map((c: string) => {
                  return <Tab eventKey={`comms_channel_${c}`} title={
                    <>
                      {L.applicant.comms.title} ({c})
                      <TailwindBadge extraClasses="ml-1 px-1" color='yellow'>{unhandledCountByChannel[c] || 0}</TailwindBadge>
                    </>
                  }>
                    <br /><ApplicantComms
                      uid={props.uid}
                      info={applicantInfo}
                      setUnhandledCount={(count) => setUnhandledCountByChannel({ ...unhandledCountByChannel, [c]: count })}
                      canSendComms={applicant.screener || applicant.reviewer}
                      channel={c} /></Tab>
                })}
                {((config.roles || '').indexOf('internal audit') !== -1) && (
                  <Tab eventKey="internal_audit_notes" title={<>
                    IA {L.applicant.notes.title}
                  </>}>
                    <br />
                    <Notes
                      uid={props.uid}
                      canRequestChanges={false} // don't need to request changes for IA
                      canDeleteNotes={true}
                      internalAudit={true}
                    />
                  </Tab>
                )}
                {!(config.roles || '').includes('no-history') && <Tab eventKey="history" title={L.applicant.history.title}>
                  <br />
                  <History uid={props.uid} canViewChanges={true} />
                </Tab>}
                {false && <Tab eventKey="help" title={L.applicant.help.title}>
                  <br />
                  <Help uid={props.uid} />
                </Tab>}
                {!(config.roles || '').includes('read-only') && ((config.roles || '').indexOf('admin') !== -1 ||
                  (config.roles || '').indexOf('reviewer') !== -1 ||
                  (config.roles || '').indexOf('reports') !== -1) && (
                  <Tab eventKey="admin" title={L.applicant.reports.title}>
                    <br />
                    <AdminTab uid={props.uid} />
                  </Tab>
                )}
                {(fraudTabEnabled &&
                  ((config.roles || '').indexOf('admin') !== -1 ||
                    (config.roles || '').indexOf('reviewer') !== -1)) && (
                  <Tab eventKey="fraud" title={
                    <>
                      {L.applicant.fraud.title}
                      {fraudFlagsLength ? <TailwindBadge extraClasses="ml-1 px-1" color='red'>{fraudFlagsLength || 0}</TailwindBadge> : ''}
                    </>
                  }>
                    <br />
                    <FraudFlags uid={props.uid} setLength={(length: number) => setFraudFlagsLength(length)} />
                  </Tab>
                )}
              </Tabs>}
            </div>
          )}
        </div>
      </div>
    </div>
  </>);
}

export function SurveyConfiguration(props: { path: string, children: any }) {
  const [_, auth_token] = useToken() as any;
  const [data, setData] = useState<any>({});
  const maybeCompressedData = useAPI(props.path === '/survey' ? 'api://survey?program=' + get_deployment()
    + '&token=' + auth_token.replace('auth=', '')
    : props.path);

  useEffect(() => {
    if (maybeCompressedData.sections_gz) {
      const decompressed = strFromU8(decompressSync(new Uint8Array(Buffer.from(maybeCompressedData.sections_gz, 'base64'))));
      setData({ sections: JSON.parse(decompressed) });
    } else {
      setData(maybeCompressedData);
    }
  }, [maybeCompressedData]);

  return <SurveyContext.Provider value={data} >
    {props.children}
  </SurveyContext.Provider>
}

function ApplicantPage(props: { isSubsurvey?: boolean }) {
  const { uid, subsurvey } = useParams() as Record<string, string>

  if (props && props.isSubsurvey) {
    return (
      <SurveyConfiguration path="/subsurvey">
        <SurveyPage uid={uid} subsurvey={true} />
      </SurveyConfiguration>
    );
  }

  // This `if (subsurvey)` param path is deprecated. 
  // See /subsurvey and /survey/{subpart} on the backend API.
  // The Route associated with this is also deprecated. (/applicant/:uid/:subsurvey)
  // Instead, The token takes care of the :subsurvey param.
  // So we can use /applicant/:uid/subsurvey now instead, with no param. 
  if (subsurvey) {
    return (
      <SurveyConfiguration path={"/survey/" + subsurvey}>
        <SurveyPage uid={uid} subsurvey={true} />
      </SurveyConfiguration>
    );
  }

  // This is the default, return the survey
  return (
    <SurveyConfiguration path="/survey">
      <SurveyPage uid={uid} />
    </SurveyConfiguration>
  );
}

export default ApplicantPage;
