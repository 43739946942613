import { useCallback, useEffect, useId, useState } from 'react';
import { SpacedSpinner } from '../Util';
import { usePost } from '../API';
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import type { Schedule } from 'aidkit/lib/common/cron';
import { useLocalizedStrings } from '../Localization';
import { InfoDict } from '../Questions/Props';
import { prettifySchedule } from './PrettyUtil';
import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from '@aidkitorg/component-library';

type StatusInfo = {
  name: string;
  status: 'pending' | 'done' | undefined;
  value: string | undefined;
  schedules: Schedule[];
  lastRan: string | undefined;
  lastUpdated: string | undefined;
  depshashFields: string[];
  targetField: string;
}

function ThirdPartyCheckStatusItem(props: { status: StatusInfo, uid: string, fetchStatuses: () => Promise<void>, saveInfo: (info: InfoDict) => void, isAdmin: boolean }) {
  const [showDetails, setShowDetails] = useState(false);
  const [reRunLoading, setReRunLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(props.status.value);
  const L = useLocalizedStrings();

  useEffect(() => {
    let currentValue = props.status.value || 'N/A';
    try {
      // pretty print it if it's JSON otherwise just show it as is.
      currentValue = JSON.stringify(JSON.parse(currentValue), null, 2);
    } catch (error) {
    }
    setCurrentValue(currentValue);
  }, [props.status.value]);

  return (
    <AccordionItem value={props.status.targetField}>
      <AccordionTrigger>
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-x-3">
            {
              props.status.status === 'done' ? <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> :
                props.status.status === 'pending' ? <div className="animate-pulse rounded-full bg-yellow-300 h-6 w-6" /> :
                  <MinusCircleIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
            }
            <div className="text-left">
              <h3 className="mb-0 text-sm font-semibold leading-6 text-gray-900">{props.status.targetField}</h3>
              <p className="mb-0 text-xs text-gray-500">
                {
                  props.status.status === 'done' ? L.applicant.check_status.done :
                    props.status.status === 'pending' ? L.applicant.check_status.pending :
                      L.applicant.check_status.not_ready
                }
              </p>
            </div>
          </div>
          <time dateTime={props.status.lastRan} className="text-xs text-gray-500">
            {L.applicant.check_status.ran}: {props.status.lastRan ? props.status.lastRan : L.applicant.check_status.never}
          </time>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="ml-3">
          <div className="mt-3 grid grid-cols-2 gap-y-2 gap-x-4 text-sm">
            <div className="text-gray-700">{L.applicant.check_status.schedule}:</div>
            <div className="text-gray-500 text-xs">{props.status.schedules.map((schedule, index) => {
              return <p key={index} className='mb-0'>{prettifySchedule(schedule, L)}</p>})}</div>

            <div className="text-gray-700">{L.applicant.check_status.last_updated}:</div>
            <div className="text-gray-500 text-xs">{props.status.lastUpdated ? props.status.lastUpdated : L.applicant.check_status.never}</div>
          </div>
          <pre className="mt-2 p-2 text-xs text-gray-600 bg-gray-100 rounded-md">{currentValue}</pre>
          { props.status.status === 'done' && props.isAdmin && 
                    (reRunLoading ?
                      <SpacedSpinner />
                      :
                      <button className="hover:bg-blue-500 text-blue-700 text-sm hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded" onClick={async () => {
                        setReRunLoading(true);
                        props.saveInfo((props.status.depshashFields.reduce((acc, field) => {
                          acc[field] = 'trigger_rerun';
                          return acc;
                        }, {} as InfoDict)));
                        await props.fetchStatuses();
                        setReRunLoading(false);
                      }}>{L.applicant.check_status.trigger_rerun}</button> )
          }
        </div> 
      </AccordionContent>
    </AccordionItem>
  );
}

export function ThirdPartyCheckStatusTab(props: { uid: string, info: InfoDict | null, saveInfo: (info: InfoDict) => void, isAdmin: boolean }) {
  const [statuses, setStatuses] = useState<StatusInfo[]>([]);
  const thirdPartyCheckStatus = usePost('/applicant/third_party_check_status');

  const fetchStatuses = useCallback(async () => {
    try {
      const result = await thirdPartyCheckStatus({ uid: props.uid });
      setStatuses(result);
    } catch (error) {
      console.error("Failed to fetch statuses:", error);
    }
  }, [props.uid, thirdPartyCheckStatus]);

  useEffect(() => {
    fetchStatuses();
    const intervalId = setInterval(fetchStatuses, 5000);

    return () => clearInterval(intervalId);
  }, [fetchStatuses]);

  return (
    <div>
      <Accordion type="multiple">
        {statuses.map((status) => (
          <ThirdPartyCheckStatusItem
            key={status.targetField}
            status={status} uid={props.uid}
            fetchStatuses={fetchStatuses}
            saveInfo={props.saveInfo}
            isAdmin={props.isAdmin} />
        )
        )}
      </Accordion>
      {!statuses.length && <SpacedSpinner />}
    </div>
  )
}
