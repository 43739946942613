import * as v0 from "@aidkitorg/types/lib/survey";
import { CompileExpressionToJS } from "@aidkitorg/types/lib/translation/expr_to_js";
import { useState } from "react";
import { usePost } from "../API";
import { flattenObject } from "../utils/flattenObject";
import { HouseholdMember } from "./Household";
import { QuestionProps } from "./Props";

type BenefitState = Record<string, Record<string, {
  eligible: boolean,
  amount?: number,
}>>

export function BenefitsCalculator(props: QuestionProps & { definition: v0.BenefitsCalculator }) {
  const doBenefits = usePost('/applicant/calculate_benefits');
  const [origBenefits, setOrigBenefits] = useState<any>(null);
  const [origBenefitList, setOrigBenefitList] = useState<BenefitState>({});
  const [enrollBenefits, setEnrollBenefits] = useState<any>(null);
  const [enrollBenefitList, setEnrollBenefitList] = useState<BenefitState>({});
  const [lastRun, setLastRun] = useState<any>(null);
  const params = {} as Record<string, string>;
  const computed = {} as Record<string, string | number>;
  const [computing, setComputing] = useState(false);

  Object.keys(props.definition.required_info).map((r) => {
    params[r] = CompileExpressionToJS((props.definition.required_info as any)[
      r as any] as any)
  });

  Object.keys(props.definition.optional_info).map((b) => {
    Object.keys(props.definition.optional_info[b as keyof v0.BenefitsCalculator['optional_info']] as any).map((r) => {
      params[r] = CompileExpressionToJS((props.definition.optional_info[b as keyof v0.BenefitsCalculator['optional_info']] as any)[r]);
    });
  });

  function compute(formula: string, info: any): string {
    const func = Function(
      "return (function(info, org, screener) { const out = " + formula + "; return out });"
    )();
    return (func(info, {}, {}) || "") + "";
  }

  let hideEnrollImpact = false;
  if (props.definition.hideImpactIf) {
    hideEnrollImpact = compute(CompileExpressionToJS(props.definition.hideImpactIf), props.info) === 'true';
  }

  Object.keys(params).map(k => {
    try {
      computed[k] = compute(params[k], props.info);
    } catch (e) {
      console.error("Error in formula", k, e);
    }
  })

  const computedFingerprint = JSON.stringify(computed);

  function extractBenefits(benefits: any) {
    const out: BenefitState = {}; 
    Object.keys(benefits).map(group => {
      Object.keys(benefits[group]).map(k => {
        out[group] = out[group] || {};
        if (k.endsWith('_benefit_name')) {
          out[group][benefits[group][k]] = {
            eligible: benefits[group][k.replace('_benefit_name', '_eligibility')] === 'Eligible',
            amount: benefits[group][k.replace('_benefit_name', '_amount')]
          };
        }
      });
    });
    return out;
  }

  async function run() {
    if (computing) return;

    const persons: {age: number}[] = [];
    if (props.definition.household_calc_target_field) {
      const householdInfo: Record<string, HouseholdMember[]> = JSON.parse(props.info[props.definition.household_calc_target_field]);
      const currentDate = new Date();
      const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;

      Object.keys(householdInfo).forEach((group) => {
        householdInfo[group].forEach((member) => {
          if (!member.dependence || (member.dependence && member.dependence != 'independent')) {
            if (member.birth_year) {
              // Note: this is coarse and may result in years off by 1, which may be undesirable. Consider using full birth_date
              persons.push({ age: currentDate.getFullYear() - Number(member.birth_year) });
            } else if (member.birth_date) {
              persons.push({ age: Math.floor((currentDate.getTime() - new Date(member.birth_date).getTime()) / millisecondsPerYear) });
            } else {
              // throw
            }
          }
        });
      });
    } else {
      // Fallback to hacky way of setting household until we settle on desired way of setting household data
      for (var i = 0; i < parseInt(props.info.adults || '1'); i++) {
        persons.push({
          age: 30
        });
      }
      for (var i = 0; i < parseInt(props.info.children || '0'); i++) {
        persons.push({
          age: 12
        });
      }
    }

    setComputing(true);

    const origBenefits = await doBenefits({...computed, persons,
      gi_snap: props.definition.waivers.snap ? 'no' : 'yes',
      gi_wic: props.definition.waivers.wic ? 'no' : 'yes',
      gi_tanf: props.definition.waivers.tanf ? 'no' : 'yes',
      gi_util: props.definition.waivers.liheap ? 'no' : 'yes',
      gi_healthcare: props.definition.waivers.healthcare ? 'no' : 'yes',
      gi_taxes: props.definition.waivers.taxCredits ? 'no' : 'yes',
      gi_childcare: props.definition.waivers.childcare ? 'no' : 'yes',
      gi_income: 0,
    });
    setOrigBenefits(origBenefits);
    const extractedOrigBenefits = extractBenefits(origBenefits.calculators)
    setOrigBenefitList(extractedOrigBenefits);

    let extractedEnrollBenefits
    if (!hideEnrollImpact) {
      const enrollBenefits = await doBenefits({...computed, persons,
        gi_snap: props.definition.waivers.snap ? 'no' : 'yes',
        gi_wic: props.definition.waivers.wic ? 'no' : 'yes',
        gi_tanf: props.definition.waivers.tanf ? 'no' : 'yes',
        gi_util: props.definition.waivers.liheap ? 'no' : 'yes',
        gi_healthcare: props.definition.waivers.healthcare ? 'no' : 'yes',
        gi_taxes: props.definition.waivers.taxCredits ? 'no' : 'yes',
        gi_childcare: props.definition.waivers.childcare ? 'no' : 'yes',
      });
      setEnrollBenefits(enrollBenefits);
      extractedEnrollBenefits = extractBenefits(enrollBenefits.calculators);
      setEnrollBenefitList(extractedEnrollBenefits);
    }

    setLastRun(computedFingerprint);

    if (props['Target Field']) {
      props.setInfoKey(props['Target Field'], JSON.stringify({ pre: extractedOrigBenefits, post: extractedEnrollBenefits }), true, false);
    }

    // TODO(Riley): Note: in order for RS to properly derive the fields for Benefits Calculator
    // when using flattenIntoFieldsWithPrefix, a targetField must also be specified. For historical
    // reasons, the target field was not always required for the Benefits Calculator which means it is
    // possible to configure an undesirable state using the existing types. This should be corrected
    // in the future, likely through a migration that forces targetField to always be specified.
    if (props['Target Field'] && props.definition.flattenIntoFieldsWithPrefix) {
      const flattenedOutput = flattenObject({ pre: extractedOrigBenefits, post: extractedEnrollBenefits });

      Object.entries(flattenedOutput ?? {}).forEach(entry => {
        // We replace 'high_speed_internet' and 'earned_income_tax_credit' to acronyms to keep the length of variable names a little more reasonable.
        // If any changes are made here in the future, remember to change the derived fields for Benefits Calculator to reflect that
        props.setInfoKey(
          props.definition.flattenIntoFieldsWithPrefix + '_' + entry[0].replaceAll(/high_speed_internet/ig, 'hsi').replaceAll(/earned_income_tax_credit/ig, 'eitc'),
          JSON.stringify(entry[1]),
          true,
          false);
      });
    }

    setComputing(false);
  }

  return <>
    {false && <>
      <div className="p-2 break-all">{origBenefits && origBenefits.params}</div>
      <div className="p-2 break-all">{enrollBenefits && enrollBenefits.params}</div>
    </>}
    {lastRun !== computedFingerprint ? 
      (computing ? 'Computing...' :
        <button
          onClick={run}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Compute Benefits Impact
        </button>)
      :
      <>
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Benefit 
                  </th>
                  <th scope="col" className="px-1 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Current Likely Eligibility 
                  </th>
                  {!hideEnrollImpact &&
                                <th scope="col" className="px-1 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  If You Enroll 
                                </th>
                  }
                </tr>
              </thead>
              <tbody className="bg-white">
                {Object.keys(origBenefitList).map((group) => {
                  return Object.keys(origBenefitList[group] || {}).map((benefit) => {
                    // The API response has some weirdly formatted things
                    if (['', 'Not Eligible', 'ssissd', 'WIC', 'General Relief', 'CARE', 'High Speed Internet', 'Unemployment Insurance'].includes(benefit)) {
                      return <></>;
                    }
                    const origInfo = origBenefitList[group][benefit]; 
                    const enrollInfo = !hideEnrollImpact && enrollBenefitList[group][benefit]; 
                    return <tr key={benefit}>
                      <td className="whitespace-nowrap py-1 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                        {benefit}
                      </td>
                      <td className="whitespace-nowrap p-1 text-sm text-gray-500">{(origInfo && origInfo.amount) ? '$' + origInfo.amount : 'Not Eligible'}</td>
                      {enrollInfo &&
                                        <td className="whitespace-nowrap p-2 text-sm text-gray-500">{(enrollInfo && enrollInfo.amount) ? '$' + enrollInfo.amount : 'Not Eligible'}</td>
                      }
                    </tr>
                  })
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    } 
  </>
}
