import React, { useCallback, useContext, useState } from "react";
import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { RigidSpinner, languageContent, safeParse } from "../Util";
import { QuestionProps } from "./Props";
import * as Sentry from "@sentry/react";
import { useToast } from "@aidkitorg/component-library";
import { getURLsFromCSV } from "@aidkitorg/roboscreener/lib/util/urls_from_csv";
import { useDownloader, usePost } from "../API";
import type { BulkDownload, Text } from "@aidkitorg/types/lib/survey";

async function loadFFlate() {
  return await import('fflate');
}

export function ActionButton(props: QuestionProps) {
  const prev = (props.info[props["Target Field"]!] || '').match(/^(01|02|03|04|05|06|07|08|09|10|11|12)\/([0-3][0-9])\/((19|20)[0-9][0-9])$/);
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const content = props[languageContent(context.lang)];
  const metadata = safeParse(props["Metadata"]!) as {
    action: string | BulkDownload,
    confirmation: Text,
    toast: Text,
  };
  const action = metadata.action;

  const getSignedURL = usePost('/document/view');
  const { toast } = useToast();
  const downloadFile = useDownloader();

  const [loadingDownload, setLoadingDownload] = useState(false);

  let func: any;
  if (action && typeof action === 'string') {
    try {
      func = Function(
        "return (function(info, org, screener) { const out = " + action + "; return out });"
      )();
    } catch (err: any) {
      console.log('Invalid Action formula. Error:', err);
    }
  }

  const loadAttachments = (targetField: string) => {
    return getURLsFromCSV(props.info[targetField] || "")
      .reduce((acc, url: string) => {
        acc[url] = true;
        return acc;
      }, {} as Record<string, boolean>);
  };

  async function doBulkDownload() {
    if (typeof action === 'string') return; //should not happen, just to keep typescript happy

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10); // YYYY-MM-DD
    const formattedTime = currentDate.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS
    const folderName = `${action.fileName || 'downloads'}_${formattedDate}_${formattedTime}`;

    const zipContents = {} as any;
    setLoadingDownload(true);
    const downloadFields = action.fields;
    for (const field of downloadFields) {
      // target could be a list of options. 
      const loadedURLS = loadAttachments(field);
      const res = await getSignedURL({
        paths: Object.keys(loadedURLS),
      });

      const urls = Object.values(res.paths);
      for (let index = 0; index < urls.length; index++) {
        const url = urls[index];
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        zipContents[`${field}${index + 1}.jpg`] = new Uint8Array(arrayBuffer);
      }
    }

    const fflate = await loadFFlate();
    const zippedData = fflate.zipSync(zipContents);
    const blob = new Blob([zippedData], { type: 'application/ zip' });
    const url = window.URL.createObjectURL(blob);
    downloadFile(url, `${folderName}.zip`);

    setLoadingDownload(false);
  }

  function doAction() {
    if (metadata.confirmation) {
      const sure = window.confirm(metadata.confirmation[context.lang || 'en'] || 'Are you sure you wish to continue?');
      if (!sure) return;
    }
    try {
      const r: Record<string, any> = func(props.info, props.orgMetadata, props.screenerMetadata) || {};
      for (const key in r) {
        props.setInfoKey(key, (r[key] || '') + '', true, false);
      }
      if (metadata.toast) {
        toast({
          description: metadata.toast[context.lang || 'en'] || 'Success!',
          variant: 'success'
        });
      }
    } catch (e) {
      console.log("Error computing: ", e);
      alert("Error: " + e);
      Sentry.captureException(e, { 
        extra: {
          action
        }
      });
    }
  }

  return <div>
    <button
      type="button"
      onClick={typeof action === 'string' ? doAction : doBulkDownload}
      className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      {content}
    </button>
    {loadingDownload ? <RigidSpinner /> : <></>}
  </div>
}