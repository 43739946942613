import React, { useContext, useEffect, useState, useCallback, useRef, Fragment, ContextType, lazy, Suspense, useMemo } from "react";
import { Button as AidKitButton, Loading, LocalizationProvider, Toaster, UserInactivityTimeout, useToast } from "@aidkitorg/component-library";

import ApplicantPage, { SurveyConfiguration } from "./Applicant";
import AuthPage from "./Auth";
import InterfaceContext, { ConfigurationContext, OfflineSyncContext, OnlineAwarenessContext, PublicConfigurationContext, LoggedInConfigurationContext, SupportedLanguage, TwilioDeviceContext, UserInfoContext } from "./Context";
import GoogleCallback from "./AuthGoogleCallback";
import Dashboard from "./Dashboard";
import BankingPage from "./Banking";
import StatsPage from "./Stats";
import ReportsPage from "./Reports";
import AdminPage from "./Admin";
import ProgramConfigPage from "./ProgramConfig";
import StatusPage from "./Status";
import CardPage, { VirtualCardSetup } from "./Card";
import DeviceSetupPage from "./DeviceSetup";
import { BatchesPage } from "./Batches";
import CampaignsPage from "./Admin/Campaigns";
import * as Sentry from "@sentry/react";
import { dsn as sentryDSN } from './Sentry';
import { useAPI, useToken, doRawPost, useAPIPost, get_deployment, useGet, usePost, specialHostnameDeployments } from "./API";
import * as serviceWorkerRegister from './serviceWorkerRegister';

import "bootstrap/dist/css/bootstrap.min.css";
import { useLocalizedStrings, VALID_LANGS, LANG_DICTS, langToWord } from "./Localization";

import "./App.css";

import {
  Redirect as ReactRouterRedirect,
  Router,
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Button, Form, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useCookies } from "react-cookie";
import moment from "moment";
import AdminFunctionsPage from "./AdminFunctions";
import ScreeningInvoicePage from "./Invoices";
import DeviceCodePage from "./DeviceCode";
import { createBrowserHistory } from 'history';
import { AidKitLogo, MiniAidKitLogo, saveKeyToCookieAndRelocateToUrl, safeParse, snakeToEnglish, SpacedSpinner, useOnlineDetection, useOfflineSync } from "./Util";
import { SiteNotFound } from "./NotFound";
import ApplicationPage, { SubsectionPage } from "./Apply";
import * as v1 from "./ApplicantPage";
import SurveyDesignPage from "./SurveyDesign";
import { RoboScreenerPage } from "./RoboScreener";
import { SearchBar, useApplicantSearchAllowed } from "./Components/ApplicantSearch";
import { NewProgramPage } from "./AdminNewProgram";
import { Device, Call } from "@twilio/voice-sdk";
import Unauthorized from "./401";
import { CogIcon, KeyIcon, TableCellsIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import FlyoverMenu from "./Components/Flyover";
import { Dropdown } from "./Components/Dropdown";
import { LanguageDropdown } from "./Components/LanguageDropdown";
import { Disclosure, Transition } from "@headlessui/react";
import { IncomingCallReceiver } from "./Components/CallReceiver";
import ProgramAdminPage, { PA_PAGES } from "./ProgramAdmin";
import ProgramAdmin from "./ProgramAdmin";
import FieldStatsPage from "./FieldStats";
import { SupportPageInner } from "./Support";
import BatchSetPage from "./BatchSet";
import { SupportCasePage } from "./SupportCase";
import { OutreachPage } from "./ProgramAdmin/Outreach";
import ConfigPage from "./Config";
import ConfigVersionsPage from "./ConfigVersions";
import PublicS3 from "./ProgramAdmin/PublicS3";
import { ForbiddenPage } from "./Subsurveys/Forbidden";
import CompilePage from "./Compile";
import DistroTestPage from "./DistroTest";
import { captureEvent, captureException } from "@sentry/react";
import { Explore } from "./Explore";
import ExampleThreeColumnPage, { ThreeColumnPage } from "./Components/ThreeColumnPage";
import { DistroDashboard } from "./DistroDashboard";
import { ProtectedSearchPage } from "./ProtectedSearchPage";
import { ImportPage } from "./Import";
import { DistroTaskPage } from "./DistroTask";
import { LegacySupportCasePage } from "./SupportCaseLegacy";
import { Distro4DistroPage } from "./Distro4Distro";
import { UserImportPage } from "./Admin/UserImport";
import { Challenge, InfoChallenge, interfaceNumber, RichText } from "@aidkitorg/types/lib/survey";
import { PublicConfig, LoggedInConfig } from "@aidkitorg/types/lib/config";
import { HomePage } from "./HomePage";
import { LivenessDetectionPage } from "./Pages/Liveness/Main";
import OfflineBanner from "./OfflineBanner";
import { offlineLogger } from "./utils";
import { DateInput } from "./Components/DateInput";
import { NavigationNode } from "@aidkitorg/types/lib/translation/permissions";
import { ScanBarcodePage } from "./Pages/ScanBarcodePage";
import LaunchPage from "./Launch";
import { Exports } from "./Exports";
import { CopyEditor } from "./CopyEditor";
import { LivenessDebug } from "./ProgramAdmin/LivenessDebugPage";
import { VerificationPage } from "./VerificationPage";
import { ProvisionPage } from "./ProvisionComms";
import { useUserAllowed } from "./Components/PermissionsUtil";
import { FullScreenTurnableImagePage } from "./Components/TurnableImage";
import AccountingPage from "./Accounting";
import { useAsyncEffect } from "./Hooks/AsyncEffect";
import { ApplicantPortalPage } from "./ApplicantPortalPage";
import { AdminAccountSettings } from "./Admin/AccountSettings";
import { ResetPasswordPage } from "./ResetPassword";
import { ConsensedSurveyView } from "./CondensedSurveyView";
import { useStorage } from "./Storage";

const MeetingPage = lazy(() => import('./Pages/Meeting/MeetingAppRoot'));
const DataLabelPage = lazy(() => import('./DataLabeler').then(module => ({ default: module.DataLabelPage })));

export const browserHistory = createBrowserHistory();

function LegacyAirTableFooterComponent() {
  const L = useLocalizedStrings();

  const refreshInfo = usePost("/surveyversions/info");
  const [sv, setSv] = useState({} as Awaited<ReturnType<typeof refreshInfo>>);
  const configuration = useContext(ConfigurationContext);
  const refreshSurvey = useAPIPost("/scheduled_job/sync_airtable");

  const [isLoading, setLoading] = useState(false);

  const svRef = useRef(sv);

  useAsyncEffect(async (cancel) => {
    setSv(await refreshInfo({}, undefined, cancel));
  }, []);

  async function doRefresh() {
    setLoading(true);
    await refreshSurvey({});
    setSv(await refreshInfo({}));
    setLoading(false);

    // refresh page bc survey is not loaded in Footer
    window.location.reload();
  }

  const brokenSurveyTooltip = (props: any) => (
    <Tooltip id="survey-broken-tooltip" {...props}>
      {L.survey_version.latest_has_errors} {props.latest_errors}
    </Tooltip>
  );

  useEffect(() => {
    if (svRef.current !== sv) {
      svRef.current = sv
    }
  }, [sv, svRef]);

  return (
    <>
      {configuration && configuration.user && sv && sv.id && (
        <>
          {configuration.roles?.includes('admin') && (
            <Button onClick={() => doRefresh()} variant="link" disabled={isLoading}>
              {isLoading && <SpacedSpinner />}{L.survey_version.refresh_survey}
            </Button>
          )}
          {sv.is_admin && sv.latest_errors && (
            <OverlayTrigger placement="top" overlay={brokenSurveyTooltip(svRef.current)}>
              <Button variant="link" className="btn-sm"><span role="img" aria-label="Warning">{"⚠️"}</span></Button>
            </OverlayTrigger>)}
          {!isLoading && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="surveyversionoverlay">
              {L.survey_version.survey_updated.replace('VERSION', sv.id)} {moment.unix(svRef.current.created_at!).calendar()}
            </Tooltip>}>
              <span className="my-auto mx-1" role="img" aria-label="info">ⓘ</span>
            </OverlayTrigger>
          )}
          {sv.is_admin ? (
            <OverlayTrigger placement="top" overlay={<Tooltip id="airtabledefoverlay">
              Go to Airtable Definition for this Program
            </Tooltip>}>
              <a className="my-auto mx-1" href={`https://airtable.com/${sv.base_id}`}
                target="_blank" rel="noopener noreferrer"><TableCellsIcon className="h-6 text-gray-500" /></a>
            </OverlayTrigger>
          ) : <></>}
          {sv.is_admin ? (
            <OverlayTrigger placement="top" overlay={<Tooltip id="airtabledefoverlay">
              Go to Distro #entireprogram config for this Program
            </Tooltip>}>
              <a className="my-auto mx-1" href="/config#entireprogram"
                target="_blank" rel="noopener noreferrer"><CogIcon className="h-6 text-gray-500" /></a>
            </OverlayTrigger>
          ) : <></>}
        </>
      )}
    </>
  );
}

export function Footer() {
  const L = useLocalizedStrings();
  const [shouldUseLegacyComponent, setShouldUseLegacyComponent] = useState(false);
  const configuration = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);

  useEffect(() => {
    const val = Object.keys(publicConfig || {}).length > 0 && !interfaceNumber?.(publicConfig?.interface?.version);
    setShouldUseLegacyComponent(val);
  }, [publicConfig]);

  return (<>
    <OfflineBanner />
    <Navbar className="d-flex justify-content-between" bg="light" expand="lg" fixed="bottom">
      <Nav> <a href="/privacy.html">{L.privacy_policy}</a> </Nav>&nbsp;
      <Nav> <a href="/tos.html">{L.terms_and_conditions}</a> </Nav>

      {(shouldUseLegacyComponent || configuration?.organization?.name?.toLowerCase().startsWith('aidkit admin')) ? <Nav className="py-auto flex-row">
        {shouldUseLegacyComponent ? <LegacyAirTableFooterComponent /> : null}
        {configuration?.organization?.name?.toLowerCase().startsWith('aidkit admin') ? (
          <OverlayTrigger placement="top" overlay={<Tooltip id="programadminoverlay">
            Program Admin
          </Tooltip>}>
            <Link className="my-auto mx-1" to={`/program-admin`}>
              <KeyIcon className="h-6 text-gray-500" />
            </Link>
          </OverlayTrigger>
        ) : null}
      </Nav> : null}
    </Navbar></>);
}

export function Menu() {
  const location = useLocation();

  const getMenuData = usePost('/legacy_navigation');
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const configuration = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);

  const searchAllowed = useApplicantSearchAllowed();

  const [dashboardItems, setDashboardItems] = useState<{ name: string, href: string, description: string }[]>([]);

  const showDashboardsMenu = (configuration.roles || '').indexOf('admin') !== -1;
  const useNewDashboards = !!(publicConfig.interface?.useNewDashboards);

  const [linksLoaded, setLinksLoaded] = useState(false);

  const [flyOverOpen, setFlyOverOpen] = useState(false);
  const languageDropdownOpen = useRef(false);
  const flyOverHovering = useRef(false);
  const languageHovering = useRef(false);

  const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configuration.applicant_facing_logo;
  const applicantFacingLogoWidth = publicConfig.interface?.applicantFacingLogo?.width || configuration.applicant_facing_logo_width || '150';

  const programName = publicConfig.name || configuration.program_name || 'AidKit Program';

  const { twilioDevice, twilioCall, setTwilioCall } = useContext(TwilioDeviceContext);

  useEffect(() => {
    (async () => {
      const results = await getMenuData({ language: context.lang });
      if (!results?.links) {
        return;
      }
      setDashboardItems(results.links.map(link => {
        const name = typeof link.name === 'string' ? link.name : (link.name?.[context.lang] ?? '');
        return {
          name: (L.dashboard as any)[name] ?? name,
          href: link.href,
          description: '', // Unused
        }
      }));

      setLinksLoaded(true);
    })();
  }, [context.lang]);

  if (location.pathname === "/login") {
    return (
      <>
        {configuration.announcement && <Alert className="m-0"
          variant={configuration.announcement_type}>{configuration.announcement}</Alert>}
        <Navbar bg="light" className="sticky top-0" expand="lg">
          <Navbar.Brand>
            {applicantFacingLogo ? (
              <img
                className="w-auto max-w-[150px] max-h-[125px]"
                src={applicantFacingLogo}
                width={applicantFacingLogoWidth}
                alt={programName}
              />
            ) : (
              <AidKitLogo width={100} height={50} />
            )}
            {context.activeRequests.size > 0 && <div className="loader"></div>}
          </Navbar.Brand>
          <Nav className="flex flex-end">
            <LanguageDropdown languages={(configuration.languages || 'en,es').split(',')} />
          </Nav>
        </Navbar>
      </>
    );
  }

  return (
    <>{configuration.announcement && <Alert className="m-0" variant={configuration.announcement_type}>{configuration.announcement}</Alert>}
      <Disclosure as={"nav"} className={`sticky bg-light top-0 ${(flyOverHovering.current || languageHovering.current || flyOverOpen || languageDropdownOpen.current) ? 'z-20' : 'z-10'}`}>
        {({ open }) => (
          <>
            <div className={`max-w-full mx-auto px-4 sm:px-6 lg:px-8`}>
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <div className="hidden sm:block">
                      {applicantFacingLogo ? (
                        <img
                          src={applicantFacingLogo}
                          width={applicantFacingLogoWidth}
                          alt={programName}
                        />
                      ) : (
                        <>
                          <div className="hidden sm:block">
                            <AidKitLogo width={100} height={40} />
                          </div>
                          <div className="block sm:hidden">
                            <MiniAidKitLogo width={50} height={40} />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className="spinner"
                      style={{
                        visibility:
                          context.activeRequests.size === 0 ? "hidden" : "visible",
                      }}
                    >
                      <div className="rect1"></div>&nbsp;
                      <div className="rect2"></div>&nbsp;
                      <div className="rect3"></div>&nbsp;
                      <div className="rect4"></div>&nbsp;
                      <div className="rect5"></div>
                    </div>
                  </div>
                  <div className="hidden md:ml-4 md:flex md:space-x-4">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    {(showDashboardsMenu && !useNewDashboards && dashboardItems) && (
                      <div className="inline-flex items-center align-middle text-md font-medium">
                        <FlyoverMenu
                          className={`${window.location.pathname.indexOf('dashboard') !== -1 ? 'border-blue-200' : 'border-transparent'} 
                          align-middle inline-flex border-solid border-t-0 border-l-0 
                          border-r-0 border-b-2 text-md font-medium my-auto px-1`}
                          items={dashboardItems.filter(item => item.href.includes('dashboard'))}
                          label={L.menu.dashboards}
                          onPointerEnter={() => flyOverHovering.current = true}
                          onPointerLeave={() => flyOverHovering.current = false}
                          withOpen={(open: boolean) => {
                            if (flyOverOpen !== open) {
                              setFlyOverOpen(open);
                            }
                          }}
                          onItemClick={() => { }} />
                      </div>
                    )}
                    {useNewDashboards &&
                      <Disclosure.Button as={Link}
                        className={`border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex 
                        items-center px-1 border-b-2 border-t-0 border-l-0 border-r-0 border-solid text-md font-medium`}
                        key={'dashboards'} to={'/'}
                        onClick={() => { }}>{L.menu.dashboards}</Disclosure.Button>
                    }
                    {(configuration.roles && linksLoaded && dashboardItems.length) &&
                      dashboardItems.map((item) => {
                        if ((showDashboardsMenu || useNewDashboards) && item.href.includes("dashboard")) {
                          return null;
                        }
                        return <Disclosure.Button as={Link}
                          className={`
                          ${window.location.pathname === item.href ? 'border-indigo-500 text-gray-900' :
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex 
                            items-center px-1 
                            border-b-2 border-t-0 border-l-0 border-r-0 border-solid text-md font-medium`}
                          key={item.href} to={item.href}
                          onClick={() => { }}>{item.name}</Disclosure.Button>;
                      })}
                  </div>
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center">
                  <div className={`my-auto ${open ? 'fit-content' : ''}`}>
                    {twilioDevice && twilioDevice.state === 'registered' && (
                      <IncomingCallReceiver />
                    )}
                  </div>
                  <div className={`my-auto ${open ? 'fit-content' : ''}`}>
                    {searchAllowed && <SearchBar />}
                  </div>
                  <div onPointerEnter={() => languageHovering.current = true}
                    onPointerLeave={() => languageHovering.current = false}>
                    <LanguageDropdown
                      onPointerEnter={() => languageHovering.current = true}
                      onPointerLeave={() => languageHovering.current = false}
                      withOpen={(open: boolean) => {
                        if (languageDropdownOpen.current !== open) languageDropdownOpen.current = open;
                      }}
                      languages={(configuration.languages || 'en,es').split(',')} />
                  </div>
                  {configuration.user && (
                    <Disclosure.Button as={Link} className="ml-2 text-gray-900"
                      key={'logout'} to={'/logout'}>{L.menu.logout}</Disclosure.Button>
                  )}
                </div>
                <div className="-mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <div className={`my-auto ${open ? 'fit-content' : ''}`}>
                    {twilioDevice && twilioDevice.state === 'registered' && (
                      <IncomingCallReceiver />
                    )}
                  </div>
                  <div className={`my-auto ${open ? 'fit-content' : ''}`}>
                    {((configuration.roles || '').indexOf('admin') !== -1 || (configuration.roles || '').indexOf('lookup') !== -1) && <SearchBar />}
                  </div>
                  <LanguageDropdown
                    onPointerEnter={() => languageHovering.current = true}
                    onPointerLeave={() => languageHovering.current = false}
                    withOpen={(open: boolean) => {
                      if (languageDropdownOpen.current !== open) languageDropdownOpen.current = open;
                    }}
                    languages={(configuration.languages || 'en,es').split(',')} />

                  <Disclosure.Button className="inline-flex bg-transparent border-0 items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="pl-2 pt-2 pb-3 space-y-1 flex flex-column">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", 
              Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                {showDashboardsMenu && dashboardItems && (
                  <FlyoverMenu
                    className="pt-1 border-b-2 text-md font-medium my-auto px-1"
                    items={dashboardItems.filter(item => item.href.includes('dashboard'))}
                    withOpen={(open: boolean) => {
                      setFlyOverOpen(open);
                    }}
                    label={L.menu.dashboards}
                    onItemClick={() => { }} />
                )}
                {(configuration.roles && linksLoaded && dashboardItems.length) &&
                  dashboardItems.map((item) => {
                    if ((showDashboardsMenu || useNewDashboards) && item.href.includes("dashboard")) {
                      return null;
                    }
                    return <Disclosure.Button as={Link}
                      className={`
                      ${window.location.pathname === item.href ? 'border-indigo-500 text-gray-900' :
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-md font-medium`}
                      key={item.href} to={item.href}
                      onClick={() => { }}>{item.name}</Disclosure.Button>
                  })}
              </div>
              <div className="pl-2 pt-2 pb-2 border-t border-gray-200">
                {configuration.user && (
                  <Disclosure.Button
                    as={Link}
                    to={'/logout'}
                    key={'logout'}
                    className="block px-1 py-2 text-base 
                    font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    {L.menu.logout}
                  </Disclosure.Button>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

function SkinnyPage(props: any) {
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);

  const programName = publicConfig.name || config.program_name || 'AidKit Program';
  const L = useLocalizedStrings();

  return (
    <AppConfiguration>
      <div className="mx-auto" style={{ maxWidth: "600px", padding: "10px" }}>
        {(config.show_brand_logo_subsurvey || config.program_logo_subsurvey) && (
          <div className="d-flex justify-content-between">
            <div>
              {config.program_logo_subsurvey && <img height="50px" style={{ maxWidth: "150px" }} src={'/' + config.program_logo_subsurvey} alt={programName} />}
            </div>
            <div>
              {config.show_brand_logo_subsurvey && <AidKitLogo height={50} width={100} />}
            </div>
          </div>
        )}
        <br /><br />
        <Form.Control as="select"
          defaultValue={context.lang}
          onChange={(e) => context.setLanguage(e.target.value as SupportedLanguage)} style={{ width: '200px' }}>
          <option value="en">{L.english}</option>
          <option value="es">{L.spanish}</option>
        </Form.Control>
        {props.children}
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      </div>
    </AppConfiguration>
  );
}

function TailwindPage(props: any) {
  return (
    <DatabaselessAppConfiguration>
      {props.children}
    </DatabaselessAppConfiguration>
  );
}

function ThickerPage(props: any) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();

  return (
    <div className="mx-auto" style={{ maxWidth: "800px", padding: "10px" }}>
      <br /><br />
      <Form.Control as="select"
        defaultValue={context.lang}
        onChange={(e) => context.setLanguage(e.target.value as SupportedLanguage)} style={{ width: '200px' }}>
        <option value="en">{L.english}</option>
        <option value="es">{L.spanish}</option>
      </Form.Control>
      {props.children}
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
    </div>
  );
}

function Redirect() {
  const location = useLocation();
  const [cookies] = useToken() as any;
  const L = useLocalizedStrings();

  const path =
    "https://api.aidkit.cloud" +
    location.pathname.slice("/redirect".length) +
    location.search +
    "&auth=" +
    cookies["auth_token"];
  useEffect(() => {
    console.log("Pushing", path);
    (window as any).location = path;
  }, [path]);
  return <div>{L.redirecting} {path}</div>;
}

function ShortLink() {
  const location = useLocation();
  const { toast } = useToast();
  let token = location.pathname.slice("/s/".length).replace('.', '');
  token = (token.replace('.', '').replace(',', '').match(/[-_a-zA-Z0-9]+/) || [''])[0]

  // console.log("Small token: " + smalltoken);
  const tokenProps = useAPI('/shortlink?token=' + token);
  // console.log(tokenProps);
  const history = useHistory();

  const L = useLocalizedStrings();

  useEffect(() => {
    if (tokenProps['redirect_url']) {
      (window as any).location = tokenProps['redirect_url'];
    }
  }, [tokenProps]);

  if (tokenProps['error']) {
    toast({
      description: L.dashboard.that_page_does_not_exist,
      variant: 'error'
    })
    history.push("/login");
  }

  return <div>{L.redirecting} {tokenProps['redirect_url']}</div>;
}

function DynamoShortLink() {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const { toast } = useToast();
  const { slug } = useParams<{ slug: string }>() as Record<string, string>;
  const resolve = usePost('/link/resolve');
  const getChallenge = usePost('/link/challenge') as any;
  const [needsChallenge, setNeedsChallenge] = useState(false);

  const [verificationSent, setVerificationSent] = useState(false);
  const [sendingVerification, setSendingVerification] = useState(false);
  const [challengeToken, setChallengeToken] = useState('');
  const [challengeMethod, setChallengeMethod] = useState(null as null | 'sms' | 'email');
  const [challengeAnswer, setChallengeAnswer] = useState('');
  const [challenges, setChallenges] = useState([] as Challenge[]);
  const [displayChallenges, setDisplayChallenges] = useState([] as { challenge: Challenge, showFailureMessage: boolean }[])
  const [loginDescription, setLoginDescription] = useState<RichText>({ en: '' });
  const [challengeResponses, setChallengeResponses] = useState({} as Record<string, string>);
  const [completingChallenge, setCompletingChallenge] = useState(false);

  // Resolve the link and if we have a challenge required, ask show the user the details
  useEffect(() => {
    (async () => {
      const result = await resolve({ slug: (slug.replace('.', '').replace(',', '').match(/[a-zA-Z0-9]+/) || [''])[0] }) as any;
      if (result.challenge) {
        setNeedsChallenge(true);
        setChallenges(result.challenges);
        setDisplayChallenges(result.challenges.map((c: Challenge) => { return { challenge: c, showFailureMessage: false } }));
        setLoginDescription(result.description);
      } else {
        if (result.url) {
          saveKeyToCookieAndRelocateToUrl(result.url, { removeHistory: true });
        } else {
          toast({
            description: L.dashboard.that_page_does_not_exist,
            variant: 'error'
          })
        }
      }
    })();
  }, []);

  async function requestChallenge() {
    setSendingVerification(true);
    const resp = await getChallenge({ slug: (slug.replace('.', '').replace(',', '').match(/[a-zA-Z0-9]+/) || [''])[0] });
    setSendingVerification(false);
    setVerificationSent(true);
    if (resp.token) {
      setChallengeToken(resp.token);
      setChallengeMethod(resp.method);
    } else {
      setVerificationSent(false);
    }
  }

  async function completeChallenge() {
    setCompletingChallenge(true);
    const res = await resolve(
      {
        slug: (slug.replace('.', '').replace(',', '').match(/[a-zA-Z0-9]+/) || [''])[0],
        challenge: {
          contactChallenge: {
            token: challengeToken,
            answer: challengeAnswer,
          },
          infoChallenges: challengeResponses
        }
      });
    setCompletingChallenge(false);
    if ((res as any).results) {
      setDisplayChallenges((res as any).results);
    } else if ((res as any).url) {
      saveKeyToCookieAndRelocateToUrl((res as any).url, { removeHistory: true });
    }
  }

  const needsContactChallenge = challenges.filter(c => c.kind === 'contact').length > 0;

  if (needsChallenge) {
    return <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm text-md">
        <div className="text-center">
          <AidKitLogo width={300} height={120} />
        </div>
        <p className="text-lg">
          {loginDescription[context.lang] || loginDescription['en']}
        </p>
        <p className="text-sm">{context.lang && context.lang !== 'en' ? `(${loginDescription['en']})` : null}</p>
        {
          (displayChallenges.filter(c => c.challenge.kind === 'info') as { challenge: InfoChallenge, showFailureMessage: boolean }[]).map(({ challenge, showFailureMessage }, i) => {
            return <>
              {challenge.fieldType !== 'date' ? <label htmlFor={challenge.fieldRef} className="block text-sm font-medium leading-6 text-gray-900">
                {challenge.description?.[context.lang] || challenge.description?.['en'] || snakeToEnglish(challenge.fieldRef)}
              </label> : null}
              <div className="mt-2 mb-2">
                {challenge.fieldType === 'date' ?
                  <DateInput key={challenge.fieldRef}
                    //value={challengeResponses[challenge.fieldRef] ?? ''}
                    label={challenge.description?.[context.lang] || challenge.description?.['en'] || snakeToEnglish(challenge.fieldRef)}
                    setValue={(val) => setChallengeResponses((responses) => ({ ...responses, [challenge.fieldRef]: val }))} />
                  : <input
                    id={challenge.fieldRef}
                    name={challenge.fieldRef}
                    type={challenge.fieldType || 'text'}
                    required
                    onChange={(e) => setChallengeResponses((responses) => ({ ...responses, [challenge.fieldRef]: e.target.value }))}
                    value={challengeResponses[challenge.fieldRef] || ''}
                    className="block w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />}
                {showFailureMessage && <span key={challenge.fieldRef} className="block text-sm font-medium leading-6 text-red-500">
                  {challenge.failureMessage?.[context.lang] || challenge.failureMessage?.['en'] || 'Your answer was incorrect'}
                </span>}
              </div>
            </>
          })
        }

        {needsContactChallenge ?
          (verificationSent && challengeMethod ?
            <div>
              <label htmlFor="code" className="block text-md font-medium leading-6 text-gray-900">
                {L.login.verification_code}
                <p className="text-md">{challengeMethod === 'email'
                  ? L.questions.contact_confirmation.please_enter_code_from_email
                  : L.questions.contact_confirmation.please_enter_code_from_sms}</p>
              </label>
              <div className="mt-2">
                <input
                  id="code"
                  name="code"
                  required
                  onChange={(e) => setChallengeAnswer(e.target.value)}
                  value={challengeAnswer}
                  className="block w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              <button
                onClick={completeChallenge}
                className="border-0 mt-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              ><span>{completingChallenge ? <SpacedSpinner /> : null}</span>
                {L.login.submit_verification}
              </button>
            </div>
            :
            <button
              onClick={requestChallenge}
              disabled={sendingVerification}
              className="border-0 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            ><span>{sendingVerification ? <SpacedSpinner /> : null}</span>
              {L.login.send_verification_code}
            </button>
          ) :
          <button
            onClick={completeChallenge}
            className="border-0 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          ><span>{completingChallenge ? <SpacedSpinner /> : null}</span>
            {L.login.submit_verification}
          </button>
        }

      </div>
    </div>
  }

  return <div>{L.redirecting}</div>;
}

function Logout() {
  const [, , removeCookie] = useCookies(["auth_token"]);
  const location = useLocation()
  const wasIdle = location.search.includes("&reason=idle")

  // Handles logging a user out that was signed in using session-based auth
  // This endpoint will remove their session from dynamo
  // Probaby want to clean this up once we switch to session-based auth
  const signout = usePost("/signout")

  useEffect(() => {
    const handleLogout = async () => {
      const result = await signout({userInactivity: wasIdle});
    }

    handleLogout().finally(() => {
      removeCookie('auth_token', { path: '/' });
      removeCookie('auth_token');
    });

    Sentry.getCurrentScope().setUser(null);
  }, []);

  const redirectPath = "/login" + location.search;

  return <ReactRouterRedirect to={redirectPath} />;
}

// Sometimes we want users to be redirected to the last page they were on before they're logged out.
// Helper function to help generate this part of the URL.
// We use RegEx here to make sure we remove everything but the path.
function generateNextForReroute() {
  return encodeURIComponent(location.href.replace(new RegExp(`^${location.origin}`), ''))
}

// Ensures that an admin for a specific program will be logged out if they're determined to be idle
// Timing is set in the program config
// This component is injected into various admin routes
function AdminTimeout(props: {}) {
  const publicConfig = useContext(PublicConfigurationContext)
  const timeoutConfig = publicConfig.logOutInactiveUser
  const history = useHistory();

  if (timeoutConfig?.timeout && timeoutConfig?.promptBeforeIdle) {
    return (
      <UserInactivityTimeout
        timeout={timeoutConfig.timeout}
        promptBeforeIdle={timeoutConfig.promptBeforeIdle}
        onIdleCallback={() => { history.push(`/logout?next=${generateNextForReroute()}&reason=idle`) }}
      />
    )
  }

  return null;
}

// Ensures an auth_token has been set (suggesting the user is logged in)
// If not, break out early and redirect to login.
//
// If <LoggedInGuard> is not used, any API calls that are made when the page component mounts/loads 
// will still ensure the user is properly authenticated. If the user is not authenticated, 
// the user will still be redirected to /login.
// Using <LoggedInGuard> prevents us from even mounting the child page component and prevents us
// from triggering unnecessary logic (like API calls that are made on page component load).
// Note that this does not check roles and privileges (that's <Guard>), this is just whether 
// they are authenticated.
//
// Wrap <LoggedInGuard> in your route definition around your page component if you want to 
// immediately redirect users to /login.
function LoggedInGuard(props: { children: any }) {
  const [_, authToken] = useToken();

  // If we're using LoggedInGuard for the homepage, don't add an unnecessary next redirect.
  // If using LoggedInGuard around a specific route, we'll redirect to that page after successful authentication.
  const nextPath = (location.pathname === "/" && !location.hash) ? "" : `?next=${generateNextForReroute()}`;

  return authToken ? props.children : <ReactRouterRedirect to={"/login" + nextPath} />;
}

// Ensures the current user's context has a sufficient role by comparing 
// the context's roles with props.auth (the required role name)
function Guard(props: { children: any, auth: string | string[] }) {
  const config = useContext(ConfigurationContext);
  const history = useHistory();
  const location = useLocation();

  const authed = (config: Record<string, any>, auth: string | string[]) => {
    if (typeof auth === 'object' && Array.isArray(auth) && auth.some(a => (config.roles || []).includes(a))) return true;
    if (typeof auth === 'string' && (config.roles || []).includes(auth)) return true;
    return false;
  }

  useEffect(() => {
    if (!config || Object.keys(config).length === 0 || config.error) return;
    if (!authed(config, props.auth)) {
      history.push("/");
    }
  }, [config])
  return (
    <LoggedInGuard>
      <AdminTimeout />
      {authed(config, props.auth) && props.children}
    </LoggedInGuard>
  );
}

function useTwilioDevice(props: any) {
  const getToken = usePost('/voice/token');

  const [twilioDevice, setTwilioDevice] = useState<Device | null>(null);
  const [twilioCall, setTwilioCall] = useState<Call | null>(null);
  const [twilioCaller, setTwilioCaller] = useState(null as string | null);

  const config = useContext(ConfigurationContext);
  const [gettingDevice, setGettingDevice] = useState(false);

  const { toast } = useToast();

  const [channel, setVoiceChannel] = useState("default");
  const [ready, setReady] = useState(false);

  const updateDeviceToken = async (twilioDevice: Device, channel: string) => {
    try {
      const token = await getToken({ channel });
      twilioDevice.updateToken(token?.token || '');
      setVoiceChannel(token.channel);
    } catch (e) {
      console.log(e);
      captureEvent({
        message: 'Failed to update Twilio token',
        extra: { e, channel }
      })
    }
  }

  useEffect(() => {
    if (!twilioDevice) return;

    twilioDevice.on('registered', device => {
      console.log("Registered and ready to receive calls.", device);
    });
    twilioDevice.on('ready', device => {
      setReady(true);
    })
    twilioDevice.on('offline', device => {
      setReady(false);
    })
    twilioDevice.on('tokenWillExpire', async () => {
      await updateDeviceToken(twilioDevice, channel);
    });
    twilioDevice.on('error', async (twilioError, call) => {
      console.log('An error has occurred: ', twilioError);
      switch (twilioError.code) {
        case 20104:
          // Access code expired
          await updateDeviceToken(twilioDevice, channel);
          break;
        case 31005:
          // Connection error
          toast({
            description: "Connection error",
            variant: 'error'
          })
          captureEvent({
            message: 'Twilio connection error',
            extra: {
              twilioError,
              call
            }
          })
          break;
        default:
          captureEvent({
            message: 'Twilio error',
            extra: {
              twilioError,
              call
            }
          });
      }
    });
    try {
      console.log(twilioDevice.state, "<- will register if this is not a registering state");
      if (['registered', 'registering'].indexOf(twilioDevice.state) === -1) {
        (async () => {
          await twilioDevice.register();
        })();
      }

    } catch (e) {
      console.warn("Failed to register device: ", e);
    }

    return () => {
      if (twilioDevice) {
        twilioDevice.disconnectAll();
      }
    }

  }, [twilioDevice, channel]);

  useEffect(() => {    
    if (twilioDevice || gettingDevice) return;
    (async () => {
      setGettingDevice(true);
      try {
        const resp = await getToken({ channel });
        if ((resp as any).error) return;
        const device = new Device(resp?.token || '', {
          logLevel: 'trace',
        });
        setVoiceChannel(resp.channel);
        setTwilioDevice(device);
        if (resp?.identity) {
          setTwilioCaller(resp.identity);
        }
      } catch (e) {
        console.log(e);
      }
      setGettingDevice(false);
    })();
  }, [config])

  return {
    twilioDevice, twilioCall, twilioCaller, setTwilioCall,
    twilioVoiceChannel: channel, resetTwilioVoiceChannel: async (channel: string) => {
      const resp = await getToken({ channel, overrideChannel: true });
      if (twilioDevice) {
        twilioDevice.updateToken(resp?.token || '');
      } else {
        const device = new Device(resp?.token || '', {
          logLevel: 'trace',
        });
        setTwilioDevice(device);
      }
      if (resp?.identity) {
        setTwilioCaller(resp.identity);
      }
      setVoiceChannel(resp.channel);
    }
  };
}

function TwilioDevice(props: any) {

  const { twilioDevice, twilioCall, twilioCaller, setTwilioCall, twilioVoiceChannel, resetTwilioVoiceChannel } = useTwilioDevice(props);

  return <TwilioDeviceContext.Provider value={{
    twilioDevice,
    twilioCall,
    twilioCaller,
    setTwilioCall,
    twilioVoiceChannel,
    resetTwilioVoiceChannel
  }}>
    {props.children}
  </TwilioDeviceContext.Provider>
}

function formatBanner(banner: Required<PublicConfig['interface']['applicantBanner']>) {
  let content = banner!.content as Record<string, string>;
  switch (banner!.color) {
    case 'Red':
      content['__style'] = "bg-red-50 text-red-700";
      break;
    case 'Blue':
      content['__style'] = "bg-blue-50 text-blue-700";
      break;
    case 'Yellow':
      content['__style'] = "bg-yellow-50 text-yellow-700";
      break;
  }
  return content
}

function AppConfiguration(props: any) {
  const getLegacyConfiguration = usePost("/program/legacy_configuration");
  const getAuthAwareConfig = usePost("/program/public_configuration");
  const getLoggedInConfig = usePost("/program/logged_in_configuration");

  const context = useContext(InterfaceContext);
  const [, token] = useToken();
  const [publicConfig, setPublicConfig] = useState<PublicConfig>({} as PublicConfig);
  const [legacyConfig, setLegacyConfig] = useState<Record<string, any>>({});
  const [loggedInConfig, setLoggedInConfig] = useState<LoggedInConfig>({} as LoggedInConfig);
  const online = useOnlineDetection(publicConfig);
  const sync = useOfflineSync(publicConfig);

  useEffect(() => {
    (async () => {
      const publicConfig = await getAuthAwareConfig({});
      setPublicConfig(publicConfig);

      const loggedInConfig = await getLoggedInConfig({});
      setLoggedInConfig(loggedInConfig);

      const legacyConfig = await getLegacyConfiguration({});
      setLegacyConfig(legacyConfig);
      document.title = publicConfig?.name || legacyConfig?.program_name || 'AidKit Program';
      if (publicConfig?.interface?.applicantBanner) {
        context.setBanner(formatBanner(publicConfig.interface.applicantBanner));
      }
      if (publicConfig?.interface?.staffBanner) {
        context.setStaffBanner(formatBanner(publicConfig.interface.staffBanner));
      }
      if (publicConfig?.interface?.loginBanner) {
        const banner = publicConfig.interface.loginBanner.content as Record<string, string>;
        banner['color'] = publicConfig.interface.loginBanner.color;
        context.setLoginBanner(publicConfig.interface.loginBanner.content as Record<string, string>);
      }

      if (legacyConfig?.user?.uid) {
        Sentry.setUser({ id: legacyConfig.user.uid })
        const deploymentName = get_deployment();
        Sentry.setContext('deployment', { 'name': deploymentName });
        Sentry.setTag("deployment_name", deploymentName);
      }
    })();
  }, [token]);

  return (
    <ConfigurationContext.Provider value={legacyConfig}>
      <PublicConfigurationContext.Provider value={publicConfig}>
        <LoggedInConfigurationContext.Provider value={loggedInConfig}>
          <OnlineAwarenessContext.Provider value={online}>
            <OfflineSyncContext.Provider value={sync}>
              <CookieBanner />
              {props.children}
              <OfflineBanner />
            </OfflineSyncContext.Provider>
          </OnlineAwarenessContext.Provider>
        </LoggedInConfigurationContext.Provider>
      </PublicConfigurationContext.Provider>
    </ConfigurationContext.Provider>
  );
}

// Test whether cookies are enabled and display a warning banner if they are not.
function CookieBanner() {
  const publicConfig = useContext(PublicConfigurationContext);
  const context = useContext(InterfaceContext);
  const [cookiesDisabled, setCookiesDisabled] = useState(false);
  const L = useLocalizedStrings();

  useEffect(() => {
    // Don't run until we've loaded public config to avoid flicker when disabled.
    if (Object.keys(publicConfig ?? {}).length > 0) {
      // Skip if disabled.
      if (publicConfig?.interface?.cookieBanner?.disable) {
        setCookiesDisabled(false);
        return;
      };

      const testCookie = '__ak_test_cookie2=1';
      document.cookie = `${testCookie}; path=/`;

      if (document.cookie.indexOf(testCookie) === -1) {
        setCookiesDisabled(true);
      } else {
        // If we successfully saved the test cookie, remove it
        document.cookie = '__ak_test_cookie2=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
  }, [publicConfig]);

  if (cookiesDisabled) {
    return (
      <div className='bg-[var(--ak-color-brand-red-200)] p-4'>
        <span>{ publicConfig?.interface?.cookieBanner?.content?.[context.lang ?? 'en'] || L.ahh.cookies_disabled }</span>
      </div>
    )
  }
  return null;
}

function DatabaselessAppConfiguration(props: any) {
  const config = useAPI("api://program/configuration?program=" + get_deployment());
  const publicConfig: PublicConfig = useAPI("api://program/public_configuration?program=" + get_deployment());
  const context = useContext(InterfaceContext);
  const online = useOnlineDetection(publicConfig);
  const [prevOfflineStatus, setPrevOfflineStatus] = useState(online.status);
  const L = useLocalizedStrings();
  const userInfo = usePost("/user/info");
  const sync = useOfflineSync(publicConfig);
  const { toast } = useToast();

  useEffect(() => {
    document.title = publicConfig?.name || config?.program_name || 'AidKit Program';
    if (publicConfig?.interface?.applicantBanner) {
      context.setBanner(formatBanner(publicConfig.interface.applicantBanner));
    }
    if (publicConfig?.interface?.staffBanner) {
      context.setStaffBanner(formatBanner(publicConfig.interface.staffBanner));
    }
    if (publicConfig?.interface?.loginBanner) {
      const banner = publicConfig.interface.loginBanner.content as Record<string, string>;
      banner['color'] = publicConfig.interface.loginBanner.color;
      context.setLoginBanner(publicConfig.interface.loginBanner.content as Record<string, string>);
    }

    if (config.banner) {
      context.setBanner(safeParse(config.banner))
    }
    if (config.user?.uid) {
      Sentry.setUser({ id: config.user.uid })
      const deploymentName = get_deployment();
      Sentry.setContext('deployment', { 'name': deploymentName });
      Sentry.setTag("deployment_name", deploymentName);
    }
  }, [config, publicConfig]);

  useEffect(() => {
    const enableOfflineMode = publicConfig.experimental?.enableOfflineMode;
    // publicConfig starts out empty, then gets populated. 
    // Therefore, undefined indicates that it has not been loaded yet,
    // which we want to skip over entirely
    if (Object.keys(publicConfig || {}).length > 0) {
      if (enableOfflineMode) {
        // offline mode enabled here
        serviceWorkerRegister.register({
          handleToast: (payload) => toast(payload)
        });
      } else {
        serviceWorkerRegister.unregister();
      }
    }
  }, [publicConfig]);

  useEffect(() => {

    if (!publicConfig.experimental?.enableOfflineMode) {
      return;
    }

    if (prevOfflineStatus === online.status) {
      return;
    }

    // without this, Offline Synced will appear
    // the first time a warm up happens again.
    // This is because the useOfflineDetection() hook
    // gets reset due to the page re-navigating.
    setPrevOfflineStatus(online.status);

    if (online.status === 'synced') {
      toast({
        id: 'offlineSynced',
        description: L.offline.offline_sync_done,
        autoClose: false,
        variant: 'success'
      })
    }
    else if (online.status === 'warmed') {
      toast({
        id: 'readyForOffline',
        description: L.offline.offline_warmup_done,
        autoClose: false,
        variant: 'success'
      })
    }
    else if (online.status === 'failed') {
      toast({
        id: 'offlineWarmupFailed',
        variant: 'error',
        description: L.offline.offline_warmup_failed,
        autoClose: false,
        action: <AidKitButton onClick={
          async () => {
            await serviceWorkerRegister.unregister();
            window.location.reload();
          }
        }>{L.tap_to_reload}</AidKitButton>
      })
    }

  }, [publicConfig, online.status]);


  return <>
    <ConfigurationContext.Provider value={config} >
      <PublicConfigurationContext.Provider value={publicConfig}>
        <OnlineAwarenessContext.Provider value={online}>
          <OfflineSyncContext.Provider value={sync}>
            <CookieBanner />
            {props.children}
            <OfflineBanner />
          </OfflineSyncContext.Provider>
        </OnlineAwarenessContext.Provider>
      </PublicConfigurationContext.Provider>
    </ConfigurationContext.Provider>
  </>;
}

function ErrorBoundaryWrapper(props: any) {
  const saveException = usePost('/save_exception');
  return <ErrorBoundary {...props} saveException={saveException} />;
}

type ErrorBoundryProps = {
  lang: SupportedLanguage,
  children: React.ReactNode,
  saveException: ({
    browserTrace,
    trace
  }: {
    browserTrace: string,
    trace: string
  }) => Promise<any>
}

type ErrorBoundyState = {
  hasError: boolean
  error?: any
  info?: any
}

class ErrorBoundary extends React.Component<ErrorBoundryProps, ErrorBoundyState> {
  constructor(props: ErrorBoundryProps) {
    super(props);
    this.state = { hasError: false } as any;
  }

  async componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    this.setState({ info: info });
    this.setState({ error: error });
    Sentry.showReportDialog({ dsn: sentryDSN });
    Sentry.captureException(error, { extra: info });

    await this.props.saveException({
      browserTrace: error.toString() + '\n' + error.stack,
      trace: '' + error.name + ': ' + error.message + info.componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      return <div className="bg-indigo-700" style={{ minHeight: '100vh' }}>
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">&nbsp;{LANG_DICTS[this.props.lang].something_went_wrong}</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-white font-bold">- Your friendly neighborhood <span className="text-red-500 text-2xl">AidKit Team</span></p>
          <img src="/programmerAtComputer.svg" alt="picture of a programmer at a computer"></img>
        </div>
      </div>
    }
    return this.props.children;
  }
}

function BrokenPage() {
  (window as any).any();
  return <div></div>;
}

function SupportPage(props: any) {
  const publicConfig = useContext(PublicConfigurationContext);

  if (Object.keys(publicConfig).length === 0) return <></>

  if (interfaceNumber(publicConfig.interface?.version) > 0) {
    return <ThreeColumnPage title="Support"
      main={<SupportPageInner {...props} />} />
  }

  return <>
    <Menu />
    <SupportPageInner {...props} />
    <Footer />
  </>
}

export function SupportCasePageSwitcher(props: any) {
  const config = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);

  if (Object.keys(publicConfig).length === 0 || Object.keys(config).length === 0) return <></>

  if (interfaceNumber(publicConfig?.interface?.version) > 0) {
    return <ThreeColumnPage
      title="Support Case"
      main={<SupportCasePage {...props} />}
    />
  }

  return <>
    <Menu />
    <SupportCasePage {...props} />
  </>
}

function UserInfoWrapper(props: any) {
  const userInfo = usePost("/user/info");
  const [user, setUser] = useState<ContextType<typeof UserInfoContext>>({});
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      setUser(await userInfo({}));
    })();
  }, [refresh]);

  // refreshContext is a callback passed along via the UserInfoContext to allow the context to be forced to refresh
  const value = useMemo(() => ({ ...user, refreshContext: () => setRefresh((prevState) => prevState + 1) }), [user]);

  return (<UserInfoContext.Provider value={value}>
    {props.children}
  </UserInfoContext.Provider>);
}

function App() {
  const [activeRequests, setActiveRequests] = useState<Set<string>>(new Set());
  const L = useLocalizedStrings();

  const sessionStorage = useStorage('session');
  const localStorage = useStorage('local');

  // Redirect .cloud and .
  if (window.location.hostname.endsWith('.') || (
    !specialHostnameDeployments[window.location.hostname]
    && window.location.hostname.indexOf('.cloud') !== -1
  )) {
    window.location.hostname = window.location.hostname.replace('.cloud', '.org').replace(/\.$/, '');
  }

  // Redirect any *.[program].aidkit.org or *.[program].dev.aidkit.com to [program].[rest]
  const hostnameParts = window.location.hostname.split('.');
  const devIndex = window.location.hostname.includes('dev.aidkit.com') ? 1 : 0;

  // Remove all subdomains before the program name
  if (hostnameParts.length > (3 + devIndex) && hostnameParts[hostnameParts.length - 2] === 'aidkit') {
    for (let idx = 0; idx < hostnameParts.length - 3; idx++) {
      hostnameParts.shift();
    }
    window.location.hostname = hostnameParts.join('.');
  }

  const setRequestActive = useCallback((req: string, active: boolean) => {
    if (["/config", "/explore", "/copy_editor"].includes(window.location.pathname)) return; // don't use this in distro
    if (active) {
      setActiveRequests((prevValue) => {
        const next = new Set(prevValue);
        next.add(req);
        return next;
      });
      return;
    }
    if (!active) {
      setActiveRequests((prevValue) => {
        const next = new Set(prevValue);
        next.delete(req);
        return next;
      });
      return;
    }
  }, [setActiveRequests]);

  const params = new URLSearchParams(window.location.search);
  const paramsLang = params.get("lang");

  const [cookies, setCookie] = useCookies(['lang']);

  let allowedLinkLang = '';
  if (paramsLang !== null && paramsLang !== undefined && VALID_LANGS.includes(paramsLang)) {
    allowedLinkLang = paramsLang;
    setCookie('lang', paramsLang);
  }

  const [lang, setLanguage] = useState(allowedLinkLang || cookies['lang'] || navigator?.language?.split('-').shift() || 'en');
  const [direction, setDirection] = useState('ltr' as 'ltr' | 'rtl');
  const [textAlign, setTextAlign] = useState('left' as 'left' | 'right');
  const [banner, setBanner] = useState<Record<string, string> | undefined>(undefined);
  const [staffBanner, setStaffBanner] = useState<Record<string, string> | undefined>(undefined);
  const [loginBanner, setLoginBanner] = useState<Record<string, string> | undefined>(undefined);
  const [forbiddenCopy, setForbiddenCopy] = useState(undefined as undefined | Record<string, Record<SupportedLanguage, string | undefined>>);
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement>();

  useEffect(() => {
    setCookie('lang', lang);
    moment.locale(lang);
    if (['ps_AF', 'fa_AF', 'ar', 'fa'].indexOf(lang) !== -1) {
      setDirection('rtl');
      setTextAlign('right');
      // This enables Tailwind rtl utility classes
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      setDirection('ltr');
      setTextAlign('left');
      // This enables Tailwind ltr utility classes
      document.documentElement.setAttribute('dir', 'ltr');
    }
  }, [lang, setCookie]);

  return (
    <div className="h-full" style={{ direction: direction, textAlign: textAlign }}>
      <InterfaceContext.Provider
        value={{
          activeRequests,
          setRequestActive,
          lang,
          setLanguage,
          textAlign,
          direction,
          banner,
          setBanner,
          staffBanner,
          setStaffBanner,
          loginBanner,
          setLoginBanner,
          forbiddenCopy,
          setForbiddenCopy,
          audioPlayer,
          setAudioPlayer,
          localStorage,
          sessionStorage
        }}
      >
        <Sentry.ErrorBoundary showDialog fallback={<p>{L.something_went_wrong}</p>}>
          <LocalizationProvider value={{lang}}>
            <Router history={browserHistory}>
              <UserInfoWrapper>
                <ErrorBoundaryWrapper lang={lang}>
                  <Switch>
                    <Route path="/redirect/:path" children={<Redirect />} />
                    <Route path="/s/:path" children={<ShortLink />} />
                    <Route path="/sl/:slug" children={<DynamoShortLink />} />
                    <Route path="/logout" children={<Logout />} />
                    <Route path="/launch" children={<LaunchPage />} />
                    <Route
                      path="/apply/:localid"
                      children={
                        <TailwindPage>
                          <ApplicationPage />
                        </TailwindPage>
                      }
                    />
                    <Route
                      path="/auth/google/callback"
                      children={
                        <GoogleCallback />
                      }
                    />
                    <Route
                      path="/apply"
                      children={
                        <TailwindPage>
                          <ApplicationPage />
                        </TailwindPage>
                      }
                    />
                    <Route
                      path="/o/:path"
                      children={
                        <TailwindPage>
                          <ApplicantPortalPage Viewer={'applicant'} />
                        </TailwindPage>
                      }
                    />
                    <Route
                      path="/p/:path"
                      children={
                        <TailwindPage>
                          <SubsectionPage />
                        </TailwindPage>
                      }
                    />
                    <Route
                      path="/verify"
                      children={
                        <TailwindPage>
                          <VerificationPage />
                        </TailwindPage>
                      } />
                    <Route
                      path="/d/:path"
                      children={
                        <DatabaselessAppConfiguration>
                          {/* Use params.get('key') check to ensure that this is not a publicly viewable dashboard */}
                          {!params.get("key") && <AdminTimeout />}
                          <DistroDashboard />
                        </DatabaselessAppConfiguration>
                      }
                    />
                    <Route
                      path="/explore">
                      <TailwindPage>
                        <Explore />
                      </TailwindPage>
                    </Route>
                    <Route path="/exports">
                      <AppConfiguration>
                        <Guard auth="export">
                          <Exports />
                        </Guard>
                      </AppConfiguration>
                    </Route>
                    <Route
                      path="/protected_search">
                      <TailwindPage>
                        <ProtectedSearchPage />
                      </TailwindPage>
                    </Route>
                    <Route path="/ahh/:forbiddenType">
                      <AppConfiguration>
                        <ForbiddenPage />
                      </AppConfiguration>
                    </Route>
                    <Route
                      path="/status"
                      children={
                        <TailwindPage>
                          <StatusPage />
                          <Footer />
                        </TailwindPage>
                      }
                    />
                    <Route
                      path="/ld/:livenessId"
                      children={
                        <DatabaselessAppConfiguration>
                          <LivenessDetectionPage />
                        </DatabaselessAppConfiguration>
                      } />
                    <Route
                      path="/ldebug"
                      children={
                        <DatabaselessAppConfiguration>
                          <LoggedInGuard>
                            <LivenessDebug />
                          </LoggedInGuard>
                        </DatabaselessAppConfiguration>
                      } />
                    <Route
                      path="/ua/:dynamoAppId/:subsurvey?"
                      children={
                        <AppConfiguration>
                          <TwilioDevice>
                            <v1.ApplicantPage readonly={true} />
                          </TwilioDevice>
                        </AppConfiguration>
                      } />
                    <Route
                      path="/card"
                      children={
                        <SkinnyPage>
                          <CardPage />
                          <Footer />
                        </SkinnyPage>
                      }
                    />
                    <Route
                      path="/card_setup"
                      children={
                        <SkinnyPage>
                          <VirtualCardSetup />
                          <Footer />
                        </SkinnyPage>
                      }
                    />
                    <Route
                      path="/device_setup"
                      children={
                        <SkinnyPage>
                          <DeviceSetupPage />
                          <Footer />
                        </SkinnyPage>
                      }
                    />
                    <Route
                      path="/device_code"
                      children={
                        <SkinnyPage>
                          <DeviceCodePage />
                          <Footer />
                        </SkinnyPage>
                      }
                    />
                    <Route
                      path="/applicant/:uid/subsurvey"
                      children={
                        <SkinnyPage>
                          <AdminTimeout />
                          <ApplicantPage isSubsurvey={true} />
                          <Footer />
                        </SkinnyPage>
                      }
                    />
                    <Route
                      path="/applicant/:uid/:subsurvey"
                      children={
                        <SkinnyPage>
                          <AdminTimeout />
                          <ApplicantPage />
                          <Footer />
                        </SkinnyPage>
                      }
                    />
                    <Route
                      path="/applicant/:uid"
                      children={
                        <AppConfiguration>
                          <AdminTimeout />
                          <TwilioDevice>
                            <Menu />
                            <ApplicantPage />
                            <Footer />
                          </TwilioDevice>
                        </AppConfiguration>
                      }
                    />
                    <Route
                      path="/a/:uid/:subsurvey?"
                      children={
                        <AppConfiguration>
                          <AdminTimeout />
                          <TwilioDevice>
                            <v1.ApplicantPage />
                          </TwilioDevice>
                        </AppConfiguration>
                      }
                    />
                    <Route
                      path="/ad/:uid/:applicantDash"
                      children={
                        <AppConfiguration>
                          <TwilioDevice>
                            <v1.ApplicantPage />
                          </TwilioDevice>
                        </AppConfiguration>
                      }
                    />
                    <Route
                      path="/ao/:uid/:path"
                      children={
                        <AppConfiguration>
                          <AdminTimeout />
                          <TwilioDevice>
                            <ApplicantPortalPage Viewer={'screener'} />
                          </TwilioDevice>
                        </AppConfiguration>
                      }
                    />
                    <Route
                      path="/fsti"
                      component={FullScreenTurnableImagePage}
                    />
                    <Route
                      path="/dashboard/:path"
                      children={
                        <AppConfiguration>
                          <AdminTimeout />
                          <TwilioDevice>
                            <Menu />
                            <Dashboard />
                            <Footer />
                          </TwilioDevice>
                        </AppConfiguration>
                      }
                    />
                    <Route
                      path="/dashboards/:customId"
                      children={
                        <AppConfiguration>
                          <AdminTimeout />
                          <TwilioDevice>
                            <Menu />
                            <Dashboard />
                            <Footer />
                          </TwilioDevice>
                        </AppConfiguration>
                      } />
                    <Route path="/supportrequests/:customId">
                      <AppConfiguration>
                        <AdminTimeout />
                        <TwilioDevice>
                          <SupportPage />
                        </TwilioDevice>
                      </AppConfiguration>
                    </Route>
                    <Route path="/supportrequestsv2/:channel/:customId">
                      <AppConfiguration>
                        <AdminTimeout />
                        <TwilioDevice>
                          <SupportPage />
                        </TwilioDevice>
                      </AppConfiguration>
                    </Route>
                    <Route path="/supportcase/:caseId">
                      <AppConfiguration>
                        <AdminTimeout />
                        <TwilioDevice>
                          <SurveyConfiguration path={"/survey"}>
                            <SupportCasePageSwitcher />
                          </SurveyConfiguration>
                          <Footer />
                        </TwilioDevice>
                      </AppConfiguration>
                    </Route>
                    <Route path="/supportcasev2/:channel/:caseId">
                      <AppConfiguration>
                        <AdminTimeout />
                        <TwilioDevice>
                          <SurveyConfiguration path={"/survey"}>
                            <SupportCasePageSwitcher />
                          </SurveyConfiguration>
                          <Footer />
                        </TwilioDevice>
                      </AppConfiguration>
                    </Route>
                    <Route path="/login">
                      <DatabaselessAppConfiguration>
                        <AuthPage authStrategy="user" />
                      </DatabaselessAppConfiguration>
                    </Route>
                    <Route path="/reset_password">
                      <DatabaselessAppConfiguration>
                        <ResetPasswordPage />
                      </DatabaselessAppConfiguration>
                    </Route>
                    <Route
                      path={["/meetingpublic/*"]}
                      children={
                        <DatabaselessAppConfiguration>
                          <Suspense fallback={<Loading loading />}>
                            <MeetingPage />
                          </Suspense>
                        </DatabaselessAppConfiguration>
                      } />
                    <Route
                      path={["/meeting*"]}
                      children={
                        <LoggedInGuard>
                          <DatabaselessAppConfiguration>
                            <MeetingPage />
                          </DatabaselessAppConfiguration>
                        </LoggedInGuard>
                      } />
                    <Route path="/signin">
                      <TailwindPage>
                        <AuthPage authStrategy="session" />
                      </TailwindPage>
                    </Route>
                    <Route path="/condensed_view/:source?/:survey?">
                      <LoggedInGuard>
                        <TailwindPage>
                          <ConsensedSurveyView />
                        </TailwindPage>
                      </LoggedInGuard>
                    </Route>
                    <Route path="/copy_editor">
                      <AppConfiguration>
                        <Guard auth={['admin', 'copy_edit']}>
                          <CopyEditor />
                        </Guard>
                      </AppConfiguration>
                    </Route>
                    {/* Remove this totally if no one complains
               * <Route path="/banking">
                <AppConfiguration>
                    <Menu />
                    <BankingPage />
                    <Footer />
                </AppConfiguration>
              </Route>
              <Route path="/batches">
                <AppConfiguration>
                  <Menu />
                  <BatchesPage />
                  <Footer />
                </AppConfiguration>
              </Route>
              */}
                    <Route path="/stats">
                      <AppConfiguration>
                        <Menu />
                        <StatsPage />
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    <Route path="/reports">
                      <AppConfiguration>
                        <Menu />
                        <ReportsPage />
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    <Route path="/import">
                      <AppConfiguration>
                        <Guard auth={["admin", "can_import"]}>
                          <ImportPage />
                        </Guard>
                      </AppConfiguration>
                    </Route>
                    <Route path="/datalabel">
                      <DataLabelPage />
                    </Route>
                    <Route path="/distrotask">
                      <DistroTaskPage />
                    </Route>
                    <Route path="/distro4distro">
                      <Distro4DistroPage />
                    </Route>
                    <Route path="/userimport">
                      <AppConfiguration>
                        <Guard auth="admin">
                          <UserImportPage />
                        </Guard>
                      </AppConfiguration>
                    </Route>
                    <Route path="/admin/account_settings">
                      <LoggedInGuard>
                        <AdminTimeout />
                        <AppConfiguration>
                          <AdminAccountSettings />
                        </AppConfiguration>
                      </LoggedInGuard>
                    </Route>
                    <Route path="/admin">
                      <AppConfiguration>
                        <Menu />
                        <Guard auth="admin">
                          <AdminPage />
                        </Guard>
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    <Route path="/t/scan_barcode">
                      <AppConfiguration>
                        <Menu />
                        <Guard auth="admin">
                          <ScanBarcodePage />
                        </Guard>
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    <Route path="/adminfuncs">
                      <AppConfiguration>
                        <Menu />
                        <Guard auth="admin">
                          <AdminFunctionsPage />
                        </Guard>
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    <Route path="/adminprovision">
                      <AppConfiguration>
                        <Menu />
                        <Guard auth="admin">
                          <NewProgramPage />
                        </Guard>
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    <Route path="/config/:survey">
                      <AppConfiguration>
                        <Guard auth="admin">
                          <ConfigPage />
                        </Guard>
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    {PA_PAGES.map((page) => {
                      if (page.href === '/config' || page.href === '/program-config' || page.href === '/macro-editor') {
                        return <Route path={page.href} key={page.name}>
                          <AppConfiguration>
                            <Guard auth="admin">
                              {['/config', '/macro-editor'].includes(page.href) ? <ConfigPage macroEditor={page.href === '/macro-editor'} /> : <ProgramConfigPage />}
                            </Guard>
                            <Footer />
                          </AppConfiguration>
                        </Route>
                      } else if (page.href === '/config-versions') {
                        return <Route path={page.href} key={page.name}>
                          <AppConfiguration>
                            <Guard auth="admin">
                              <ConfigVersionsPage />
                            </Guard>
                            <Footer />
                          </AppConfiguration>
                        </Route>
                      } else if (page.href === '/collab-versions') {
                        return <Route path={page.href} key={page.name}>
                          <AppConfiguration>
                            <Guard auth="admin">
                              <ConfigVersionsPage displayCollabVersion />
                            </Guard>
                            <Footer />
                          </AppConfiguration>
                        </Route>
                      } else if (page.href === '/accounting') {
                        return <Route path={page.href} key={page.name}>
                          <AppConfiguration>
                            <Guard auth={['admin', 'accountant']}>
                              <AccountingPage />
                            </Guard>
                            <Footer />
                          </AppConfiguration>
                        </Route>
                      }
                      return <Route path={page.href} key={page.name}>
                        <AppConfiguration>
                          <Guard auth="admin">
                            <Suspense fallback={<Loading loading />}>
                              <ProgramAdmin hideTitle={page.hideTitle} title={page.title || page.name}>
                                <page.component />
                              </ProgramAdmin>
                            </Suspense>
                          </Guard>
                        </AppConfiguration>
                      </Route>
                    })}
                    <Route path="/provision_comms">
                      <AppConfiguration>
                        <ProgramAdmin title={"Provision Comms"}>
                          <ProvisionPage />
                        </ProgramAdmin>
                      </AppConfiguration>
                    </Route>
                    <Route path="/compile">
                      <AppConfiguration>
                        <LoggedInGuard>
                          <ProgramAdmin title={"Compile"}>
                            <CompilePage />
                          </ProgramAdmin>
                        </LoggedInGuard>
                      </AppConfiguration>
                    </Route>
                    <Route path="/distrotest">
                      <AppConfiguration>
                        <DistroTestPage />
                      </AppConfiguration>
                    </Route>
                    <Route path="/campaigns">
                      <AppConfiguration>
                        <Guard auth="admin">
                          <ProgramAdmin title={"Campaigns"}>
                            <CampaignsPage />
                          </ProgramAdmin>
                        </Guard>
                      </AppConfiguration>
                    </Route>
                    <Route path="/screening_invoice/:payment_id">
                      <AppConfiguration>
                        <ThickerPage>
                          <ScreeningInvoicePage />
                          <Footer />
                        </ThickerPage>
                      </AppConfiguration>
                    </Route>
                    <Route path="/borked">
                      <AppConfiguration>
                        <Menu />
                        <BrokenPage />
                        <Footer />
                      </AppConfiguration>
                    </Route>
                    <Route path="/styleguide/threecolumn">
                      <ExampleThreeColumnPage />
                    </Route>
                    <Route exact path="/">
                      {(window.location.hostname.indexOf('chicagocashpilot.org') !== -1
                      || window.location.hostname.indexOf('cookcountyil.gov') !== -1
                      || window.location.hostname.indexOf('workerscomp.cloud') !== -1
                      || window.location.hostname.indexOf('brf.aidkit.org') !== -1) ?
                        <TailwindPage>
                          <ApplicationPage />
                        </TailwindPage> :
                        <DatabaselessAppConfiguration>
                          <LoggedInGuard>
                            <HomePage />
                          </LoggedInGuard>
                        </DatabaselessAppConfiguration>}
                    </Route>
                    <Route path="/site_not_found">
                      <SiteNotFound />
                    </Route>
                    <Route path="/401">
                      <AppConfiguration>
                        <Unauthorized />
                        <Footer />
                      </AppConfiguration>
                    </Route>
                  </Switch>
                  <Toaster />
                </ErrorBoundaryWrapper>
              </UserInfoWrapper>
            </Router>
          </LocalizationProvider>
        </Sentry.ErrorBoundary>
      </InterfaceContext.Provider>
    </div>
  );
}

export default App;
