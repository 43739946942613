import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useAPI, useAPIPost, useAPIUpload, usePost } from "../API";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { InfoDict, QuestionProps } from "./Props";
import { languageContent, safeParse, SpacedSpinner, isPhone } from "../Util";
import { useParams } from "react-router-dom";
import InterfaceContext, { PublicConfigurationContext } from "../Context";
import { useLocalizedStrings, useLocalizedStringsNoContext } from "../Localization";
import { ListGroup } from "react-bootstrap";
import moment from "moment";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/solid";
import { useToast } from "@aidkitorg/component-library";
import { TurnableImage } from "../Components/TurnableImage";
import * as v0 from "@aidkitorg/types/lib/survey";
import { getURLsFromCSV } from '@aidkitorg/roboscreener/lib/util/urls_from_csv'
import DismissibleAlert from "../Components/DismissibleAlert";
import { interfaceNumber } from "@aidkitorg/types/lib/survey";
import Camera from "../Components/Camera";
import slugid from 'slugid';
import { v5 } from "uuid";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import * as Sentry from "@sentry/react";
import bsCustomFileInput from "bs-custom-file-input";
import { STRINGS } from "@aidkitorg/i18n/lib";

/**
 * @name Attachment
 * @description The Common way to display and manage a URL image
 */
function Attachment(props: {
  url: string,
  key: string,
  removeURL?: (url: string) => void,
  info?: InfoDict,
  targetField?: string,
  style?: React.CSSProperties,
  turnable?: boolean,
  Viewer?: 'applicant' | 'screener',
  // Video options are at top level so you can do <Attachment playsInline muted /> for videos
  playsInline?: boolean,
  autoPlay?: boolean,
  muted?: boolean,
}) {
  const L = useLocalizedStrings();
  const [signedURL, setSignedURL] = useState('');
  const getSignedURL = usePost('/document/view');
  
  const url = props.url;
  const urllower = url.toLowerCase();
  let urlToFetch = url;
  if ((urllower.endsWith("heic")) && props.info && props.info[props.targetField + "_conversion"]) {
    urlToFetch = props.info[props.targetField + "_conversion"]!;
  }

  useEffect(() => {
    (async () => {
      if (!urlToFetch) return;
      if (urlToFetch.includes('https://aidkit-signatures')) {
        setSignedURL(urlToFetch);
        return;
      }

      const paths = await getSignedURL({
        paths: [urlToFetch],
      });
      if (paths.paths[urlToFetch]) {
        setSignedURL(paths.paths[urlToFetch]);
      }
    })();
  }, [urlToFetch]);

  const createFullScreenURL = () => {
    const params = new URLSearchParams({
      src: signedURL,
      Viewer: props.Viewer || 'applicant'
    });
    return `/fsti?${params.toString()}`;
  };
  
  const showImages = (url: string, urlToRemove: string) => {
    let ext = url.endsWith('mp4') ? 'mp4' : url.endsWith('ogg') ? 'ogg' : 'webm';
    return (
      <li style={props.style}>
        {signedURL && url?.match(/\.mp4$|\.ogg$|\.webm$/)?.[0] ?
          <video className="w-full" 
            crossOrigin="anonymous"
            onCanPlay={(e) => {
              if (props.autoPlay) e.currentTarget.play();
            }}
            controls playsInline={props.playsInline} autoPlay={props.autoPlay} muted={props.muted}
            src={signedURL} />
          : signedURL && url?.match(/\.mp3$/)?.[0] ? 
            <audio className="w-full" controls>
              <source src={signedURL} type="audio/mpeg" />
            </audio>
            : <a href={createFullScreenURL()} target="_blank"
              rel="noopener noreferrer">

              {signedURL && ( 
                props.turnable || props.Viewer === 'screener' ? 
                  <TurnableImage
                    Viewer={props.Viewer || 'applicant'}
                    alt="document"
                    src={signedURL}
                  /> : <img alt="document" style={{ maxWidth: props.style?.maxWidth || '200px' }} src={signedURL} />)
              }
            </a>}{" "}
        {props.removeURL && (
          <button className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-900 bg-red-100 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={(e) => props.removeURL?.(urlToRemove)}>
            {L.questions.attachment.remove}
          </button>
        )}
      </li>
    );
  }
  if (
    urllower.endsWith("png") ||
    urllower.endsWith("jpeg") ||
    urllower.endsWith("jpg") ||
    urllower.match(/mp4$|mp3$|ogg$|webm$/i)?.[0] ||
    url.split("/").slice(-1)[0].indexOf(".") === -1
  ) {
    return showImages(url, url);
  }
  
  if ((urllower.endsWith("heic")) && props.info && props.info[props.targetField + "_conversion"]) {
    return showImages(props.info[props.targetField + "_conversion"]!, url);
  }

  return (
    <li>
      <a href={url} 
        onClick={async (e) =>  {
          e.preventDefault();
          const paths = await getSignedURL({
            paths: [url],
          });
          window.open(paths.paths[url], "_blank");
        }}
        rel="noopener noreferrer" target="_blank">
        {url.split("/").slice(-1)[0]}
      </a>{" "}
      {props.removeURL && (
        <Button variant="light" onClick={(e) => props.removeURL!(url)}>
          {L.questions.attachment.remove}
        </Button>
      )}
    </li>
  );
}

function createImageWindow(event: any) {

  event.preventDefault();

  const newWindow = window.open('_blank');

  let containerElement = null;
  let callback = (imageUrl: string) => {
    if (newWindow) {
      containerElement = newWindow.document.createElement('div');
      newWindow.document.body.appendChild(containerElement);
  
      newWindow.document.write('<title>AidKit Attachment</title>');
  
      newWindow.document.title = "AidKit Attachment";
      newWindow.document.write('<img style="max-width: 100%"; alt="document" src="' + imageUrl + '" />');
    }
  }

  return { newWindow, callback };
}

function FromComms(props: {
  uid?: string,
  attachments: Record<string, boolean>,
  setSelected: React.Dispatch<React.SetStateAction<string>>,
  setSelectedMetadata?: React.Dispatch<React.SetStateAction<string>>,
  setUrlMetadataLookup?: React.Dispatch<React.SetStateAction<{ [url: string]: any }>>,
}) {
  const L = useLocalizedStrings();

  const { setSelected, setSelectedMetadata, setUrlMetadataLookup } = props
  const currentAttachments = props.attachments;

  const getMessages = usePost("/v2/applicant/get_messages");
  const [messages, setMessages] = useState({ data: [], unhandled_count: 0 } as Awaited<ReturnType<typeof getMessages>>);

  useEffect(() => {
    (async () => {
      if (!props.uid) return;
      const messageData = await getMessages({
        channel: 'default',
        applicant: props.uid
      });
      if (messageData) {
        setMessages(messageData);
      }

    })();
  }, [props.uid, getMessages]);

  if (!props.uid || !messages || !messages.data) return null;

  return (
    <ListGroup>
      {messages.data.map((m: any, i: number) => {
        const metadata = safeParse(m.metadata);
        if (!metadata?.attachments) return '';
        return metadata.attachments.map((a: string, i2: number) => {
          const alreadyExists = currentAttachments[a] === true;
          return (
            <ListGroup.Item key={`medialist-${i}-${i2}`} className="alternate-color">
              <h6>
                {m.source}, {moment(m.created_at).calendar()}
              </h6>
              <div className="d-flex justify-content-center">
                <Attachment key={`attachment-${a}`} style={{ maxHeight: '350px' }} url={a} />
              </div>
              <br />
              <div className="d-flex justify-content-end">
                {(alreadyExists && (
                  <button className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" disabled>{L.questions.attachment.already_added}</button>
                )) || 
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                    setSelected(a)
                    setSelectedMetadata && setSelectedMetadata(metadata.attachmentMetadata);
                    // incoming message metadata gets stored in a lookup table on the metadata object. look for them and assign if available for the files url
                    setUrlMetadataLookup && setUrlMetadataLookup(prevState => {
                      return metadata.attachmentMetadata ? { ...prevState, [a]: metadata.attachmentMetadata[a] } : prevState;
                    })
                  }}>{L.questions.attachment.add_to_survey}</button>
                }
              </div>
            </ListGroup.Item>
          )
        })
      })}
    </ListGroup>
  );
}

function useTextMessage(expectResponse: boolean, language?: string): any {

  let message = STRINGS[(language && language in STRINGS ? language : 'en') as keyof typeof STRINGS].questions.attachment.reply_with_attachment;

  const [response, setResponse] = useState({});
  const [sent, setSent] = useState(false);

  const [waterline, setWaterline] = useState("");
  const refWaterline = useRef(waterline);
  useEffect(() => {
    refWaterline.current = waterline;
  }, [waterline]);

  const [sentTo, setSentTo] = useState("");
  const refSentTo = useRef(sentTo);
  useEffect(() => {
    refSentTo.current = sentTo;
  }, [sentTo]);

  const sendMessage = usePost("/messages/request_attachment");
  const expectMessage = usePost("/messages/check");

  useEffect(() => {
    if (!expectResponse) return;

    const interval = setInterval(async () => {
      if (refWaterline.current) {
        const data = await expectMessage({
          since: refWaterline.current,
          phone: refSentTo.current,
          channel: 'default'
        });
        setResponse(data);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [expectResponse]);

  const send = useCallback( async (phone: string) => {
    setSentTo(phone);
    const data = await sendMessage({
      phone: phone,
      is_attachment_request: true,
      message,
      channel: 'default'
    });
    if (data && 'created_at' in data && data.created_at){
      setWaterline(data.created_at);
      setSent(true);
    }
  }, [language]);

  function finish() {
    setWaterline("");
    setSent(false);
  }

  if (expectResponse) {
    return [send, sent, response, finish];
  }

  return [send, sent];
}

function CameraAttachment({ expectMore, enableCamera, onStateChange, doUpload, enabled }: { 
  expectMore?: boolean, 
  enableCamera?: boolean, 
  enabled?: boolean,
  onStateChange(state: 'enabled' | 'disabled'): void,
  doUpload(files?: File[] | undefined): Promise<void>
}) {
  return <Camera
    cameraClassName={expectMore ? "bg-gray-100 h-full justify-items-center grid grid-cols-1 relative block w-60 border-2 text-gray-600 border-gray-300 border-dashed rounded-lg p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      : "bg-gray-100 h-full relative block border-y-0 border-x-2 border-gray-100 bg-white rounded-lg p-3 text-gray-300 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
    hidden={!enableCamera || isPhone()}
    onStateChange={onStateChange}
    enabled={enabled}
    onSnapshot={async (image) => {

      function dataURLtoBlob(dataurl: string) {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)?.[1];
        const bstr = atob(arr[1]);
        let n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }

      const name = slugid.encode(v5(image, v5.URL))

      await doUpload([new File([dataURLtoBlob(image)], `${name}.png`, { type: 'image/png' })]);

    }} />

}

function AttachmentQuestion(props: QuestionProps) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const { toast } = useToast();

  const { subsurvey } = useParams() as Record<string,string>;

  const [send, sent, response, finish] = useTextMessage(true, props.info["language"]);
  const [phone, setPhone] = useState((props['Additional Options'] || []).indexOf('Default to Screener Phone') !== -1 ? 
    props.screenerMetadata['Phone'].replace( /^([0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?)(.*)$/g, "$1") : 
    props.info["phone_number"]);

  const field = props["Target Field"];

  const loadAttachments = useCallback(() => {
    return getURLsFromCSV(props.info[props["Target Field"]!] || "")
      .reduce((acc, url: string) => {
        acc[url] = true;
        return acc;
      }, {} as Record<string, boolean>);
  }, [props.info, props["Target Field"]]);

  const [attachments, setAttachments] = useState(loadAttachments());
  const attachmentsRef = useRef(attachments);
  const [selectedFiles, setSelectedFiles] = useState([] as File[]);

  const [pendingAdds, setPendingAdds] = useState([] as any[]);
  const [pendingDeletes, setPendingDeletes] = useState([] as any[]);
  const [progress, setProgress] = useState(null as null | string);
  const [processingUpload, setProcessingUpload] = useState(false);
  const [urlMetadataLookup, setUrlMetadataLookup] = useState<{ [url: string]: any }>({});
  const [cameraActive, setCameraActive] = useState(false);
  const [sendingSMSLink, setSendingSMSLink] = useState(false);
  const [sendingEmailLink, setSendingEmailLink] = useState(false);

  const sendLinkToSubsurvey = usePost('/applicant/send_subsurvey_link');

  const targetField = props["Target Field"]!;

  const [attachmentsLoaded, setAttachmentsLoaded] = useState(false);

  const { limitOne, realtimeVerifications, allowSendingLinkToUpload, enableCamera } = safeParse(props.Metadata || '{}') as {
    limitOne?: boolean,
    realtimeVerifications?: v0.VerificationRequirements[]
    allowSendingLinkToUpload?: boolean,
    enableCamera: boolean
  };

  const [verificationErrors, setVerificationErrors] = useState<string[]>([]);
  const [verificationWarnings, setVerificationWarnings] = useState<string[]>([]);

  // Get all media messages for this applicant
  const [selectedMediaFromMessage, setSelectedMediaFromMessage] = useState('');
  const [selectedMetadataFromMessage, setSelectedMetadataFromMessage] = useState('');
  const [pendingAddsFromComms, setPendingAddsFromComms] = useState([] as any[]);

  const publicConfig = useContext(PublicConfigurationContext);

  useEffect(() => {
    bsCustomFileInput.init();
  }, [])

  const replacer = useCallback((match: string) => {
    const variable = match.slice(1).replace(/\\_/g,'_');

    if (variable === "uid") return props.uid;

    if (props.info[variable] !== undefined && props.info[variable] !== null) {
      return props.info[variable];
    }
    return match;
  }, [props.uid, props.info]);

  const marked = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
    replacer: (str) => str.replace(/\$[a-zA-Z\\_][a-zA-Z\\_0-9]+/g, replacer)
  });

  useEffect(() => {
    if (attachmentsLoaded) return;
    setAttachmentsLoaded(true);
    setAttachments(loadAttachments());
  }, [field, targetField, attachmentsLoaded, props.info]);

  const uploadURL = usePost("/document/upload_url");
  const checkLegibility = usePost("/document/check_legibility");

  const upload = useAPIUpload("/upload", (progress: number) => {
    setProgress(`${Math.round(progress * 100)}%`);
  });

  const cleanUpload = usePost("/document/clean_upload");

  useEffect(() => {
    if (response && response.attachments) {
      setPendingAdds((prevState) => {
        return Array.prototype.concat.apply(prevState, response.attachments);
      });
    }
  }, [response]);

  const info = props.info;
  const setInfoKey = props.setInfoKey;
  const target = props["Target Field"]!;
  const directUploadOnly = (props["Additional Options"] || []).includes("Direct Upload Only");

  // Overwrite attachments if selected media from a message
  useEffect(() => {
    if (selectedMediaFromMessage) {
      setPendingAddsFromComms((prevState) => {
        return Array.prototype.concat.apply(prevState, [selectedMediaFromMessage]);
      });

      setSelectedMediaFromMessage('');
    }
  }, [selectedMediaFromMessage]);

  // If our attachments update
  useEffect(() => {
    // Check if we have things to save, and do so.
    if (pendingAddsFromComms.length || pendingAdds.length || pendingDeletes.length) {
      const nextAttachments = Object.assign({}, attachments);
      for (const add of pendingAddsFromComms) {
        nextAttachments[add] = true;
      }

      for (const add of pendingAdds) {
        if (nextAttachments[add] === undefined) {
          nextAttachments[add] = true;
        }
      }
      for (const del of pendingDeletes) {
        nextAttachments[del] = false;
      }
      const list = Object.keys(nextAttachments)
        .filter((url) => nextAttachments[url])
        .join(",");
      // Get metadata for any current urls
      const metadataArray = Object.keys(nextAttachments)
        .filter(url => nextAttachments[url])
        .map(url => urlMetadataLookup[url])
        .filter(Boolean);
      if (list !== info[target]) {
        setInfoKey(target, list, true, false);
        setInfoKey(target + "_metadata", JSON.stringify(metadataArray), true, false);
      }
      setAttachments(nextAttachments);
      setPendingAddsFromComms([]);
      setPendingAdds([]);
      setPendingDeletes([]);
      finish();
    }
  }, [setInfoKey, pendingAdds, pendingDeletes, pendingAddsFromComms]);

  // Create a ref of the attachments object to use in the useEffect below.
  // This allows validate against attachments without triggering the useEffect when `attachments` changes.
  useEffect(() => {
    attachmentsRef.current = attachments;
  }, [attachments]);
  
  useEffect(() => {
    const nextAttachments = getURLsFromCSV(info[target] || "");
    let stale = false;
    for (const key in attachmentsRef.current) {
      if(attachmentsRef.current[key] === true && nextAttachments.indexOf(key) === -1) {
        stale = true;
      }
    }
    for (const key of nextAttachments) {
      if (attachmentsRef.current[key] !== true) {
        stale = true;
      }
    }
    if (stale === false) return;

    setAttachments((prevState) => {
      const nextState: any = Object.assign({}, prevState);
      for (const key in nextState) {
        nextState[key] = false;
      }
      for (const key of nextAttachments) {
        nextState[key] = true;
      }
      return nextState;
    });
  }, [info[target]]);

  const fileInput = useRef<HTMLFormElement>(null as any);
  const fileInputLabel = useRef<HTMLLabelElement>(null as any);
  const fileInputRef = useRef<HTMLInputElement>(null as any);
  const uploading = useRef(false);

  const doUpload = useCallback(async (files?: File[]) => {
    console.log("Upload started for", files?.length ?? selectedFiles?.length ?? 0, "files");
    setVerificationWarnings([]);
    setVerificationErrors([]);
    if (!files && selectedFiles) {
      files = selectedFiles;
    }
    if (!files || files?.length === 0) return;
    setProgress('0%');
    console.log("Getting upload URL");
    uploading.current = true;
    const url = await uploadURL({
      path: files[0].name,
      length: files[0].size,
      phone: props.info["phone_number"],
    });

    let result;
    try {
      result = await upload(files as unknown as any[] || selectedFiles, {}, url.uploadURL, "PUT");

      // Once uploaded, call cleanUpload if enabled
      if (publicConfig?.experimental?.enableCleanUploads && typeof result == 'string' && url.savedPath) {
        setProcessingUpload(true);
        setProgress(null);
        const cleanResult = await cleanUpload({
          savedPath: url.savedPath,
        })
        // Use cleaned url from cleanUpload instead of initial savedPath
        if (cleanResult.savedPath) {
          url.savedPath = cleanResult.savedPath;
          setUrlMetadataLookup((prevState) => ({
            ...prevState,
            [url.savedPath]: cleanResult.metadata,
          }));
          if (cleanResult.infoForUser === 'pdf_sliced_to_20_pages') {
            alert(L.questions.attachment.pdf_sliced_to_20_pages);
          }
        } else if (cleanResult.validation_error) {
          if (cleanResult.validation_error === "password_protected_pdf") {
            alert(L.questions.attachment.can_not_process_password_protected_files);
          } else if (cleanResult.validation_error === "empty_file") {
            alert(L.questions.attachment.can_not_process_empty_file);
          }
          fileInput.current.reset();
          fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
          setProcessingUpload(false);
          setProgress(null);  
          return;
        } else {
          alert(L.questions.attachment.could_not_process_file_please_try_a_different_file_or_type)
          throw new Error("Error processing upload");
        }
      }

    } catch (e) {
      fileInput.current.reset();
      fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
      setProcessingUpload(false);
      setProgress(null);  
      toast({
        description: "Error uploading image",
        variant: 'error'
      });
      Sentry.captureException(e, { extra: { uid: props.uid, url: url.savedPath }});
      return;
    }
    uploading.current = false;
    setProcessingUpload(false);
    setProgress(null);
    if (typeof result === 'string' && url.savedPath) {
      if (fileInput.current) { 
        fileInput.current.reset();
        if (fileInputLabel.current) fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
      }

      if (realtimeVerifications) {
        const passed = await checkLegibility({
          path: url.savedPath,
          info: props.info,
          tests: realtimeVerifications
        });

        let errs: string[] = [];
        for (const e of (passed?.errorMsgs || [])) {
          errs.push(e[context.lang] || '');
        }
        setVerificationErrors(errs);

        let warnings: string[] = [];
        for (const e of (passed?.warningMsgs || [])) {
          warnings.push(e[context.lang] || '');
        }
        setVerificationWarnings(warnings);

        // If passed is false, that means we failed a verification that is not
        // "advisoryOnly". So we should not complete the upload.
        if (!passed.pass) {
          setProgress(null);
          return;
        } else {
          toast({
            description: "Upload successful",
            variant: 'success'
          });
        }
      }

      setProgress(null);
      setPendingAdds((prevState) => {
        return Array.prototype.concat.apply(prevState, [url.savedPath]);//result.urls);
      });
    } else {
      toast({
        description: "Error uploading image",
        variant: 'error'
      });
      throw new Error("Error uploading image");
    }
    
  }, [selectedFiles, props.info, realtimeVerifications, publicConfig?.experimental]);

  function removeURL(url: string) {
    console.log("removing", url);
    setPendingDeletes((prevState) => {
      return Array.prototype.concat.apply(prevState, [url]);
    });
  }

  const renderedAttachments = useMemo(() => <ul>
    {Object.keys(attachments).map((url) => {
      if (!attachments[url]) return <></>;
      return <Attachment info={props.info} targetField={props["Target Field"]} 
        key={url} url={url} removeURL={removeURL} Viewer={props.Viewer} />;
    })}
  </ul>, [attachments, props.info, props.Viewer]);

  const sendLinkToGeneratedSuburvey = useCallback(async (method: 'sms' | 'email') => {
    const applicant = props.uid;
    if (!applicant) {
      return;
    }
    const result = await sendLinkToSubsurvey({
      channel: 'default',
      method,
      applicant,
      subsurvey: props['Target Field'] + '_automated_collection_form',
      message: L.questions.attachment.use_link_to_upload });
    if (result && (result as any).status) {
      toast({
        description: L.applicant.link_sent,
        variant: 'success'
      });
    } else {
      toast({
        description: L.applicant.comms.message_failed,
        variant: 'error'
      });
    }
  }, [props.uid]);

  const useNewInterface = useMemo(() => {
    const interfaceVersion = interfaceNumber(publicConfig?.interface?.version);
    return props.Viewer === 'applicant' || (interfaceVersion > 0);
  }, [props.Viewer, publicConfig?.interface?.version]);

  const uploadForm = useMemo<JSX.Element>(() => <form ref={fileInput} className={(useNewInterface ? "hidden" : "")}>
    <InputGroup>
      <div className="custom-file">
        <input
          ref={fileInputRef}
          multiple
          className="custom-file-input"
          id={"fileupload" + props["Target Field"]!}
          onChange={async (e) => {
            e.preventDefault();
            let files = e.target.files;

            if (files && files.length === 0) {
              fileInput.current.reset();
              fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
            } else {
              if (files && files[0].size > 10485760) {
                fileInput.current.reset();
                fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
                return alert(L.questions.attachment.file_too_big); 
              }
              if (files) {
                if (useNewInterface) {
                  await doUpload(Array.from(files));
                } else {
                  console.log("Setting selected files", files.length);
                  setSelectedFiles(Array.from(files))
                }
              }
            }
          }}
          type="file"
          accept={`application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/*, image/heic${realtimeVerifications ? "" : ", audio/*, video/*"}`}
        />
        <label
          ref={fileInputLabel}
          className={"custom-file-label"}
          htmlFor={"fileupload" + props["Target Field"]!}
        >
          {L.questions.attachment.choose_files}
        </label>
      </div>
      <InputGroup.Append>
        <Button type="button" style={{'zIndex': 10000}} onClick={async (e) => { e.preventDefault(); console.log("button clicked"); await doUpload() }} variant="primary">
          {uploading.current && <SpacedSpinner />}<span>{L.questions.attachment.upload}</span>
        </Button>
      </InputGroup.Append>
    </InputGroup>
  </form>, [doUpload, useNewInterface, publicConfig?.experimental?.enableCleanUploads]);

  const expectMore = useMemo(
    () => Object.keys(attachments || {}).filter(k => attachments[k]).length === 0 || (props['Additional Options'] || []).indexOf("Multiple") !== -1, 
    [attachments, props['Additional Options']]
  );

  if (props["Additional Options"]?.includes('Hidden')) {
    return null;
  }

  if (useNewInterface) {
    return <div>
      {marked}
      {renderedAttachments}
      {uploadForm}
      {(limitOne ? expectMore : true) &&
        <div className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className={expectMore 
              ? "bg-gray-100 h-full justify-items-center grid grid-cols-1 relative block w-60 border-2 text-gray-600 border-gray-300 border-dashed rounded-lg p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              : "bg-gray-100 h-full justify-items-center grid grid-cols-1 relative block w-60 border-y-0 border-x-2 text-gray-300 border-gray-100 rounded-lg p-3 text-center bg-white text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
            onClick={async (e) => { 
              e.preventDefault(); 
              fileInputLabel.current.click(); 
            }}
          >
            { (progress || processingUpload) ? 
              <SpacedSpinner /> 
              : 
              <ArrowUpOnSquareIcon className={ expectMore ? "h-6 w-6 text-gray-600" : "h-6 w-6 text-gray-300" } aria-hidden="true" />
            }
            <span className={ expectMore ? "mt-2 block text-sm font-medium text-gray-900"
              :  "mt-2 ml-2 block text-sm font-medium text-gray-600"}
            >{expectMore ?
                (progress ? L.questions.attachment.uploading + " " + progress : 
                  processingUpload ? L.questions.attachment.processing : L.questions.attachment.upload_document)
                :
                (progress ? L.questions.attachment.uploading + " " + progress : 
                  processingUpload ? L.questions.attachment.processing : L.questions.attachment.upload_additional)
              }</span>
          </button>
          <CameraAttachment 
            expectMore={expectMore}
            enableCamera={enableCamera}
            onStateChange={(s) => setCameraActive(s === 'enabled')}
            doUpload={doUpload}
          />
        </div>
      }
      {verificationErrors.map((e) =>
        <DismissibleAlert 
          message={e} 
          dismiss={() => {
            setVerificationErrors((prev) => prev.filter((e2) => e2 !== e));
          }} 
          error 
        />
      )}
      {verificationWarnings.map((e) =>
        <DismissibleAlert 
          message={e} 
          dismiss={() => {
            setVerificationWarnings((prev) => prev.filter((e2) => e2 !== e));
          }} 
          warning 
        />
      )}
      {props.Viewer === 'screener' && !directUploadOnly && (limitOne ? expectMore : true) && <div>
        <div className="mt-4" title={L.questions.attachment.via_text}>
          {sent ? (
            <div>
              <b className="mr-2">{L.questions.attachment.message_sent}</b><SpacedSpinner />
            </div>
          ) : (
            <div>
              <p className="mb-2 text-sm text-gray-600">
                {L.questions.attachment.send_attachment_text}
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder={L.questions.attachment.phone_placeholder}
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <button
                  onClick={() => send(phone)}
                  className="inline-flex justify-center w-full px-4 py-2 border border-gray-300 rounded-md bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {L.questions.attachment.send}
                </button>
              </div>
            </div>
          )}
        </div>
        {allowSendingLinkToUpload && (
          <div className="mt-4 mb-4">
            <p className="mb-2 text-sm text-gray-600">
              {L.questions.attachment.send_link_to_upload}
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
              <button
                className="inline-flex justify-center w-full px-4 py-2 border border-gray-300 rounded-md bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={async () => {
                  setSendingSMSLink(true);
                  await sendLinkToGeneratedSuburvey('sms');
                  setSendingSMSLink(false);
                }}
              >
                {sendingSMSLink ? <SpacedSpinner /> : L.questions.attachment.via_text}
              </button>
              <button
                className="inline-flex justify-center w-full px-4 py-2 border border-gray-300 rounded-md bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={async () => {
                  setSendingEmailLink(true);
                  await sendLinkToGeneratedSuburvey('email');
                  setSendingEmailLink(false);
                }}
              >
                {sendingEmailLink ? <SpacedSpinner /> : L.questions.attachment.via_email}
              </button>
            </div>
          </div>
        )}
        <FromComms
          uid={props.uid}
          attachments={attachments}
          setSelected={setSelectedMediaFromMessage}
          setSelectedMetadata={setSelectedMetadataFromMessage}
          setUrlMetadataLookup={setUrlMetadataLookup} />
      </div>}
    </div>
  }

  return (
    <div>
      {marked}
      {renderedAttachments}
      <br />
      {(limitOne ? expectMore : true) && <div>
        <Tabs defaultActiveKey={subsurvey || directUploadOnly ? "direct" : "text"}>
          {!subsurvey && !directUploadOnly && (
            <Tab eventKey="text" title={L.questions.attachment.via_text}>
              <br />
              {sent && <b>{L.questions.attachment.message_sent}</b>}
              {!sent && (
                <InputGroup>
                  <Form.Control
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder={L.questions.attachment.phone_placeholder}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={(e) => {
                        send(phone);
                      }}
                    >
                      {L.questions.attachment.send_attachment_text}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              )}
            </Tab>
          )}
          <Tab eventKey="direct" title={L.questions.attachment.via_direct_upload}>
            <br />
            {uploadForm}
          </Tab>
          {enableCamera && (
            <Tab 
              onEnter={() => setCameraActive(true)} 
              onExit={() => setCameraActive(false)} 
              title={L.selfie.camera} 
              eventKey="from-camera">
              <CameraAttachment
                expectMore={true}
                enableCamera={enableCamera}
                onStateChange={(s) => setCameraActive(s === 'enabled')}
                doUpload={doUpload}
                enabled={cameraActive}
              />
            </Tab>
          )}
          {!directUploadOnly && props.uid && (
            <Tab eventKey="from-comms" title="...">
              <FromComms uid={props.uid} attachments={attachments}
                setSelected={setSelectedMediaFromMessage} />
              
            </Tab>
          )}
        </Tabs>
      </div>}
    </div>
  );
}

export { AttachmentQuestion, Attachment, createImageWindow };
