import React, { useContext } from 'react';
import { useToast } from "@aidkitorg/component-library";
import InterfaceContext from '../Context';
import { useLocalizedStrings } from '../Localization';
import * as serviceWorkerRegistration from '../serviceWorkerRegister';

export function ForceWorkerUpdateButton() {
  const L = useLocalizedStrings();
  const { toast } = useToast();
  const { lang } = useContext(InterfaceContext);

  async function updateWorkerRegistration() {
    if (!navigator.serviceWorker) {
      return;
    }

    if (!confirm(L.offline.confirm_reset_worker)) {
      toast({
        description: "Cancelled worker"
      });
      return;
    }

    toast({
      description: "Resetting worker"
    });

    // rather than try to update, this is the nuclear option.
    // just unregister everything, then run the registration step again 
    const registrations = await navigator.serviceWorker.getRegistrations();

    await Promise.allSettled(registrations.map(async r => r.unregister()));

    await serviceWorkerRegistration.register({lang: lang});

    window.location.reload();
  }

  return (
    <a className="cursor-pointer text-blue-500" tabIndex={0} onClick={updateWorkerRegistration}>{L.offline.reset_worker}</a>
  );
}
