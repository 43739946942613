"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lookup = void 0;
var neverthrow_1 = require("neverthrow");
var runtime_1 = require("@pgtyped/runtime");
var client_dynamodb_1 = require("@aws-sdk/client-dynamodb");
var json_1 = require("../../util/json");
var db_1 = require("../../util/db");
exports.Lookup = {
    getSlugs: function (params) {
        var slugs = [params.targetField];
        var dependencies = [];
        // Lookup has no slugs, only dependencies
        var metadata = JSON.parse(params.infoDefs[params.targetField].metadata || '{}');
        if (metadata.query) {
            if (metadata.kind === 'dynamo_hash') {
                dependencies.push.apply(dependencies, __spreadArray([], __read(metadata.query.split(',').map(function (s) {
                    return s.trim().split(':')[1];
                })), false));
                slugs.push('_decrypted');
            }
            else if (metadata.geo && metadata.geoType && ['geoLookup', 'geoShape'].includes(metadata.geoType)) {
                dependencies.push(metadata.query + "_lat");
                dependencies.push(metadata.query + "_lng");
            }
            else {
                dependencies.push(metadata.query);
            }
        }
        return (0, neverthrow_1.ok)({ slugs: slugs, dependencies: dependencies });
    },
    compute: function (params) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var metadata, normalizePreHashField_1, token_1, oldInfoArray_1, sortedInfoArray, hashId, dbConfig, configPath, hashStatus, hashUploads, desiredTokenId, result, match, decrypted, decoded, decodedDict, key, readOnlyConn, dbConn, lookup, latInfo, lngInfo, lat, lng, getLookupSRID, lookupSRIDResult, lookupSRID, lookupResult, getLookupValueNoSrid, getLookupValueWithSrid, lookup, latInfo, lngInfo, lat, lng, geoShapeLookup, geoShapeLookupResult, lookup, shapeValue, geoPointsLookup, geoShapeLookupResult, kind, value, getHits, hits;
            var _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
            return __generator(this, function (_0) {
                switch (_0.label) {
                    case 0:
                        if (params.targetField.endsWith('_decrypted')) {
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        metadata = JSON.parse(params.infoDefs[params.targetField].metadata || '{}');
                        // console.log("Computing lookup for " + params.targetField, metadata);
                        if (!metadata.kind || !metadata.query)
                            return [2 /*return*/, (0, neverthrow_1.err)("lookup_has_no_metadata")];
                        if (!(metadata.kind === 'dynamo_hash')) return [3 /*break*/, 4];
                        // If this lookup is archived, don't Lookup anything.
                        if (metadata.archived)
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        normalizePreHashField_1 = function (field) {
                            // normalize the fields that are being hashed:
                            // -- remove anything that's not a number, letter, or a character used
                            // -- email characters: @ . - _
                            // -- dob characters: /
                            // -- 
                            if (!field)
                                return "";
                            if (field.indexOf('@') !== -1) {
                                return field.replace(/[^a-zA-Z0-9@.-_]/g, '');
                            }
                            else if (field.indexOf('/') !== -1) {
                                return field.replace(/[^0-9\/]/g, '');
                            }
                            return field.toLowerCase().replace(/[^0-9a-zA-Z]/g, '');
                        };
                        return [4 /*yield*/, params.aidkitCrypto];
                    case 1:
                        token_1 = _0.sent();
                        if (!token_1)
                            return [2 /*return*/, (0, neverthrow_1.err)("cannot_encrypt_without_key")];
                        oldInfoArray_1 = [];
                        sortedInfoArray = metadata.query.split(',').map(function (s) { return s.trim(); }).sort().reduce(function (sortedHashArray, hashField) {
                            var infoKey = hashField.split(':')[1];
                            var infoDef = params.infoDefs[infoKey];
                            oldInfoArray_1.push(params.previousInfo[infoKey] || '');
                            if (!params.newInfoKeys[infoKey]) {
                                sortedHashArray.push('');
                                return sortedHashArray;
                            }
                            if (!infoDef) {
                                return sortedHashArray;
                            }
                            if ((infoDef.options || []).indexOf('Encrypted') !== -1) {
                                var decoded = token_1.decode(params.newInfoKeys[infoKey]);
                                var parsedDecoded = (0, json_1.safeParse)(decoded || '{}');
                                if (parsedDecoded && parsedDecoded.userdata) {
                                    decoded = parsedDecoded.userdata;
                                }
                                sortedHashArray.push(decoded);
                            }
                            else {
                                sortedHashArray.push(normalizePreHashField_1(params.newInfoKeys[infoKey] || ''));
                            }
                            return sortedHashArray;
                        }, []);
                        // Return early if some info is blank
                        if (sortedInfoArray.some(function (i) { return !i; }))
                            return [2 /*return*/, (0, neverthrow_1.ok)((_k = {},
                                    _k[params.targetField] = '',
                                    _k[params.targetField + '_decrypted'] = '',
                                    _k))
                                // Return if the previous info hasn't changed for this lookup
                                // if (oldInfoArray.join('') === sortedInfoArray.join('')) return ok({});
                                // Actually we cant do this because the lookup data could change
                                // Use the config path from metadata to hash the info
                            ];
                        hashId = token_1.hashNoDecode(metadata.config_path + ':' + JSON.stringify(sortedInfoArray));
                        return [4 /*yield*/, params.configPromise];
                    case 2:
                        dbConfig = _0.sent();
                        configPath = 'hashed_upload:' + metadata.config_path;
                        hashStatus = JSON.parse(dbConfig ? dbConfig[configPath] || '{}' : '{}');
                        hashUploads = JSON.parse((dbConfig === null || dbConfig === void 0 ? void 0 : dbConfig.hash_uploads) || '[]');
                        if (!hashStatus || hashStatus.generation === undefined) {
                            return [2 /*return*/, (0, neverthrow_1.err)("no_hash_generation_in_db")];
                        }
                        desiredTokenId = hashUploads.filter(function (f) { return f.path === metadata.config_path; })[0].token_id;
                        return [4 /*yield*/, params.dynamo.send(new client_dynamodb_1.GetItemCommand({
                                TableName: params.deploymentKey + ".hashedinfo",
                                Key: {
                                    hash_id: {
                                        S: hashId
                                    }
                                }
                            }))];
                    case 3:
                        result = _0.sent();
                        match = (_c = (_b = (_a = result.Item) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.S) !== null && _c !== void 0 ? _c : '';
                        // Only return match if it is the token we want, and the generation we want.
                        if (((_e = (_d = result.Item) === null || _d === void 0 ? void 0 : _d.token_id) === null || _e === void 0 ? void 0 : _e.S) !== desiredTokenId || ((_g = (_f = result.Item) === null || _f === void 0 ? void 0 : _f.generation) === null || _g === void 0 ? void 0 : _g.N) !== hashStatus.generation.toString()) {
                            match = '';
                        }
                        decrypted = void 0;
                        console.log("Got match, decrypting...", match);
                        if (match) {
                            decoded = token_1.decode(match);
                            if (metadata.sensitive_info) {
                                decodedDict = JSON.parse(decoded);
                                for (key in metadata.sensitive_info) {
                                    console.log("Checking for decrypted key: ", key, !!decodedDict[key]);
                                    if (!decodedDict[key]) {
                                        continue;
                                    }
                                    if (metadata.sensitive_info[key] === 'hide') {
                                        decodedDict[key] = '******';
                                    }
                                    else if (metadata.sensitive_info[key] === 'encrypt') {
                                        decodedDict[key] = token_1.encode(decodedDict[key]);
                                    }
                                }
                                decrypted = JSON.stringify(decodedDict);
                            }
                            else {
                                decrypted = decoded;
                            }
                        }
                        return [2 /*return*/, (0, neverthrow_1.ok)(__assign((_l = {}, _l[params.targetField] = match ? 'found' : '', _l), (decrypted ? (_m = {}, _m[params.targetField + '_decrypted'] = decrypted, _m) : {})))];
                    case 4:
                        if (!params.conn)
                            return [2 /*return*/, (0, neverthrow_1.err)("no_db_conn")];
                        return [4 /*yield*/, (0, db_1.getReadOnlyDbConn)(params.deploymentKey)];
                    case 5:
                        readOnlyConn = _0.sent();
                        if (readOnlyConn.isErr()) {
                            console.warn("Failed to get readonly db conn for geo lookup program=".concat(params.deploymentKey));
                        }
                        dbConn = readOnlyConn.isOk() ? readOnlyConn.value : params.conn;
                        if (!metadata.geo) return [3 /*break*/, 16];
                        if (!(!metadata.geoType || metadata.geoType === 'geoLookup')) return [3 /*break*/, 11];
                        lookup = metadata.kind;
                        latInfo = params.newInfoKeys[metadata.query + "_lat"];
                        lngInfo = params.newInfoKeys[metadata.query + "_lng"];
                        if (!latInfo || !lngInfo) {
                            console.info('Missing geo coordinates for lookup ', metadata.query);
                            return [2 /*return*/, (0, neverthrow_1.ok)((_o = {},
                                    _o[lookup] = '',
                                    _o[params.targetField] = '',
                                    _o))];
                        }
                        lat = parseFloat(latInfo);
                        lng = parseFloat(lngInfo);
                        getLookupSRID = (0, runtime_1.sql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                  SELECT DISTINCT(ST_SRID(shape)) FROM geolookup WHERE kind = $lookup LIMIT 1;\n              "], ["\n                  SELECT DISTINCT(ST_SRID(shape)) FROM geolookup WHERE kind = $lookup LIMIT 1;\n              "])));
                        return [4 /*yield*/, getLookupSRID.run({ lookup: lookup }, dbConn)];
                    case 6:
                        lookupSRIDResult = _0.sent();
                        lookupSRID = (_j = (_h = lookupSRIDResult === null || lookupSRIDResult === void 0 ? void 0 : lookupSRIDResult[0]) === null || _h === void 0 ? void 0 : _h.st_srid) !== null && _j !== void 0 ? _j : 4326;
                        lookupResult = void 0;
                        if (!(lookupSRID === 0)) return [3 /*break*/, 8];
                        getLookupValueNoSrid = (0, runtime_1.sql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                      SELECT value \n                      FROM geolookup \n                      WHERE \n                          (ST_CONTAINS(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 0)) OR \n                            (ST_GeometryType(shape) = 'ST_Point' AND ST_Equals(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 0)))\n                          )\n                          and kind = $lookup\n                          LIMIT 1;\n                  "], ["\n                      SELECT value \n                      FROM geolookup \n                      WHERE \n                          (ST_CONTAINS(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 0)) OR \n                            (ST_GeometryType(shape) = 'ST_Point' AND ST_Equals(shape, ST_SetSRID(ST_MakePoint($lng, $lat), 0)))\n                          )\n                          and kind = $lookup\n                          LIMIT 1;\n                  "])));
                        return [4 /*yield*/, getLookupValueNoSrid.run({ lng: lng, lat: lat, lookup: lookup }, dbConn)];
                    case 7:
                        lookupResult = _0.sent();
                        return [3 /*break*/, 10];
                    case 8:
                        getLookupValueWithSrid = (0, runtime_1.sql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n                      SELECT value \n                      FROM geolookup \n                      WHERE \n                        (ST_CONTAINS(\n                              shape,\n                              ST_Transform(ST_SetSRID(ST_MakePoint($lng, $lat), 4326), $lookupSRID::integer)\n                          ) OR\n                          (ST_GeometryType(shape) = 'ST_Point' AND ST_Equals(\n                              shape,\n                              ST_Transform(ST_SetSRID(ST_MakePoint($lng, $lat), 4326), $lookupSRID::integer)\n                          )))\n                      and kind = $lookup\n                      LIMIT 1;\n                  "], ["\n                      SELECT value \n                      FROM geolookup \n                      WHERE \n                        (ST_CONTAINS(\n                              shape,\n                              ST_Transform(ST_SetSRID(ST_MakePoint($lng, $lat), 4326), $lookupSRID::integer)\n                          ) OR\n                          (ST_GeometryType(shape) = 'ST_Point' AND ST_Equals(\n                              shape,\n                              ST_Transform(ST_SetSRID(ST_MakePoint($lng, $lat), 4326), $lookupSRID::integer)\n                          )))\n                      and kind = $lookup\n                      LIMIT 1;\n                  "])));
                        return [4 /*yield*/, getLookupValueWithSrid.run({ lng: lng, lat: lat, lookup: lookup, lookupSRID: lookupSRID }, dbConn)];
                    case 9:
                        lookupResult = _0.sent();
                        _0.label = 10;
                    case 10:
                        if (lookupResult.length) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_p = {},
                                    _p[lookup] = lookupResult[0].value,
                                    _p[params.targetField] = lookupResult[0].value,
                                    _p))];
                        }
                        else {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_q = {},
                                    _q[lookup] = '',
                                    _q[params.targetField] = '',
                                    _q))];
                        }
                        return [3 /*break*/, 15];
                    case 11:
                        if (!(metadata.geoType === 'geoShape')) return [3 /*break*/, 13];
                        lookup = metadata.kind;
                        latInfo = params.newInfoKeys[metadata.query + "_lat"];
                        lngInfo = params.newInfoKeys[metadata.query + "_lng"];
                        if (!latInfo || !lngInfo) {
                            console.info('Missing geo coordinates for lookup ', metadata.query);
                            return [2 /*return*/, (0, neverthrow_1.ok)((_r = {},
                                    _r[params.targetField] = '',
                                    _r))];
                        }
                        lat = parseFloat(latInfo);
                        lng = parseFloat(lngInfo);
                        geoShapeLookup = (0, runtime_1.sql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          SELECT DISTINCT(ST_AsText(shape)) as value\n          FROM geolookup\n          WHERE\n            kind = $lookup\n            AND ST_Contains(\n              shape,\n              ST_SetSRID(ST_Point($lng, $lat), 4326)\n            );\n        "], ["\n          SELECT DISTINCT(ST_AsText(shape)) as value\n          FROM geolookup\n          WHERE\n            kind = $lookup\n            AND ST_Contains(\n              shape,\n              ST_SetSRID(ST_Point($lng, $lat), 4326)\n            );\n        "])));
                        return [4 /*yield*/, geoShapeLookup.run({ lng: lng, lat: lat, lookup: lookup }, dbConn)];
                    case 12:
                        geoShapeLookupResult = _0.sent();
                        if (geoShapeLookupResult.length) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_s = {},
                                    _s[params.targetField] = geoShapeLookupResult.map(function (r) { return r.value; }).join(','),
                                    _s))];
                        }
                        else {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_t = {},
                                    _t[params.targetField] = '',
                                    _t))];
                        }
                        return [3 /*break*/, 15];
                    case 13:
                        if (!(metadata.geoType === 'geoPoints')) return [3 /*break*/, 15];
                        lookup = metadata.kind;
                        shapeValue = params.newInfoKeys[metadata.query];
                        if (!shapeValue) {
                            console.info('Missing geo shape value for lookup ', metadata.query);
                            return [2 /*return*/, (0, neverthrow_1.ok)((_u = {},
                                    _u[lookup] = '',
                                    _u[params.targetField] = '',
                                    _u))];
                        }
                        geoPointsLookup = (0, runtime_1.sql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          SELECT DISTINCT(value)\n          FROM geolookup\n          WHERE\n            kind = $lookup\n            AND ST_Contains(\n              ST_SetSRID(\n                ST_GeomFromText($shapeValue),\n                4326\n              ),\n              shape\n            );\n        "], ["\n          SELECT DISTINCT(value)\n          FROM geolookup\n          WHERE\n            kind = $lookup\n            AND ST_Contains(\n              ST_SetSRID(\n                ST_GeomFromText($shapeValue),\n                4326\n              ),\n              shape\n            );\n        "])));
                        return [4 /*yield*/, geoPointsLookup.run({ shapeValue: shapeValue, lookup: lookup }, dbConn)];
                    case 14:
                        geoShapeLookupResult = _0.sent();
                        if (geoShapeLookupResult.length) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_v = {},
                                    _v[params.targetField] = geoShapeLookupResult.map(function (r) { return r.value; }).join(','),
                                    _v))];
                        }
                        else {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_w = {},
                                    _w[params.targetField] = '',
                                    _w))];
                        }
                        _0.label = 15;
                    case 15: return [2 /*return*/, (0, neverthrow_1.ok)((_x = {},
                            _x[params.targetField] = '',
                            _x))];
                    case 16:
                        kind = metadata.kind;
                        value = params.newInfoKeys[metadata.query];
                        getHits = (0, runtime_1.sql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n                SELECT * FROM lookup where kind = $kind and key = $value\n            "], ["\n                SELECT * FROM lookup where kind = $kind and key = $value\n            "])));
                        return [4 /*yield*/, getHits.run({ kind: kind, value: value }, dbConn)];
                    case 17:
                        hits = _0.sent();
                        if (hits.length && params.targetField) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_y = {},
                                    _y[params.targetField] = hits[0].value,
                                    _y))];
                        }
                        else if (params.targetField) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_z = {},
                                    _z[params.targetField] = '',
                                    _z))];
                        }
                        return [2 /*return*/, (0, neverthrow_1.ok)({})];
                }
            });
        });
    }
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
