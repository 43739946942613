import { useState } from "react";
import { usePost } from "./API";
import { Button, Checkbox } from "@aidkitorg/component-library";

export default function GeolookupPage() {
  const [kind, setKind] = useState<string>('');
  const [property, setProperty] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [loadDynamo, setLoadDynamo] = useState<boolean>(false);
  const [loadPostgres, setLoadPostgres] = useState<boolean>(false);
  const [forceLoad, setForceLoad] = useState<boolean>(false);
  const loadBoundaries = usePost('/program/admin/load_boundaries');

  const load = async function() {
    alert(JSON.stringify(await loadBoundaries({ kind, property, url, loadDynamo, loadPostgres })));
  }

  return <>
    <h1>Boundary Loading</h1>
    <hr/>
    <p>Note that for simplicity and performance this does not run RS. If you want to run RS, do so from the other admin interface</p>
    <fieldset>
      <legend>Lookup Kind</legend>
      <input value={kind} onChange={(e) => setKind(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
      <legend>Url</legend>
      <input value={url} onChange={(e) => setUrl(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
      <legend>Property</legend>
      <input value={property} onChange={(e) => setProperty(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset className="my-4">
      <legend className="max-w-md">
        Use postgres for geolookups after ingestion.<br />
        If the geoJSON provided is empty, we will skip all data loading and won't remove any existing lookup data. Use Force Override if you want to clear out existing geolookup data.  
      </legend>
      <Checkbox
        label="Load Postgres"
        checked={loadPostgres}
        onChange={(value) => setLoadPostgres(value as boolean)}
      />
      <Checkbox
        label="Force Override"
        checked={forceLoad}
        onChange={(value) => setForceLoad(value as boolean)}
      />
    </fieldset>
    <fieldset>
      <legend>Use dynamodb for geolookups pre-ingestion</legend>
      <Checkbox
        label="Load Dynamo"
        checked={loadDynamo}
        onChange={(value) => setLoadDynamo(value as boolean)}
      />
    </fieldset>
    <br />
    <Button onClick={load}>Load Boundaries</Button>
  </>
}