import * as v0 from "@aidkitorg/types/lib/survey";
import { useEffect, useMemo, useState } from "react";
import { useLocalizedStrings } from "../Localization";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

type GroupedAttachments = {
  images: { url: string; name: string }[];
  others: { url: string; name: string }[];
}

export function ArcGISAttachmentViewer(props: {
  component: v0.ArcGISAttachmentViewer,
  info: Record<string, string | undefined>,
}) {
  const content = useModularMarkdown({
    content: props.component.content,
    info: props.info
  });
  const L = useLocalizedStrings();

  const objectIds = useMemo(() => {
    const objectIdsString =
      typeof props.component.objectIds === "string"
        ? props.component.objectIds
        : props.info?.[props.component.objectIds.field];

    return objectIdsString ? objectIdsString.split(",") : [];
  }, [props.component.objectIds, props.info]);

  const [groupedAttachments, setGroupedAttachments] = useState<GroupedAttachments>({
    images: [],
    others: [],
  });

  useEffect(() => {
    if (!objectIds || objectIds.length === 0) {
      return;
    }

    // Fetch attachments for each objectId and build up the final list of image URLs
    const fetchAttachments = async () => {
      try {
        // Use Promise.all to fetch all attachments in parallel
        const attachmentsPromises = objectIds.map(async (objectId) => {
          const url = `${props.component.featureServerLayerUrl}/${objectId}/attachments?f=json`;
          const response = await fetch(url);
          const json = await response.json();
          const attachmentInfos = json.attachmentInfos || [];

          const images = attachmentInfos
            .filter((info: any) => info.contentType === "image/jpeg")
            .map((info: any) => ({
              url: `${props.component.featureServerLayerUrl}/${objectId}/attachments/${info.id}`,
              name: info.name,
            }));

          const others = attachmentInfos
            .filter((info: any) => info.contentType !== "image/jpeg")
            .map((info: any) => ({
              url: `${props.component.featureServerLayerUrl}/${objectId}/attachments/${info.id}`,
              name: info.name || `Attachment ${info.id}`,
            }));

          return { images, others };
        });

        const allGroupedAttachments = await Promise.all(attachmentsPromises);

        // Flatten and set images and other attachments
        const finalGroupedAttachments: GroupedAttachments = allGroupedAttachments.reduce(
          (acc, group) => {
            acc.images.push(...group.images);
            acc.others.push(...group.others);
            return acc;
          },
          { images: [], others: [] } as GroupedAttachments
        );

        setGroupedAttachments(finalGroupedAttachments);
      } catch (err) {
        // Handle error as needed
        console.error('Error fetching attachment info:', err);
      }
    };

    fetchAttachments();
  }, [objectIds]);

  return <>
    <div>
      {content}
    </div>
    {groupedAttachments.others.length > 0 && (
      <div className="flex flex-col gap-2 w-full mb-2">
        {groupedAttachments.others.map((attachment, idx) => (
          <a
            key={idx}
            href={attachment.url}
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full py-1"
          >
            {attachment.name}
          </a>
        ))}
      </div>
    )}
    <div className="flex flex-col gap-4 w-full">
      {groupedAttachments.images.length ? (
        groupedAttachments.images.map((image, idx) => (
          <a
            key={idx}
            href={image.url}
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full border border-gray-300 p-1"
          >
            <img
              src={image.url}
              alt={`ArcGIS Attachment ${idx}`}
              className="w-full h-auto block"
            />
          </a>
        ))
      ) : (
        <p>{L.arcgis_attachment_viewer.no_images_found}</p>
      )}
    </div>
  </>
}