import * as v0 from "@aidkitorg/types/lib/survey";
import { Component, useEffect, useState } from "react";
import { usePost } from "../API";
import { snakeToEnglish, safeParse } from "../Util";
import { Button } from "@aidkitorg/component-library";

export function Consent(props: { 
  component: v0.Consent,
  setInfo: (key: string, value: string) => void,
  info: Record<string, string | undefined>,
  uid: string,
}) {
  return <>
    <h2>First thing's first</h2>
    <p>Before you start, we need two things:</p>
    <ol className='list-decimal pl-10'>
      <li><b>Consent for AidKit to store and process the data you provide to us in connection with this program.</b> <Button variant="link" href="#">Learn More</Button></li>
      <li><b>Consent for AidKit to save information to your computer so that we can save your progress and authenticate you in the future (known as a cookie).</b> <Button variant="link" href="#">Learn More</Button></li>
    </ol>
    <p>If that sounds good, click "I agree"</p>
    <button className="inline-flex p-2 justify-center rounded-md border">I agree</button>
  </>
}