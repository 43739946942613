import { useRef, useState, useContext, useCallback } from "react";
import { usePost } from "../API";
import { QuestionProps } from "./Props";
import { useLocalizedStrings } from "../Localization";
import InterfaceContext, { AuthContext } from "../Context";
import { ValidatorMessage } from "../Components/ValidatorMessage";
import { saveKeyToCookieAndRelocateToUrl, safeParse } from "../Util";
import { CandidateChooser } from "../Components/CandidateChooser";
import { useHistory } from "react-router";
import * as v0 from "@aidkitorg/types/lib/survey";
import { useCookies } from "react-cookie";

export function ResumeWidget(props: QuestionProps) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const auth = useContext(AuthContext);
  const [contact, setContact] = useState('');
  const [resuming, setResuming] = useState(false);
  const [challenge, setChallenge] = useState<string | undefined>('');
  const token = useRef('')

  const history = useHistory();

  const [canStartNew, setCanStartNew] = useState(true);
  const [homePageLink, setHomePageLink] = useState('');

  const sendConfirmation = usePost("/confirm/phone");
  const checkCode = usePost("/confirm/phone_code");
  const resume = usePost("/subsurvey/resume", {
    token: () => {
      return token.current;
    }
  });

  const getApplicantCandidates = usePost("/applicant/get_candidates", { token: () => token.current });
  const [resumeCandidates, setResumeCandidates] = useState([] as Awaited<ReturnType<typeof getApplicantCandidates>>['candidates']);
  const [startNewToken, setStartNewToken] = useState('');
  const [dialogDescription, setDialogDescription] = useState<undefined | v0.RichText>(undefined);
  const [cookies, setCookie] = useCookies(['portal'])

  const [challenges, setChallenges] = useState(null as Record<string, string> | null)
  const [code, setCode] = useState(''); 
  const [validationError, setValidationError] = useState(''); // for storing user errors for better UX

  const contactRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);

  const metadata = safeParse(props.Metadata || '{}');

  const doResume = async (params: {
    ingested?: boolean,
    resumptionToken: string, 
    confirmationToken: string
  }) => {
    token.current = params.resumptionToken;
    if (params.ingested) {
      alert(L.questions.contact_confirmation.previous_application_found);
      const newURL = `${window.location.protocol}//${window.location.host}/p/${metadata.ingestedResumesToSubsurvey || 'apply'}?key=${params.resumptionToken}`;
      // window.location.href = newURL;
      saveKeyToCookieAndRelocateToUrl(newURL);
      return;
    }

    const previous = await resume({
      form_name: 'apply'
    });

    if (previous?.info) {
      props.LoadInfo!(previous.info, token.current);
      alert(L.questions.contact_confirmation.confirmation_successful_also_save)
    } else {
      window.confirm(L.questions.contact_confirmation.no_app_to_resume);
    }
    setResuming(false);
  }

  const submitChallenge = useCallback(async (e: React.FormEvent<HTMLFormElement>, code: string) => {
    e.preventDefault();
    if (code.length !== 6) {
      setValidationError(L.questions.contact_confirmation.code_is_not_6_digits);
      return;
    }
    if (!challenge) return;
    const result = await checkCode({
      token: challenge,
      answer: code
    })
    if (result.attestation) {
      token.current = result.attestation;

      const res = await getApplicantCandidates({
        info: props.info,
        mode: 'resume'
      });

      const { candidates, canStartNewToken, homePageLink, portalToken } = res;
      setDialogDescription(res.dialogDescription);

      if (portalToken) {
        setCookie('portal', portalToken, {path: '/'});
      }

      const anyCandidateHasSubsurveys = candidates.some(c => c.subsurveys?.length);

      if (candidates.length && (candidates.length > 1 || canStartNewToken || anyCandidateHasSubsurveys)) {
        setStartNewToken(canStartNewToken || '');
        setResumeCandidates(candidates)
        setHomePageLink(homePageLink || '');
      } else if (candidates.length === 1 && !canStartNewToken) {
        await doResume({
          ingested: candidates[0].applicant.kind === 'ingested',
          resumptionToken: candidates[0].resumeToken,
          confirmationToken: result.attestation
        });
      } else {
        window.confirm(L.questions.contact_confirmation.no_app_to_resume);
        setResuming(false);
      }

      setCanStartNew(canStartNew);
            
      setChallenge(undefined);
      setCode('');
    } else {
      if (result.error === 'challenge_expired') {
        setChallenge(undefined);
        setCode('');
      }
      // AK already toasts
      //toast(result.error);
    }
  }, [challenge]);

  if (props.Viewer === 'screener') {
    return <></>;
  }
    
  const phoneOnly = (props["Additional Options"] || []).indexOf("Phone Only") !== -1;

  if (auth?.token?.() && canStartNew) {
    return <>
      <button
        type="button"
        onClick={(e) => {
          window.location.href = '/apply'
        }}
        className="inline-flex items-center -mt-2 mr-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        {L.apply.start_new_application}
      </button>
    </>
  }

  if (!canStartNew && !resuming) return <>Please continue with your application.</>;

  return <>
    {resuming && (resumeCandidates.length > 1 || startNewToken) ? <CandidateChooser 
      onClose={() => {
        setResuming(false);
        setResumeCandidates([]);
        setStartNewToken('');
      }}
      label={<>
        <span>{dialogDescription?.[context.lang] || L.apply.we_found_some_existing_apps}</span>
        {startNewToken ? <><br /><span>{L.apply.you_can_also_start_new}</span></> : null}
      </>}
      candidates={[
        ...(homePageLink ? [{
          buttonLabel: L.questions.contact_confirmation.go_to_home_page,
          onClick: async () => {
            window.location.href = homePageLink;
          }
        }] : []),
        ...(resumeCandidates.map(c => {
          return {
            ...c,
            onClick: async () => {
              await doResume({
                ingested: c.applicant.kind === 'ingested',
                resumptionToken: c.resumeToken,
                confirmationToken: token.current
              });
            }
          }
        })), ...(startNewToken ? [{
          applicant: { kind: 'pending' as const, dynamo_uid: 'start-new' },
          resumeToken: startNewToken,
          summary: { en: 'Start a new application' },
          onClick: async () => {
            await doResume({ 
              ingested: false, 
              resumptionToken: startNewToken,
              confirmationToken: token.current
            })
          }
        }] : [])]} /> : null}
        
    {(!resuming && canStartNew) && (
      <>
        <button
          type="button"
          onClick={(e) => {
            props.pageNext?.()
          }}
          className="inline-flex items-center -mt-2 mr-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {L.apply.start_new_application}
        </button>
        <button
          type="button"
          onClick={(e) => {
            setResuming(true);
            setTimeout(() => {
              contactRef.current!.focus();
            }, 100)
          }}
          className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {metadata.customButtonText?.[context.lang || 'en'] || L.apply.resume_application}
        </button>
      </>
    )}

    {resuming && !challenge &&
            <form onSubmit={async (e) => {
              e.preventDefault();
              const challenge = await sendConfirmation({
                phone: contact,
                language: context.lang
              })
              console.log("challenge", challenge)
              if (challenge.token) {
                setChallenge(challenge.token);
                setTimeout(() => {
                  codeRef.current!.focus();
                }, 100)
              }
            }}>
              <fieldset className="mt-2">
                <legend>{L.apply.please_enter_previous}</legend>
                <input 
                  ref={contactRef}
                  value={contact}
                  onChange={(e) => { setContact(e.target.value); }}
                  placeholder={phoneOnly ? "Phone" : "Email or Phone"}
                  className="max-w-md block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400" />
              </fieldset>
              <button 
                className={`inline-flex items-center mr-2 mt-2 px-4 py-2 border border-transparent 
                    ${ !contact || (contact.length !== 10 && !contact.includes('@')) ? 'disabled:bg-gray-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none' : 
                      'hover:bg-indigo-700 bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}
                    text-sm font-medium rounded-md shadow-sm text-white `}
                type="submit" 
                disabled={!contact || (contact.length !== 10 && !contact.includes('@'))}
                onSubmit={(e) => e.preventDefault()}>{L.apply.send_confirmation_code}</button>
              <button 
                className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={(e) => setResuming(false)}>{L.apply.cancel}</button>
              {contact !== "" && contact.length !== 10 && !contact.includes('@') && <ValidatorMessage message={L.questions.textentry.please_enter_valid_us_number} />}

            </form>
    }
    {challenge && 
            <form onSubmit={async (e) => submitChallenge(e, code)}>
              <fieldset>
                <legend>{L.apply.please_enter_code}</legend>
                <input 
                  ref={codeRef}
                  value={code}
                  autoComplete="one-time-code"
                  onChange={(e) => { 
                    const value = (e.target.value || '').replace(/[^0-9]/g, '');
                    if (value.length === 6) {
                      setValidationError('');
                    }
                    setCode(value);
                  }}
                  placeholder="6-digit code"
                  className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400" />
              </fieldset>
              {validationError && <ValidatorMessage message={validationError} />}
              <button 
                className="inline-flex items-center mr-2 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                type="submit" onSubmit={(e) => e.preventDefault()}>{L.apply.confirm_code}</button>
              <button 
                className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={(e) => { setCode(''); setChallenge(undefined); setResuming(false) }}>{L.apply.cancel}</button>
            </form>
    }
  </>
}