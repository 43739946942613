import { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Button } from "@aidkitorg/component-library";
import { usePost } from "../API";
import { TurnableImage } from "../Components/TurnableImage";
import InterfaceContext, { ConfigurationContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import { languageContent, safeParse, BUTTON_CLASS } from "../Util";
import { Attachment, createImageWindow } from "./Attachment";
import { QuestionProps } from "./Props";
import { getURLsFromCSV } from '@aidkitorg/roboscreener/lib/util/urls_from_csv'
import { useToast } from "@aidkitorg/component-library";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

export function ShowField(props: QuestionProps) {
  const config = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);
  const getVersions = usePost('/applicant/get_history');
  const urls = getURLsFromCSV(props.info[props["Target Field"]!] || '');
  const [versions, setVersions] = useState(null as null | Awaited<ReturnType<typeof getVersions>>['changes']);
  const [versionsFetched, setVersionsFetched] = useState(false);
  const L = useLocalizedStrings();
  const content = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
  });
  const { toast } = useToast();
  
  const decryptField = usePost("/admin/decrypt_field");

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
    return <span></span>
  }

  async function fetchDecryptedValue() {
    if (!props.uid) {
      toast({
        description: "Cannot decrypt without an applicant",
        variant: 'error'
      });
      return;
    }

    const decrypted = await decryptField({
      applicant: props.uid,
      field: props["Target Field"]!
    });

    if (decrypted.decrypted) {
      alert(decrypted.decrypted);
    }
  }
  
  async function fetchVersions() {
    const history = await getVersions({
      applicant: props.uid!,
      key: props["Target Field"]!
    })
    setVersions(history.changes);
    setVersionsFetched(true);
  }
  
  const showVersions = (props["Additional Options"] || []).indexOf("Shows Versions") !== -1;

  function restoreURL(url?: string) {
    if (confirm("Are you sure you want to add this document to the current field?")) {
      const prev = props.info[props["Target Field"]!];
      if (prev) {
        props.setInfoKey(props["Target Field"]!, (prev + ',') + url || '', true, false);
      } else {
        props.setInfoKey(props["Target Field"]!, url || '', true, false);
      }
    }
  }
  
  function renderURL(url?: string, canRestore?: boolean) {
    if (!url) return <></>
    return <>{canRestore && <Button variant="secondary" onClick={() => restoreURL(url)}>Restore</Button>}
      <Attachment key={url} info={props.info} 
        targetField={props["Target Field"]!} 
        url={url} Viewer={props.Viewer} turnable={true} /></>
  }
   
  if (
    props.info[props["Target Field"]!] &&
      urls.some(u => u?.startsWith('http'))
  ) {
    return (
      <div>
        {content}
        <ul>
          {urls.map((u) => { return renderURL(u) })}
        </ul>
        {showVersions && 
            <div>
              {versionsFetched ?
                <div>
                  <Button variant="secondary" onClick={() => setVersionsFetched(false)}>Hide Versions</Button>
                  {(versions || []).map((v) => <div className="p-2 bg-gray-100 m-2 rounded-md">
                    {v.value ? getURLsFromCSV(v.value).map((u) => { return renderURL(u, true) }) : <div>(cleared)</div>}
                    <span className="text-gray-400">
                      Set at {v.created_at.toLocaleString()} by {v.author_name.replace('System', 'recipient')}
                    </span>
                  </div>)}
                </div> 
                : <Button variant="secondary" onClick={fetchVersions}>Show Versions</Button>
              }
            </div>}
      </div>
    );
  }
  
  // console.log(props["Target Field"], props.info[props['Target Field']!]);

  // Styled show field
  const bg = 'light';
  let targetFieldValue = props.info[props["Target Field"]!];
  let isJSON = false;
  try {
    targetFieldValue = JSON.parse(targetFieldValue);
    isJSON = typeof targetFieldValue === 'object' && targetFieldValue !== null;
  } catch (e) {}

  return (
    <Card>
      <Card.Header as="h6">{props[languageContent(context.lang)]}</Card.Header>
      <Card.Body as="div" className={`alert alert-${bg} mb-0`}>
        <Card.Text className="text-gray-600">
          {props["Additional Options"]?.includes("Allow Decrypting") && config.roles?.includes('admin')
            ? <button className={BUTTON_CLASS} onClick={() => fetchDecryptedValue()}>Encrypted Value, Click to Decrypt</button>
            : isJSON
              ? <pre>{(JSON.stringify(targetFieldValue, null, 2)) + ''}</pre>
              : ![undefined, null, ''].includes(targetFieldValue) 
                ? (targetFieldValue + '') 
                : <em className="text-gray-600">{L.questions.empty}</em>
          }
          {showVersions && 
            <div>
              {versionsFetched ?
                <div className="mt-2">
                  <Button className="mb-2" variant="secondary" onClick={() => setVersionsFetched(false)}>Hide Versions</Button>
                  {(versions || []).map((v, vindex) => <div key={`${v}-${vindex}`} className="p-2 bg-gray-100 m-2 rounded-md">
                    <div>{v.value ? getURLsFromCSV(v.value).map((u) => renderURL(u)) : '(cleared)'}</div>
                    <span className="text-gray-600">
                      Set at {new Date(v.created_at).toLocaleString(context.lang.split('_')[0])} by {v.author_name?.replace('System', 'recipient')}
                    </span>
                  </div>)}
                </div> 
                : <Button className="mt-2" variant="secondary" onClick={fetchVersions}>Show Versions</Button>
              }
            </div>}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export function ShowDate(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  
  const fetchDate = usePost('/applicant/get_history');
    
  const [date, setDate] = useState<Date | null>(null);
  const loading = useRef(false);
  
  useEffect(() => {
    if (loading.current || !props["Target Field"] || !props.uid) return;
  
    (async () => {
      loading.current = true;
      const history = await fetchDate({ 
        applicant: props.uid!,
        key: props["Target Field"],
      });
  
      const latest = history.changes.find((change) => change.key === props["Target Field"] && change.latest === true);
      if (latest) {
        setDate(new Date(latest.created_at));
      }
  
      loading.current = false;
    })();
  }, [props["Target Field"]]);
  
  return (
    <Card>
      <Card.Header as="h6">{props[languageContent(context.lang)]}</Card.Header>
      <Card.Body as="div" className={`alert alert-light mb-0`}>
        <Card.Text className="text-gray-600">
          <div>
            <span>{date ? date.toLocaleString(context.lang.split('_')[0]) : ''}</span>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  ); 
}
  