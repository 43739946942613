import { useCallback, useEffect, useState } from "react";
import { get_host, usePost } from "../API";
import { StageAttempt, type LivenessSession } from "aidkit/lib/application/liveness.schema";
import { Attachment } from "../Questions/Attachment";
import { classNames, snakeToEnglish } from "../Util";
import { type TypedQueueResults } from "aidkit/lib/documents/realtimeMediaProcessing";
import { SimpleForm } from "../Components/SimpleForm";
import { Link } from "react-router-dom";
import { InlineAttachments } from "../Applicant/Comms";

import { Tabs } from "@aidkitorg/component-library";

export function LivenessDebug(props: any) {

  const findSessionsByAppId = usePost("/admin/liveness/find_sessions_by_appid");
  const findSessionsByContact = usePost("/admin/liveness/find_sessions_by_email");

  const [searchTerm, setSearchTerm] = useState('');
  const [sessions, setSessions] = useState<null | LivenessSession[] | "no_results" >(null);
  const [showAllSessions, setShowAllSessions] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  const handleSearch = useCallback(async (searchTerm: string) => {
    if (searchTerm.includes('@')) {
      const email = searchTerm.trim().toLowerCase();
      const response = await findSessionsByContact({ email });
      setSessions(response?.sessions?.length ? response.sessions : "no_results");
    } else if ([11,22,36].includes(searchTerm.length)) {
      // 11 is legacy uid, 22 is modern slug uid, 36 is dynamo app id
      const response = await findSessionsByAppId({ appId: searchTerm });
      setSessions(response?.sessions?.length ? response.sessions : "no_results");
    } else {
      alert("Invalid search term. Please enter an email address or app id.");
    }

    // update window search params
    const params = new URLSearchParams(window.location.search);
    params.set('term', btoa(searchTerm));
    window.history.replaceState({}, '', window.location.pathname + '?' + params.toString());
  }, []);

  useEffect(() => {
    const encodedTermFromUrl = searchParams.get('term');
    if (encodedTermFromUrl) {
      try {
        const decodedTerm = atob(encodedTermFromUrl);
        setSearchTerm(decodedTerm);
        handleSearch(decodedTerm);
      } catch (e) {
        // Handle case where term is not properly base64 encoded
        console.warn('Invalid encoded search term in URL');
      }
    }
  }, []);

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Liveness Debugging</h1>
      
      <div className="mb-8">
        <SimpleForm 
          title="Search Liveness Sessions"
          description="Enter the email or App ID (UID or Dynamo UID) to find liveness sessions"
          inputs={[{ 
            name: "term", 
            label: "Search Term", 
            value: searchTerm, 
            onChange: (e) => setSearchTerm(e.target.value) 
          }]}
          submit={{ 
            label: "Find Sessions", 
            onClick: async (e) => {
              e.preventDefault();
              handleSearch(searchTerm);
            }
          }}
        />
        {sessions && typeof sessions === 'string' && sessions === 'no_results' && (
          <p className="mt-4 text-gray-600">No liveness sessions were found matching that email.</p>
        )}
      </div>

      {sessions && typeof sessions !== 'string' && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">
              Found {sessions.length} session{sessions.length !== 1 ? 's' : ''}
            </h3>
            {sessions.length > 5 && (
              <button
                onClick={() => setShowAllSessions(!showAllSessions)}
                className="px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 rounded"
              >
                {showAllSessions ? 'Show Latest 5' : `Show All (${sessions.length})`}
              </button>
            )}
          </div>
          <ul role="list" className="space-y-8">
            {(showAllSessions ? sessions : sessions.slice(-5)).map((s) => (
              <li key={s.sessionData.livenessId} className="bg-gray-50 rounded-lg p-6">
                <div className={classNames(s.sessionData.front || s.sessionData.selfie || s.sessionData.back ? 
                  'border-b border-gray-200 pb-4 mb-4' : ''
                )}>
                  <h2 className="text-xl font-bold text-gray-900 mb-2">
                    Applicant <Link className="text-blue-600 hover:text-blue-800" target="_blank" to={`${s.appId.length == 22 ? 'a' : 'ua'}/${s.appId}`}>{s.appId}</Link>
                  </h2>
                  <h3 className="text-md text-gray-600">
                    Session ID: {s.sessionData.livenessId}
                  </h3>
                  <h3 className="text-sm text-gray-600">
                    <span className="">Started: {new Date(new Date(s.expiresAt).getTime() - 1000 * 60 * 60).toLocaleString()}</span>
                  </h3>
                  <h3 className="text-sm text-gray-600">
                    <span className="">Expiration: {new Date(s.expiresAt).toLocaleString()}</span>
                  </h3>
                </div>

                {(s.sessionData.front || s.sessionData.back || s.sessionData.selfie) && <Tabs
                  tabs={[
                    {
                      name: "View by Stage",
                      key: "stage-view",
                      content: <StageView session={s} />
                    },
                    {
                      name: "Timeline View",
                      key: "timeline-view",
                      content: <TimelineView session={s} />
                    }
                  ]}
                />}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

const StageAttemptDetails = ({ stage, attempt } : { stage: string, attempt: StageAttempt }) => {

  const getQueueResults = usePost("/admin/liveness/get_queue_results_by_id");
  const [queueResults, setQueueResults] = useState<null | Record<string, { [mode: string & { __kind: "face" | "barcode" | "text"}]: TypedQueueResults }>>(null);

  const populateQueueResults = async (queueId: string) => {
    if (stage === 'front') {
      const faceQueueResults = await getQueueResults({ action: "Detect Faces", queueId });
      const textQueueResults = await getQueueResults({ action: "Extract Text", queueId });
      setQueueResults((cur) => ({ ...cur, [queueId]: { 
        face: faceQueueResults?.queueResults || { error: "No results" },
        text: textQueueResults?.queueResults || { error: "No results" },
      } }));
    } else if (stage === 'selfie') {
      const faceQueueResults = await getQueueResults({ action: "Detect Faces", queueId });
      setQueueResults((cur) => ({ ...cur, [queueId]: { 
        face: faceQueueResults?.queueResults || { error: "No results" }
      } }));
    } else if (stage === 'back') {
      const barcodeQueueResults = await getQueueResults({ action: "Scan Barcode", queueId });
      setQueueResults((cur) => ({ ...cur, [queueId]: { 
        barcode: barcodeQueueResults?.queueResults || { error: "No results"} 
      } }));
    }
  }

  return <div>
    <div key={attempt.version} className="flex flex-col md:flex-row gap-6 bg-white p-6 rounded-md mb-4">
      <div className="w-full md:w-1/3 space-y-4">
        <div className="p-4 bg-gray-50 rounded-md overflow-hidden">
          <div className="aspect-[3/2] relative">
            <ul>
              <Attachment url={attempt.imageUrl} key={""} Viewer="screener" />
            </ul>
            
          </div>
          <pre className="mt-2 text-xs overflow-x-auto whitespace-pre-wrap">
            {attempt.imageUrl}
          </pre>
        </div>
        {attempt.videoUrl && (
          <div className="p-4 bg-gray-50 rounded-md overflow-hidden">
            <InlineAttachments
              attachments={attempt.videoUrl}
              attachmentTypes={attempt.videoUrl?.includes('.webm') ? 'video/webm' : "video/mp4" }
              Viewer="screener"
            />
            <pre className="mt-2 text-xs overflow-x-auto whitespace-pre-wrap">
              {attempt.videoUrl}
            </pre>
          </div>
        )}
      </div>
      <div className="w-full md:w-2/3 p-4">
        <h5 className="text-md font-semibold mb-2">Version {attempt.version}</h5>
        <div className="space-y-2 text-sm">
          <div className="grid grid-cols-2 gap-2">
            <span className="text-gray-600">IP Address:</span>
            <span className="break-all">{attempt._ip}</span>
            <span className="text-gray-600">Created At:</span>
            <span className="break-words">{new Date(attempt.createdAt).toLocaleString()}</span>
          </div>
          
          {attempt.queueId && (
            <div className="mt-4">
              <span className="text-gray-600">Queue ID: {attempt.queueId}</span>
              <button 
                className="ml-2 px-3 py-1 text-sm bg-blue-600 text-white rounded hover:bg-blue-700"
                onClick={() => populateQueueResults(attempt.queueId!)}
              >
                Populate Queue Results
              </button>
            </div>
          )}

          {queueResults && attempt.queueId && queueResults[attempt.queueId] && (
            <div className="mt-4 p-4 bg-gray-50 rounded">
              <h6 className="font-semibold mb-2">Queue Results</h6>
              {Object.entries(queueResults[attempt.queueId]).map(([mode, results]) => (
                <div key={mode} className="mb-2">
                  <h6 className="font-medium capitalize">{mode}</h6>
                  <div className="grid grid-cols-2 gap-2">
                    {Object.entries(results).map(([key, value]: [string, any]) => (
                      <div key={key} className="contents">
                        <span className="text-gray-600">{key}:</span>
                        <span className="break-all overflow-hidden">
                          {typeof value === 'object' ? JSON.stringify(value) : value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="mt-4">
            <h6 className="font-semibold mb-2">Browser Details</h6>
            <div className="grid grid-cols-2 gap-2">
              {Object.entries(attempt.browserDetails).map(([key, value]: [string, any]) => (
                <div key={key} className="contents">
                  <span className="text-gray-600">{key}:</span>
                  <span className="break-all overflow-hidden">
                    {typeof value === 'object' ? <pre>{JSON.stringify(value, null, 2)}</pre> : value}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

function StageView({ session } : { session: LivenessSession }) {
  const [expandedStages, setExpandedStages] = useState<Record<string, boolean>>({
    front: false,
    back: false,
    selfie: false
  });

  return <>
    {(['front','back','selfie'] as const).map((stage) => {
      const attempts = session.sessionData[stage];
      if (!Array.isArray(attempts)) return null;
      const isExpanded = expandedStages?.[stage];
      
      return (
        <div key={stage} className="mb-6">
          <button 
            onClick={() => setExpandedStages(prev => ({
              ...prev,
              [stage]: !isExpanded
            }))}
            className="w-full flex items-center justify-between text-lg font-semibold text-gray-900 mb-4 hover:bg-gray-100 p-2 rounded"
          >
            <span className="capitalize">{stage} Attempts</span>
            <span className="text-sm text-gray-600">
              {isExpanded ? '▼' : '▶'} ({attempts.length})
            </span>
          </button>
          {isExpanded && attempts && attempts.sort((a, b) => a.version - b.version).map(attempt => (
            <StageAttemptDetails key={attempt.queueId} stage={stage} attempt={attempt} />
          ))}
        </div>
      );
    })}
  </>
}

function TimelineView({ session } : { session: LivenessSession }) {
  // Get timeline events sorted by hash timestamp
  const getTimelineEvents = (session: LivenessSession) => {
    const allAttempts: (Required<typeof session['sessionData']>['front'][number] & { stage: string })[] = [];
    
    for (const stage of ['front', 'back', 'selfie'] as const) {
      const attempts = session.sessionData[stage];
      if (Array.isArray(attempts)) {
        attempts.forEach(attempt => {
          allAttempts.push({
            ...attempt,
            stage,
          });
        });
      }
    }
    
    return allAttempts.sort((a, b) => {
      const timeA = new Date(a.createdAt || '0').getTime();
      const timeB = new Date(b.createdAt || '0').getTime();
      return timeA - timeB;
    });
  };

  const events = getTimelineEvents(session);

  return (
    <div className="space-y-4">
      <ul className="divide-y divide-gray-200">
        {events.map((event, index) => (
          <li key={`${event.stage}-${event.version}`} className="py-3">
            <div className="flex items-center space-x-4">
              <span className="capitalize font-medium text-gray-900">{event.stage}</span>
              <span className="text-sm text-gray-500">Version {event.version}</span>
              <span className="text-sm text-gray-500">
                {new Date(event.createdAt || session.expiresAt).toLocaleString()}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}